 import React, { useState, useEffect, forwardRef } from 'react';
import { MenuItem, Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDataProvider, Loading, Error, useTranslate } from 'react-admin';
import BusinessAccountLogo from './pictures/businessAccount64.png';
import { ReactComponent as ArrowSvgIcon} from '../../logos/right-arrow.svg.svg'
import { useHubApplication } from '../../hooks/useHubApplication';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    title: {
        fontWeight: 'bold',
    },
    gridList: {
        width: '100vw',
        spacing: 0,
        justify: 'space-around'
    },
    icon: {
        height: '32px',
    },
    rightarrow: {
        transform: 'rotate(-90deg)'
    },
    name: {
        margin: '0',
    },
    businessAccountSelectorContainer: {
        color: '#172B4D',
        padding: '22px 30px',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
    },
    businessAccountList: {
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
    },
    businessAccountRow: {
        display: 'flex',
        padding: '0',
        gap: '16px',
        cursor: 'pointer',
    }
}));

const BusinessAccountSelect = forwardRef((props, ref) => {
    const translate = useTranslate();
    const classes = useStyles();
    const [businessAccounts, setBusinessAccounts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const selected = props.selected;
    const dataProvider = useDataProvider();
    const hubApplication = useHubApplication();

    useEffect(() => {
        dataProvider.getList('businessaccount', { pagination: { page: 1, perPage: 100 } })
            .then(({ data }) => {
                setBusinessAccounts(data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
    }, [dataProvider]);

    const navigateHub = () => {
        window.location.href = hubApplication.website;
    }

    if (loading) return <Loading />;
    if (error) return <Error />;
    if (!businessAccounts) return null;

    return (
        <>
            <div ref={ref} className={classes.businessAccountSelectorContainer}>
                <Typography className={classes.title} variant="subtitle2">
                    {translate('hgw.businessaccount.mybusinessaccount')}
                </Typography>
                <div className={classes.businessAccountList}>
                    {businessAccounts.map((businessAccount, index) => {
                        if (selected && businessAccount.id === selected.id) {
                            return null;
                        }
                        return (
                            <MenuItem
                                className={classes.businessAccountRow}
                                key={`${index}${businessAccount.name}`}
                                onClick={() => props.onSelected(businessAccount)}
                            >
                                <img
                                    src={businessAccount.picture ? `/picture/${businessAccount.picture}/32` : BusinessAccountLogo}
                                    alt={businessAccount.name}
                                    className={classes.icon}
                                />
                                <Typography variant='subtitle2' className={classes.name}>
                                    {businessAccount.name}
                                </Typography>
                            </MenuItem>
                        );
                    })}
                </div>
                <Divider variant='fullWidth' />
                <div className={classes.businessAccountRow} onClick={navigateHub}>
                    <ArrowSvgIcon className={classes.rightarrow} />
                    <Typography variant='subtitle2' className={classes.name}>
                        {translate('hgw.businessaccount.allbusinessaccount')}
                    </Typography>
                </div>
            </div>
        </>
    )
});

export default BusinessAccountSelect;
