export default {

    getBusinessAccount: () => {
        var businessAccount = sessionStorage.getItem('BUSINESSACCOUNT-SELECTED');
        return !businessAccount ? null :
            JSON.parse(businessAccount);
    },

    getWac: () => {
       
        return localStorage.getItem('WAC-SELECTED');
    },

    setBusinessAccount: (businessAccount) => {
        var mail = _getUserSession()?.mail;
        if (!mail) return null;
        if (!businessAccount) {
            sessionStorage.removeItem('BUSINESSACCOUNT-SELECTED');
            localStorage.removeItem('WAC-SELECTED');
        } else {
            sessionStorage.setItem('BUSINESSACCOUNT-SELECTED', JSON.stringify(businessAccount));
            localStorage.setItem('WAC-SELECTED', businessAccount.wac);
        }
    },

    getVersion: () => {
        return sessionStorage.getItem('VERSION');
    },

    getHasBadge: () => {
        return sessionStorage.getItem('BADGE-ENABLED') === 'true';
    },

    setSpaInfo: (info) => {
        sessionStorage.setItem('VERSION', info.version);
        sessionStorage.setItem('BADGE-ENABLED', info.enableBadge);
    },

    getUser: () => {
        return _getUserSession();
    },

    setUser: (user) => {
        if (!user) {
            sessionStorage.removeItem('USER');
        } else {
            sessionStorage.setItem('USER', JSON.stringify(user));
        }
    },

    getOrganization: () => {
        var organization = sessionStorage.getItem('ORGANIZATION');
        return !organization ? null :
            JSON.parse(organization);
    },

    setOrganization: (organization) => {
        if (!organization) {
            sessionStorage.removeItem('ORGANIZATION');
        } else {
            sessionStorage.setItem('ORGANIZATION', JSON.stringify(organization));
        }
    },

    clearSession: () => {
        sessionStorage.removeItem('ORGANIZATION');
        sessionStorage.removeItem('USER');
    }
};

function _getUserSession() {
    var user = sessionStorage.getItem('USER');
    return !user ? null :
        JSON.parse(user);
}
