import * as React from "react";
import {
    Create,
    CreateButton,
    ListButton,
    SimpleForm,
    Toolbar,
    TopToolbar,
    SaveButton,
    useTranslate,
    useRedirect,
    useNotify
} from 'react-admin';
import { Box, Typography } from '@material-ui/core';
import SelectBusinessAccount from "./inputs/select";

const BusinessAccountTitle = () => {
    const translate = useTranslate();
    return <span>{translate('hgw.businessaccount.join')}</span>;
};

const BusinessAccountJoinToolbar = props => (
    <Toolbar {...props}>
        <SaveButton label="hgw.join" />
    </Toolbar>
);

const BusinessAccountJoinActions = ({ props, basePath, data, resource }) => {
    return (
        <TopToolbar>
            <ListButton basePath='/businessaccount' />
            <CreateButton basePath='/businessaccount' />
        </TopToolbar>
    )
};

const Aside = () => {
    const translate = useTranslate();
    return (
        <div style={{ width: 200, margin: '1em' }}>
            <Typography variant="h5">{translate('hgw.help')}</Typography>
            <Typography variant="overline" display="block" >
                {translate('hgw.businessaccount.selectorg')}
            </Typography>
            <Box textAlign="center" fontWeight="fontWeightBold">
                {translate('hgw.or')}
            </Box>
            <Typography variant="overline" display="block" >
                {translate('hgw.businessaccount.setwac')}
            </Typography>
        </div>
    )
};

const BusinessAccountJoin = ({ staticContext, ...props }) => {
    const notify = useNotify();
    const redirect = useRedirect();
    const onSuccess = () => {
        notify('hgw.businessaccount.joinsuccessfully', 'info', null, true, 10000); // default message is 'ra.notification.updated'
        redirect('list', '/businessaccount');
    }
    return (
        <Create {...props} title={<BusinessAccountTitle />} onSuccess={onSuccess} aside={<Aside />} actions={<BusinessAccountJoinActions />}>
            <SimpleForm toolbar={<BusinessAccountJoinToolbar />} redirect="list">

                <SelectBusinessAccount label="hgw.businessaccount.label" source="wac" />
            </SimpleForm>
        </Create>
    )
};

export default BusinessAccountJoin;
                //<ReferenceInput label="hgw.businessaccount.near" source="wac" reference="businessaccount" filter={{ friendly: true }} emptyText="hgw.businessaccount.other" allowEmpty>
                //    <SelectInput optionText="name" optionValue="wac" />
                //</ReferenceInput>
                //<TextInput label="hgw.businessaccount.wac" source="wac" />