import ExtendedPropertyList from './list';
import ExtendedPropertyCreate from './create';
import ExtendedPropertyEdit from './edit';
import ExtendedPropertyIcon from './icon';

export default {
    list: ExtendedPropertyList,
    create: ExtendedPropertyCreate,
    edit: ExtendedPropertyEdit,
    icon: ExtendedPropertyIcon
};