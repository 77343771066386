import * as React from 'react';
import { Fragment } from 'react';
import { useMediaQuery } from '@material-ui/core';
import {
    AutocompleteArrayInput,
    BooleanInput,
    BulkDeleteButton,
    Datagrid,
    Filter,
    List,
    ReferenceArrayInput,
    ReferenceField,
    SelectArrayInput,
    SelectField,
    SimpleList,
    SelectInput,
    TextField,
    TextInput
} from 'react-admin';
import ReflectClassField from './fields/reflectClassField';
import MomentField from '../crossCutting/fields/momentField';
import TagArrayField from '../tag/fields/arrayField';
import AlarmBadge from "./fields/alarmBadge";
import ResourceStatusField from '../crossCutting/fields/resourceStatusField';
import InfraReferenceField from '../infra/fields/reference';
import SmartLocalUnitReferenceField from '../smartlocalunit/fields/referenceField';
import BulkValidateButton from '../crossCutting/buttons/BulkValidateButton';
import { CriticityList } from "./enums/criticity";

const ReflectListBulkActionButtons = props => {
    return <Fragment>
        <BulkValidateButton {...props} />
        <BulkDeleteButton {...props} />
    </Fragment>
};

const ReflectFilter = (props) => (
    <Filter {...props}>
        <TextInput label="hgw.search" source="search" alwaysOn />
        <ReferenceArrayInput label="hgw.scope.many" source="scopeIds" reference="scope" alwaysOn filterToQuery={searchText => ({ search: searchText })} perPage={25}>
            <AutocompleteArrayInput optionText="label" optionValue="id" />
        </ReferenceArrayInput>
        <ReferenceArrayInput label="hgw.infra.many" source="infraIds" reference="infra" filterToQuery={searchText => ({ search: searchText })} perPage={25} alwaysOn>
            <AutocompleteArrayInput optionText="name" optionValue="id" alwaysOn />
        </ReferenceArrayInput>
        <SelectInput label="hgw.reflect.alarm" source="alarm" choices={CriticityList} optionText={<AlarmBadge />} emptyText="hgw.undefined" allowEmpty alwaysOn />
        <ReferenceArrayInput label="hgw.reflect.class.many" source="classIds" reference="reflectclass" alwaysOn>
            <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <BooleanInput label="hgw.status.tovalidate" source="toValidate" alwaysOn />
        <ReferenceArrayInput label="hgw.smartlocalunit.many" source="smartLocalUnitIds" reference="smartlocalunit" filterToQuery={searchText => ({ search: searchText })} perPage={25}>
            <AutocompleteArrayInput optionText="name" optionValue="id" />
        </ReferenceArrayInput>
        <ReferenceArrayInput label="hgw.tag.many" source="tags" reference="tag" filterToQuery={searchText => ({ search: searchText })} perPage={25}>
            <AutocompleteArrayInput optionText="label" optionValue="label" />
        </ReferenceArrayInput>
    </Filter>
);

const ReflectList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List
            title="hgw.reflect.many"
            bulkActionButtons={<ReflectListBulkActionButtons />}
            filters={<ReflectFilter />} {...props}>
            {isSmall ?
                (<SimpleList
                    linkType="show"
                    leftIcon={record => <ReflectClassField record={record} source="classId" />}
                    primaryText={record => record.name}
                    secondaryText={record => record.description ?? '-'}
                    tertiaryText={record => <MomentField record={record} source="exchangeDate" />}
                />) :
                (<Datagrid rowClick="show">
                    <ReflectClassField label="hgw.reflect.class.label" source="classId" />
                    <TextField label="hgw.label" source="name" />
                    <SelectField label="hgw.reflect.alarm" source="alarm" choices={CriticityList} optionText={<AlarmBadge addLabel={false} />} sortable={false} />
                    <TagArrayField label="hgw.tag.many" source="tags" sortable={false} />
                    <ReferenceField label="hgw.infra.label" source="infraId" reference="infra" sortable={false}>
                        <InfraReferenceField />
                    </ReferenceField>
                    <ReferenceField label="hgw.smartlocalunit.label" source="smartLocalUnitId" reference="smartlocalunit" sortable={false} link="show">
                        <SmartLocalUnitReferenceField />
                    </ReferenceField>
                    <MomentField label="hgw.smartlocalunit.exchangedate" source="exchangeDate" />
                    <MomentField label="hgw.smartlocalunit.nextcall" source="nextCall" />
                    <ResourceStatusField label="hgw.status.label" source="status" />
                </Datagrid>)}
        </List>
    )
};

export default ReflectList;