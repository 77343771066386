import * as React from "react";
import { useMediaQuery } from '@material-ui/core';
import {
    BulkUpdateButton,
    Datagrid,
    List,
    SimpleList,
} from 'react-admin';
import { Fragment } from 'react';
import { Mail, Notifications, NotificationsOff, Cancel } from '@material-ui/icons';
import LabelField from './fields/label';
import SendMailField from './fields/sendMail';
import contextStore from '../../provider/services/contextStore';

const UNSUBSCRIBE_GLOBAL = {
    global: { sendMail: false, isDefault: false },
};

const UNSUBSCRIBE_WAC = {
    wac: { sendMail: false, isDefault: false }
};
const RESET_GLOBAL = {
    global: { isDefault: true }
};

const RESET_WAC = {
    wac: { isDefaultWac: true }
};

const NotificationPolicyListBulkActionButtons = props => (
    <Fragment>
        <BulkUpdateButton
            {...props}
            label="hgw.notificationpolicy.deletewac"
            data={UNSUBSCRIBE_WAC}
            icon={<NotificationsOff />}
        />

        <BulkUpdateButton
            {...props}
            label="hgw.notificationpolicy.resetwac"
            data={RESET_WAC}
            icon={<Cancel />}
        />

        <BulkUpdateButton
            {...props}
            label="hgw.notificationpolicy.deleteglobal"
            data={UNSUBSCRIBE_GLOBAL}
            icon={<NotificationsOff />}
        />

        <BulkUpdateButton
            {...props}
            label="hgw.notificationpolicy.resetglobal"
            data={RESET_GLOBAL}
            icon={<Cancel />}
        />
    </Fragment>
);

const NotificationPolicyList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const businessAccount = contextStore.getBusinessAccount()?.name ?? "hgw.businessaccount.label";
    return (
        <List
            title="hgw.notificationpolicy.many"
            pagination={false}
            bulkActionButtons={<NotificationPolicyListBulkActionButtons />}
            {...props}>
            {isSmall ?
                (<SimpleList
                    linkType="edit"
                    leftIcon={record => <Notifications />}
                    primaryText={record => record.label}
                    rightIcon={record => record.wac?.sendmail ?? record.global.sendmail ? <Mail /> : null}
                />) :
                (<Datagrid rowClick="edit">
                    <LabelField label="hgw.notificationpolicy.event" source="label" />
                    <SendMailField label={businessAccount} source="wac" />
                    <SendMailField label="hgw.notificationpolicy.global" source="global" />
                </Datagrid>)}
        </List>
    )
};

export default NotificationPolicyList;