import httpClient from './services/httpClient';
import requestUtils from './factory/requestUtils';
import contextStore from './services/contextStore';

const root = () => {
    return "core/authorization";
};

const code = () => {
    var organization = contextStore.getOrganization();
    return (!organization) ? Promise.reject("User organization not defined or not validated") : organization.code;
};

const setUsers = (authorizations) => {
    var userIds = authorizations.map(u => u.userId)
    const query = userIds.map((id) => `ids=${id}`).join("&");
    return httpClient(`core/user/organization/${code()}/many?${query}`).then(
        ({ json }) => {
            authorizations.forEach(a => {
                const user = json.find(u => u.id === a.userId);
                a.user = user;
            });
        })
}

export default {
    map: (json) => {
        json.toValidate = !json.validated;
        if (!!json.user) {
            if (!json.user.lastname) {
                json.user.name = json.user.mail;
            } else if (!json.user.firstname) {
                json.user.name = json.user.lastname;
            }
            else {
                json.user.name = `${json.user.firstname} ${json.user.lastname}`;
            }
        }

        return json;
    },

    getList: (params) => {
        const query = requestUtils.buildQuery(params);
        return httpClient(`${root()}/organization/${code()}?${query}`)
            .then(({ json }) => setUsers(json.data).then(() => json))
    },

    getOne: (params) => {
        return httpClient(`${root()}/${params.id}`)
            .then(({ json }) => setUsers([json]).then(() => json))
    },

    getMany: (params) => {
        //return httpClient(root()).then(({ json }) => {
        //    return json.filter(j => params.ids.indexOf(j.id) !== -1);
        //})
    },

    update: (params) => {
        const puts = [];
        var dataUpdate = requestUtils.getDatas(params, ['id', 'roleId']);
        if (dataUpdate) {
            puts.push({
                url: `${root()}/organization`,
                method: 'PUT',
                data: JSON.stringify(dataUpdate)
            });
        }

        var dataValid = requestUtils.getDatas(params, ['id', 'validated']);
        if (dataValid) {
            dataValid.validate = dataValid.validated;
            delete dataValid.validated;
            puts.push({
                url: `${root()}/validate`,
                method: 'PATCH',
                data: JSON.stringify(dataValid)
            });
        }

        return requestUtils.sendSynchrone(puts)
            .then(json => setUsers([json]).then(() => json));
    },

    updateMany: (params) => {
        const puts = [];
        if (params.data.validate) {
            params.ids.forEach(id => {
                var cmd = { id, validate: true };
                puts.push({
                    url: `${root()}/validate`,
                    method: 'PATCH',
                    data: JSON.stringify(cmd)
                });
            })
        }

        return requestUtils.sendSynchrone(puts).then(() => params.ids);
    },

    create: (params) => {
        // woc is provided when join
        if (!params.data.code) {
            params.data.code = code();
        }

        return httpClient(`${root()}/organization`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => setUsers([json]).then(() => json));
    },

    delete: (params) => {
        return httpClient(`${root()}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => json)
    },

    deleteMany: (params) => {
        //const query = {
        //    filter: JSON.stringify({ id: params.ids }),
        //};
        //return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
        //    method: 'DELETE',
        //    body: JSON.stringify(params.data),
        //}).then(({ json }) => ({ data: json }));
    },
};
