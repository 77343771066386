import * as React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import ModelEnums from '../enums/model';
import mainLogo from '../pictures/smartLocalUnit32.png';

const useStyles = makeStyles({
    avatar: {
        height: 24,
        width: 24,
    },
});

const ReferenceField = ({ record }) => {
    const classes = useStyles();
    var model = ModelEnums[record.model] ?? { logo: mainLogo, label: '?' };
    return (
        <Chip icon={<Avatar className={classes.avatar} src={model.logo} />} label={record.name} key={record} />
    )
}

ReferenceField.defaultProps = { addLabel: true }

export default ReferenceField;