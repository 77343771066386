import * as React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {
    useRedirect,
    useTranslate
} from 'react-admin';
import contextStore from '../../../provider/services/contextStore';
import httpClient from '../../../provider/services/httpClient';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const useStyles = makeStyles({
    button: {
        backgroundColor: 'yellow',
        color: 'black',
        marginLeft: 8,
    }
});

const DisableButton = ({ record, source, basePath, resource, validatedValue, isWacResource }) => {
    const classes = useStyles();
    const redirect = useRedirect();
    const tranlate = useTranslate();

    const isValidated = !record ? false : record[source] === validatedValue;

    const handleClick = () => {
        if (!isValidated) return;
        const root = isWacResource ? `wac/${contextStore.getWac()}` : "core";
        return httpClient(`${root}/${resource}/disable`, {
            method: 'PATCH',
            body: JSON.stringify({ id: record.id })
        }).then(f => {
            redirect('list', basePath);
        })
    }

    return !isValidated ? null :
        <Button
            startIcon={<HighlightOffIcon />}
            className={classes.button}
            onClick={handleClick}  >
            {tranlate("hgw.disable")}
        </Button>;
};

DisableButton.defaultProps = { source: "status", disabledValue: 1, validatedValue: 2, isWacResource: true }

export default DisableButton;