import * as React from "react";
import { Avatar, makeStyles } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';

const useStyles = makeStyles({
    avatar: {
        height: 32,
        width: 32,
    }
});

const UserIcon = props => {
    const classes = useStyles();
    return (
        <Avatar className={classes.avatar} >
            <PersonIcon />
        </Avatar>
    )
}

export default UserIcon;