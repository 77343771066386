import ActivityProvider from '../activityProvider';
import AppProvider from '../appProvider';
import AuthorizationProvider from '../authorizationProvider';
import BadgeProvider from '../badgeProvider';
import BusinessAccountProvider from '../businessAccountProvider';
import CountryProvider from '../countryProvider';
import CultureProvider from '../cultureProvider';
import DeviceLoraProvider from '../deviceLoraProvider';
import FluxProvider from '../fluxProvider';
import FluxItemProvider from '../fluxItemProvider';
import ExtendedPropertyProvider from '../extendedPropertyProvider';
import InfraProvider from '../infraProvider';
import InfraUsageProvider from '../infraUsageProvider';
import MemberProvider from '../memberProvider';
import NotificationPolicyProvider from '../notificationPolicyProvider';
import OperationProvider from '../operationProvider';
import OrganizationProvider from '../organizationProvider';
import RoleProvider from '../roleProvider';
import ReflectProvider from '../reflectProvider';
import ReflectClassProvider from '../reflectClassProvider';
import ScopeProvider from '../scopeProvider';
import SmartLocalUnitProvider from '../smartLocalUnitProvider';
import TagProvider from '../tagProvider';
import UserProvider from '../userProvider';
import WumUnitProvider from '../wumUnitProvider';
import WumTypeProvider from '../wumTypeProvider';
import WumFunctionProvider from '../wumFunctionProvider';
import WumMaterialProvider from '../wumMaterialProvider';

const providers = {
    activity: ActivityProvider,
    app: AppProvider,
    authorization: AuthorizationProvider,
    badge: BadgeProvider,
    businessaccount: BusinessAccountProvider,
    culture: CultureProvider,
    country: CountryProvider,
    flux: FluxProvider,
    fluxitem: FluxItemProvider,
    devicelora: DeviceLoraProvider,
    extendedproperty: ExtendedPropertyProvider,
    infra: InfraProvider,
    infrausage: InfraUsageProvider,
    member: MemberProvider,
    notificationpolicy: NotificationPolicyProvider,
    operation: OperationProvider,
    organization: OrganizationProvider,
    reflect: ReflectProvider,
    reflectclass: ReflectClassProvider,
    role: RoleProvider,
    scope: ScopeProvider,
    smartlocalunit: SmartLocalUnitProvider,
    tag: TagProvider,
    user: UserProvider,
    wumunit: WumUnitProvider,
    wumtype: WumTypeProvider,
    wumfunction: WumFunctionProvider,
    wummaterial: WumMaterialProvider
}

export default {
    getList: (resource, params) => {
        if (!params.filter) params.filter = {};
        if (!params.sort) params.sort = {};
        if (params.pagination) {
            const { page, perPage } = params.pagination;
            params.filter.pageSize = perPage;
            params.filter.pageIndex = page;
            const { field, order } = params.sort;
            if (!!field) params.filter.orderField = field;
            if (order === 'DESC') params.filter.orderAsc = false;
        }

        return providers[resource].getList(params.filter)
            .then(json => {
                return {
                    data: json.data.map(j => providers[resource].map(j)),
                    total: json.count
                };
            });
    },

    getOne: (resource, params) => {
        return providers[resource].getOne(params).then(json => {
            var result = !json ? undefined : providers[resource].map(json);
            return ({
                data: result
            })
        });
    },

    getMany: (resource, params) => {
        return providers[resource].getMany(params).then(json => {
            return {
                data: json.map(j => providers[resource].map(j)),
            }
        });
    },

    getManyReference: (resource, params) => {
        if (params.pagination) {
            const { page, perPage } = params.pagination;
            params.filter.pageSize = perPage;
            params.filter.pageIndex = page;
        }

        if (params.hasOwnProperty("target")) {
            params.filter[params.target] = [params.id]
        }

        return providers[resource].getManyReference(params.filter).then(json => {
            return {
                data: json.data.map(j => providers[resource].map(j)),
                total: json.count
            };
        });
    },

    update: (resource, params) => {
        return providers[resource].update(params).then(json => {
            return {
                data: providers[resource].map(json)
            }
        })
    },

    updateMany: (resource, params) => {
        return providers[resource].updateMany(params).then(json => {
            return {
                data: json
            }
        })
    },

    create: (resource, params) => {
        return providers[resource].create(params).then(json => {
            return {
                data: providers[resource].map(json)
            }
        });
    },

    delete: (resource, params) => {
        return providers[resource].delete(params).then(json => {
            return {
                data: json
            }
        });
    },

    deleteMany: (resource, params) => {
        return params.ids.reduce(function (p, item) {
            return p.then(function () {
                return providers[resource].delete({ id: item });
            });
        }, Promise.resolve())
            .then((x) => {
                return ({ data: params.ids });
            })
    },
};
