import * as React from 'react';
import { Fragment } from 'react';
import { useMediaQuery } from '@material-ui/core';
import {
    BooleanInput,
    BulkDeleteButton,
    Datagrid,
    Filter,
    List,
    ReferenceField,
    SimpleList,
    TextField,
    TextInput,
    useTranslate
} from 'react-admin';
import AvatarPicture from '../crossCutting/fields/avatarPicture';
import PeopleIcon from '@material-ui/icons/People';
import ValidateField from './fields/validateField';
import RoleReferenceField from '../role/fields/referenceField';
import ScopeReferenceField from '../scope/fields/referenceField';
import UserCardField from '../user/fields/cardField';
import BulkValidateButton from '../crossCutting/buttons/BulkValidateButton';

const AuthorizationListBulkActionButtons = props => {
    return <Fragment>
        <BulkValidateButton {...props} />
        <BulkDeleteButton {...props} />
    </Fragment>
};

const AuthorizationFilter = (props) => (
    <Filter {...props}>
        <TextInput label="hgw.search" source="search" alwaysOn />
        <BooleanInput label="hgw.authorization.tovalidate" source="toValidate" alwaysOn />
    </Filter>
);

const AuthorizationBusinessAccountListTitle = (props) => {
    const translate = useTranslate();
    return <span>{translate("hgw.authorization.businessaccount.many")}</span>;
};

const AuthorizationOrganizationListTitle = (props) => {
    const translate = useTranslate();
    return <span>{translate("hgw.authorization.organization.many")}</span>;
};

const AuthorizationList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const isBusinessAccount = props.options.businessaccount;
    return (
        <List
            title={isBusinessAccount ? <AuthorizationBusinessAccountListTitle /> : <AuthorizationOrganizationListTitle />}
            bulkActionButtons={<AuthorizationListBulkActionButtons />}
            filters={<AuthorizationFilter />}
            filter={{ organization: props.options.organization }}
            {...props}>
            {isSmall ?
                (<SimpleList
                    linkType="show"
                    leftIcon={record => <AvatarPicture record={record} source="user.photo" ><PeopleIcon /></AvatarPicture>}
                    primaryText={record => record.user.name}
                    secondaryText={record => record.user.mail}
                    tertiaryText={record =>
                        <span>
                            <ReferenceField source="roleId" reference="role" basePath="role" record={record} link="show">
                                <TextField source="name" />
                            </ReferenceField>
                            {!record.scopeId ? null : " / "}
                            {!record.scopeId ? null : <ReferenceField source="scopeId" reference="scope" basePath="scope" record={record} link="show">
                                <TextField source="label" />
                            </ReferenceField>}
                        </span>}
                />) :
                (<Datagrid rowClick="show">
                    <UserCardField label="hgw.user.label" source="user" />
                    <ReferenceField label="hgw.role.label" source="roleId" reference="role" link="show">
                        <RoleReferenceField />
                    </ReferenceField>
                    {isBusinessAccount ? <ReferenceField label="hgw.scope.label" source="scopeId" reference="scope" link="show">
                        <ScopeReferenceField />
                    </ReferenceField> : null}
                    <ValidateField label="hgw.authorization.isvalid" source="validated" />
                </Datagrid>)}
        </List>
    )
};

export default AuthorizationList;