const ValueTypeEnum = {
    bit: { type: 'boolean' },
    decimal: { type: 'decimal' },
    float: { type: 'float' },
    int: { type: 'int' },
    money: { type: 'money' },
    text: { type: 'text' },
    tinyint: { type: 'tinyint' },
    datetime: { type: 'datetime' }
}

export const ValueTypeList = Object.entries(ValueTypeEnum).map(([id, obj]) => {
    obj.id = id;
    return obj
})

export default ValueTypeEnum;