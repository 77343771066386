import * as React from "react";
import ModelEnums from '../enums/model';
import mainLogo from '../pictures/smartLocalUnit32.png';

const SmartLocalUnitModelField = ({ record }) => {
    var model = ModelEnums[record.model] ?? { logo: mainLogo, label: '?' };
    return (<img src={model.logo} alt={model.label} />)
}

SmartLocalUnitModelField.defaultProps = { addLabel: true }

export default SmartLocalUnitModelField;