import * as React from "react";
import {
    Edit,
    FormDataConsumer,
    FormTab,
    ReferenceInput,
    SelectInput,
    TabbedForm,
    TextInput,
} from 'react-admin';
import FluxReferenceField from '../flux/fields/reference';
import title from './title';

const FluxItemTitle = title;

export const FluxItemEdit = props => {
    return (
        <Edit title={<FluxItemTitle />} {...props}>
            <TabbedForm redirect="list">
                <FormTab label="hgw.summary">
                    <TextInput disabled source="id" />
                    <TextInput label="hgw.label" source="name" allowEmpty={false} />
                    <ReferenceInput label="hgw.flux.label" source="fluxId" reference="flux" filter={{ origin: 'cloud' }} disabled>
                        <SelectInput optionText={<FluxReferenceField />} />
                    </ReferenceInput>
                    <TextInput label="hgw.flux.item.code" source="label" disabled />
                    <TextInput label="hgw.description" multiline source="description" />
                </FormTab>
                <FormTab label="hgw.wum.label">
                    <FormDataConsumer>
                        {({ formData, ...rest }) => (
                            <ReferenceInput label="hgw.wum.unit" source="wum.unit" reference="wumunit" {...rest}>
                                <SelectInput optionText="name" disabled={formData.origin === 'acquiring'} />
                            </ReferenceInput>
                        )}
                    </FormDataConsumer>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => (
                            <ReferenceInput label="hgw.wum.type" source="wum.type" reference="wumtype" {...rest}>
                                <SelectInput optionText="name" disabled={formData.origin === 'acquiring'} />
                            </ReferenceInput>)}
                    </FormDataConsumer>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => (
                            <ReferenceInput label="hgw.wum.function" source="wum.function" reference="wumfunction" {...rest}>
                                <SelectInput optionText="name" disabled={formData.origin === 'acquiring'} />
                            </ReferenceInput>)}
                    </FormDataConsumer>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => (
                            <ReferenceInput label="hgw.wum.material" source="wum.material" reference="wummaterial" {...rest}>
                                <SelectInput optionText="name" disabled={formData.origin === 'acquiring'} />
                            </ReferenceInput>)}
                    </FormDataConsumer>
                </FormTab>
            </TabbedForm>
        </Edit>
    )
};

export default FluxItemEdit;