import * as React from "react";
import {
    Edit,
    DeleteButton,
    ReferenceInput,
    SaveButton,
    SelectInput,
    SimpleForm,
    TextInput,
    Toolbar
} from 'react-admin';
import { useTranslate } from "react-admin";
import UserCardField from '../user/fields/cardField';
import ValidateButton from '../crossCutting/buttons/ValidateButton';

const AuthorizationBusinessAccountEditTitle = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate("hgw.authorization.businessaccount.label")} {record ? `"${record.user.mail}"` : ''}</span>;
};

const AuthorizationOrganizationEditTitle = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate("hgw.authorization.organization.label")} {record ? `"${record.user.mail}"` : ''}</span>;
};

const AuthorizationToolbar = (props) => {
    return (
        <Toolbar {...props}>
            <SaveButton />
            <ValidateButton source="validated" resource={props.resource} validatedValue={true} toValidateValue={false} />
            <DeleteButton />
        </Toolbar>
    )
};

const AuthorizationEdit = props => {
    const isBusinessAccount = props.options.businessaccount;
    return (
        <Edit title={isBusinessAccount ? <AuthorizationBusinessAccountEditTitle /> : <AuthorizationOrganizationEditTitle />} {...props}>
            <SimpleForm toolbar={<AuthorizationToolbar />} redirect="list">
                <TextInput disabled source="id" />
                <UserCardField label="hgw.user.label" source="user" />
                <ReferenceInput label="hgw.role.label" source="roleId" reference="role" filter={{ organization: props.options.organization }}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                {isBusinessAccount ? <ReferenceInput label="hgw.scope.label" source="scopeId" reference="scope" allowEmpty>
                    <SelectInput optionText="label" />
                </ReferenceInput> : null}
            </SimpleForm>
        </Edit>
    )
};

export default AuthorizationEdit;