import * as React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from "@material-ui/core/Box";
import contextStore from './provider/services/contextStore';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

const About = () => {
    const classes = useStyles();
    const version = contextStore.getVersion();
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"    >
            <Card className={classes.root}>
                <CardContent>
                    <Typography variant="h5" component="h2">
                        Highway Resource Manager
                    </Typography>
                    <Typography className={classes.pos} color="textSecondary">
                        <br />
                        version: {version}
                    </Typography>

                </CardContent>
                <CardActions>
                    <Button size="small" disabled>Learn More</Button>
                </CardActions>
            </Card>
        </Box>
    );

}

export default About;