import * as React from "react";
import { Avatar, makeStyles } from '@material-ui/core';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

const useStyles = makeStyles({
    avatar: {
        height: 32,
        width: 32,
    }
});

const BadgeIcon = props => {
    const classes = useStyles();
    return (
        <Avatar className={classes.avatar} >
            <VpnKeyIcon />
        </Avatar>
    )
}

export default BadgeIcon;