import * as React from "react";
import ReflectLogo from './pictures/reflectDefaultCmd32.png';
import { Avatar, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    avatar: {
        height: 32,
        width: 32,
    }
});

export const ReflectIcon = props => {
    const classes = useStyles();
    return (
        < Avatar src={ReflectLogo} className={classes.avatar} />
    )
}

export default ReflectIcon;