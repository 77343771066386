import * as React from "react";
import {
    Create,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    TextInput,
    FormDataConsumer
} from 'react-admin';
import { useTranslate } from "react-admin";
import UserCardField from '../user/fields/cardField';

const AuthorizationBusinessAccountCreateTitle = () => {
    const translate = useTranslate();
    return <span>{translate("hgw.authorization.businessaccount.label")}</span>;
};

const AuthorizationOrganizationCreateTitle = () => {
    const translate = useTranslate();
    return <span>{translate("hgw.authorization.organization.label")}</span>;
};

const AuthorizationCreate = props => {
    const isBusinessAccount = props.options.businessaccount;
    return (
        <Create title={isBusinessAccount ? <AuthorizationBusinessAccountCreateTitle /> : <AuthorizationOrganizationCreateTitle />} {...props}>
            <SimpleForm redirect="list">
                <ReferenceInput label="hgw.user.label" source="userId" reference="user" filter="friendly" allowEmpty>
                    <SelectInput optionText={<UserCardField />} />
                </ReferenceInput>
                <FormDataConsumer>
                    {({ formData, ...rest }) => !formData.userId &&
                        <TextInput label="hgw.user.mail" source="mail" {...rest} />
                    }
                </FormDataConsumer>
                <ReferenceInput label="hgw.role.label" source="roleId" reference="role" filter={{ organization: props.options.organization }}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                {isBusinessAccount ? <ReferenceInput label="hgw.scope.label" source="scopeId" reference="scope" allowEmpty>
                    <SelectInput optionText="label" />
                </ReferenceInput> : null}
            </SimpleForm>
        </Create>
    )
};

export default AuthorizationCreate;