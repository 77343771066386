import * as React from "react";
import {
    Button,
    ReferenceField,
    Show,
    SimpleShowLayout,
    TextField,
    TopToolbar,
    useRedirect
} from 'react-admin';
import ImagePictureField from '../crossCutting/fields/imagePictureField';
import contextStore from '../../provider/services/contextStore';
import EditIcon from '@material-ui/icons/Edit';

const UserShowActions = ({ props, basePath, data, resource }) => {
    const redirect = useRedirect();
    return (
        <TopToolbar>
            <Button
                onClick={() => { redirect('/profile/edit'); }}
                label="hgw.edit" >
                <EditIcon />
            </Button>
        </TopToolbar>
    )
};

const UserShow = props => {
    const user = contextStore.getUser();
    const id = user?.id;
    return (
        <Show id={id} title="hgw.user.profile" actions={<UserShowActions />} {...props}>
            <SimpleShowLayout>
                <ImagePictureField label="hgw.user.photo" source="photo" />
                <TextField label="hgw.user.firstname" source="firstname" />
                <TextField label="hgw.user.lastname" source="lastname" />
                <TextField label="hgw.user.mail" source="mail" />
                <TextField label="hgw.user.phone" source="phone" />
                <ReferenceField label="hgw.culture.label" source="culture" reference="culture">
                    <TextField source="name" />
                </ReferenceField>
            </SimpleShowLayout>
        </Show>
    )
};

export default UserShow;
