import * as React from "react";
import { Avatar, makeStyles } from '@material-ui/core';
import NoteAddIcon from '@material-ui/icons/NoteAdd';

const useStyles = makeStyles({
    avatar: {
        height: 32,
        width: 32,
    }
});

const ExtendedPropertyIcon = props => {
    const classes = useStyles();
    return (
        <Avatar className={classes.avatar} >
            <NoteAddIcon />
        </Avatar>
    )
}

export default ExtendedPropertyIcon;