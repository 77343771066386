import * as React from "react";
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';

const useStyles = makeStyles({
    chip: {
        marginRight: '8px',
        marginBottom:'4px'
    }
});

const TagArrayField = ({ record, source }) => {
    const classes = useStyles();
    var array = source.split('.').reduce(function (o, k) {
        return o && o[k];
    }, record);

    if (typeof array === 'undefined' || array === null || array.length === 0) {
        return <div />
    } else {
        return (
            <>
                {array.map(item => <Chip className={classes.chip} icon={<LocalOfferIcon />} label={item} key={item} />)}
            </>
        )
    }
}

TagArrayField.defaultProps = { addLabel: true }

export default TagArrayField;