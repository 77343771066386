import * as React from "react";
import { translate } from 'react-admin';

const periodField = ({ record, source, translate }) => {
    var period = (source ?? 'period').split('.').reduce(function (o, k) {
        return o && o[k];
    }, record);

    if (typeof period === 'undefined' || period === null) {
        return (
            <>
                {translate(`hgw.period.undefined`)}
            </>
        )
    } else {
        var periodType = period.substring(period.length - 2);
        var occurence = period.substring(0, period.length - 2);
        // const translate = useTranslate();
        return (
            <>
                {occurence} {translate(`hgw.period.${periodType}`)}
            </>
        )
    }
}
periodField.defaultProps = { addLabel: true }

export default translate(periodField);
