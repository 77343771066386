import httpClient from './services/httpClient';

const root = () => 'global/wum/unit';

function getLocaleWumUnits() {
    var wumUnits = localStorage.getItem('wumunits');
    return (!wumUnits ?
        httpClient(root()).then(({ json }) => {
            localStorage.setItem('wumunits', JSON.stringify(json));
            return json;
        }) :
        Promise.resolve(JSON.parse(wumUnits)));
}

export default {

    map: (json) => {
        return json
    },

    getList: (params) => {
        return getLocaleWumUnits().then(items => {

            if (params.hasOwnProperty("search")) {
                items = items.filter(w => w.name.indexOf(params.search) > -1);
            }

            var paged = (params.hasOwnProperty("pageSize") && params.hasOwnProperty("pageIndex")) ?
                items.slice((params.pageIndex - 1) * params.pageSize, params.pageIndex * params.pageSize) : items;

            return {
                data: paged,
                count: items.length
            }
        })
    },

    getMany: (params) => {
        var areIntegers = params.ids.every(id => Number.isInteger(id));
        return getLocaleWumUnits().then(items => {
            return items.filter(u => params.ids.includes(areIntegers ? u.id : u.symbol));
        })
    }
};
