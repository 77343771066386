import * as React from "react";
import FluxItemLogo from './pictures/level1.png';
import { Avatar, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    avatar: {
        height: 32,
        width: 32,
    }
});

const FluxItemIcon = props => {
    const classes = useStyles();
    return (
        <Avatar src={FluxItemLogo} className={classes.avatar} />
    )
}

export default FluxItemIcon;