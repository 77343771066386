import * as React from 'react';
import { UserMenu, MenuItemLink, useTranslate } from 'react-admin';
import { Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ApartmentIcon from '@material-ui/icons/Apartment';
import PersonIcon from '@material-ui/icons/Person';
import PeopleIcon from '@material-ui/icons/People';
import InfoIcon from '@material-ui/icons/Info';
import AvatarPicture from '../resources/crossCutting/fields/avatarPicture';
import BusinessAccountIcon from '../resources/businessaccount/icon';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import NotificationIcon from '@material-ui/icons/Notifications';
import { ReactComponent as ArrowSvgIcon} from '../logos/right-arrow.svg.svg'
import CustomLogoutButton from './customLogoutButton';

const useStyles = makeStyles(() => ({
    userMenuButton: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
    }
}))


const ConfigurationMenu = React.forwardRef(({ user, organization, onClick }, ref) => {
    const translate = useTranslate();
    const isAuthenticated = !!user;
    const hasOrganization = !!organization;

    return (
        <>
            {isAuthenticated ?
                <MenuItemLink
                    ref={ref}
                    to="/profile/show"
                    primaryText={translate('hgw.user.profile')}
                    leftIcon={<AvatarPicture record={user} source="photo" ><PersonIcon /></AvatarPicture>}
                    onClick={onClick} // close the menu on click
                /> :
                <MenuItemLink
                    ref={ref}
                    to="/"
                    primaryText={translate('hgw.login')}
                    leftIcon={<ExitToAppIcon />}
                    onClick={onClick} // close the menu on click
                />}
            <Divider />
            {isAuthenticated ?
                <MenuItemLink
                    ref={ref}
                    to="/businessaccount"
                    primaryText={translate('hgw.businessaccount.many')}
                    leftIcon={<BusinessAccountIcon />}
                    onClick={onClick} // close the menu on click
                /> : null}
            <Divider />
            {isAuthenticated ?
                <MenuItemLink
                    ref={ref}
                    to={hasOrganization ? "/organization/show" : "/organization/define"}
                    primaryText={translate(hasOrganization ? 'hgw.organization.label' : 'hgw.organization.define')}
                    leftIcon={<ApartmentIcon />}
                    onClick={onClick} // close the menu on click
                /> : null}
            {hasOrganization ?
                <MenuItemLink
                    ref={ref}
                    to="/member"
                    primaryText={translate('hgw.authorization.organization.many')}
                    leftIcon={<PeopleIcon />}
                    onClick={onClick} // close the menu on click
                /> : null}
            <Divider />
            {isAuthenticated ?
                <MenuItemLink
                    ref={ref}
                    to="/notificationpolicy"
                    primaryText={translate('hgw.notificationpolicy.many')}
                    leftIcon={<NotificationIcon />}
                    onClick={onClick} // close the menu on click
                /> : null}
            <Divider />
            <MenuItemLink
                ref={ref}
                to="/about"
                primaryText={translate('hgw.about')}
                leftIcon={<InfoIcon />}
                onClick={onClick} // close the menu on click
            />
            <Divider />
            {isAuthenticated ? <CustomLogoutButton /> : null}
        </>
    )
});

const CustomUserMenu = props => {
    const user = props.user;
    const organization = props.organization;
    const classes = useStyles();

    return (
        <>
            <UserMenu
                icon={
                    <div className={classes.userMenuButton}>
                        <AvatarPicture
                            record={user}
                            source="photo"
                        />
                        <Typography variant='subtitle2'>
                            {`${user?.firstname} ${user?.lastname}`}                
                        </Typography>
                        <ArrowSvgIcon />
                    </div>
                }
                label={`${user?.firstname} ${user?.lastname}`}
                {...props}
            >
                <ConfigurationMenu user={user} organization={organization} />
            </UserMenu>
        </>
    )
};

export default CustomUserMenu;
