import * as React from "react";
import { Avatar, Card, CardHeader } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';


const cardField = ({ record, source }) => {

    const user = !source ? record : record[source];
    if (typeof user === 'undefined' || user === null) {
        return <div />
    } else {
        return (
            <Card>
                <CardHeader
                    avatar={
                        <Avatar
                            src={user.photo ? `picture/${user.photo}/32` : AccountCircleIcon} />
                    }
                    title={user.mail}
                    subheader={user.name}
                />
            </Card>
        )
    }
}

cardField.defaultProps = { addLabel: true }

export default cardField;
