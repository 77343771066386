import httpClient from './services/httpClient';

const root = () => 'core/culture/country';

function getLocaleCountries() {
    var countries = localStorage.getItem('countries');
    return (!countries ?
        httpClient(root()).then(({ json }) => {
            localStorage.setItem('countries', JSON.stringify(json));
            return json;
        }) :
        Promise.resolve(JSON.parse(countries)));
}

export default {

    map: (json) => { return json },

    getList: (params) => {
        return getLocaleCountries().then(
            (countries) => {
                return {
                    data: countries,
                    count: countries.length
                }
            })
    },

    getOne: (params) => {
        return getLocaleCountries().then(cs => cs.find(c => params.id === c.id));
    },

    getMany: (params) => {
        return getLocaleCountries().then(cs => cs.filter(c => params.ids.indexOf(c.id) !== -1));
    }
};
