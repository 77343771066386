import * as React from "react";
import {
    AutocompleteInput,
    BooleanInput,
    Edit,
    FormDataConsumer,
    FormTab,
    ImageField,
    ImageInput,
    NumberInput,
    RadioButtonGroupInput,
    ReferenceInput,
    TabbedForm,
    TextInput
} from 'react-admin';
import { TypeList } from './enums/type';
import title from './title';
import ImagePictureField from '../crossCutting/fields/imagePictureField';
import LockIcon from '@material-ui/icons/Lock';

const InfraTitle = title;

const InfraEdit = props => (
    <Edit title={<InfraTitle />} {...props}>
        <TabbedForm>
            <FormTab label="hgw.summary">
                <TextInput disabled source="id" />
                <TextInput label="hgw.label" source="name" />
                <RadioButtonGroupInput
                    source="type"
                    choices={TypeList}
                    options={{ 'row': false }} />
                <ReferenceInput label="hgw.infra.usage.label" source="usageId" reference="infrausage" filterToQuery={searchText => ({ search: searchText })} perPage={25}>
                    <AutocompleteInput optionText="name" optionValue="id" />
                </ReferenceInput>
                <TextInput label="hgw.description" multiline source="description" />
            </FormTab>
            <FormTab label="hgw.infra.surfaces">
                <ReferenceInput label="hgw.infra.parent" source="parentId" reference="infra" filterToQuery={searchText => ({ search: searchText, exclude: props.id })} perPage={25}>
                    <AutocompleteInput optionText="name" optionValue="id" />
                </ReferenceInput>
                <BooleanInput label="hgw.infra.issurfaceoverride" options={{ checkedIcon: <LockIcon /> }} source="isSurfaceOverride" />
                <FormDataConsumer>
                    {({ formData, ...rest }) => formData.isSurfaceOverride &&
                        <NumberInput label="hgw.infra.surface" source="surface" />
                    }
                </FormDataConsumer>
                <BooleanInput label="hgw.infra.isheatedsurfaceoverride" options={{ checkedIcon: <LockIcon /> }} source="isHeatedSurfaceOverride" />
                <FormDataConsumer>
                    {({ formData, ...rest }) => formData.isHeatedSurfaceOverride &&
                        <NumberInput label="hgw.infra.heatedsurface" source="heatedSurface" />
                    }
                </FormDataConsumer>
                <NumberInput label="hgw.location.latitude" source="location.latitude" />
                <NumberInput label="hgw.location.longitude" source="location.longitude" />
            </FormTab>
            <FormTab label="hgw.logo">
                <ImagePictureField label="hgw.picturecurrent" source="picture" />
                <ImageInput label="hgw.picturenew" source="pictureraw" accept="image/*">
                    <ImageField source="src" title="title" />
                </ImageInput>
            </FormTab>
        </TabbedForm>
    </Edit>
);

export default InfraEdit;