import Level1 from '../pictures/level1.png';
import Level2 from '../pictures/level2.png';

const LevelEnums = {
    acquiring: { icon: Level1, name: 'hgw.flux.origin.acquiring' },
    cloud: { icon: Level2, name: 'hgw.flux.origin.cloud' }
}

export const LevelList = Object.entries(LevelEnums).map(([id, obj]) => {
    obj.id = id;
    return obj
})

export default LevelEnums;