import * as React from "react";
import {
    Create,
    SimpleForm,
    TextInput,
} from 'react-admin';

const InfraCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <TextInput label="hgw.label" source="name" />
        </SimpleForm>
    </Create>
);

export default InfraCreate;