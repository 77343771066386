import * as React from "react";
import ReflectClassAdminLogo from '../pictures/reflectAdmin32.png';
import ReflectClassAdminCmdLogo from '../pictures/reflectAdminCmd32.png';
import ReflectClassAnalogLogo from '../pictures/reflectAnalog32.png';
import ReflectClassAnalogCmdLogo from '../pictures/reflectAnalogCmd32.png';
import ReflectClassDefaultLogo from '../pictures/reflectDefault32.png';
import ReflectClassDefaultCmdLogo from '../pictures/reflectDefaultCmd32.png';
//import ReflectClassDailyLogo from '../logos/reflectDaily32.png';
import ReflectClassDailyCmdLogo from '../pictures/reflectDailyCmd32.png';
import ReflectClassDigitLogo from '../pictures/reflectDigit32.png';
import ReflectClassDigitCmdLogo from '../pictures/reflectDigitCmd32.png';
import ReflectClassStateLogo from '../pictures/reflectState32.png';
import ReflectClassStateCmdLogo from '../pictures/reflectStateCmd32.png';
import ReflectClassTextLogo from '../pictures/reflectText32.png';
import ReflectClassTextCmdLogo from '../pictures/reflectTextCmd32.png';
import ReflectClassUopLogo from '../pictures/reflectUop32.png';
import ReflectClassUopCmdLogo from '../pictures/reflectUopCmd32.png';
import ReflectClassZoneLogo from '../pictures/reflectZone32.png';
import ReflectClassZoneCmdLogo from '../pictures/reflectZoneCmd32.png';

function GetReflectClassLogo(type, isCmd) {
    switch (type) {
        case 1:
            return isCmd ? ReflectClassDigitCmdLogo : ReflectClassDigitLogo;
        case 2:
            return isCmd ? ReflectClassAnalogCmdLogo : ReflectClassAnalogLogo;
        case 3:
            return isCmd ? ReflectClassDailyCmdLogo : ReflectClassDefaultLogo; // no cmd logo
        case 4:
            return isCmd ? ReflectClassZoneCmdLogo : ReflectClassZoneLogo;
        case 5:
            return isCmd ? ReflectClassTextCmdLogo : ReflectClassTextLogo;
        case 6:
            return isCmd ? ReflectClassStateCmdLogo : ReflectClassStateLogo;
        case 7:
            return isCmd ? ReflectClassUopCmdLogo : ReflectClassUopLogo;
        case 8:
            return isCmd ? ReflectClassAdminCmdLogo : ReflectClassAdminLogo;
        default:
            return isCmd ? ReflectClassDefaultCmdLogo : ReflectClassDefaultLogo;
    }
}

const ReflectClassField = ({ record, source }) => {
    var logo = GetReflectClassLogo(record[source], record.allowCommand);
    return (
        <>
            <img src={logo} alt={`class${record[source]}`} />
        </>
    )
}

ReflectClassField.defaultProps = { addLabel: true }

export default ReflectClassField;