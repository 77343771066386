import * as React from "react";
import {
    BooleanInput,
    Edit,
    TabbedForm,
    FormTab,
    ImageField,
    ImageInput,
    ReferenceInput,
    SelectInput,
    TextInput
} from 'react-admin';
import ImagePictureField from '../crossCutting/fields/imagePictureField';
import SelectOrganization from "../organization/inputs/select";
import title from './title';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import PanToolIcon from '@material-ui/icons/PanTool';

const BusinessAccountTitle = title;

const BusinessAccountEdit = props => (
    <Edit title={<BusinessAccountTitle />} {...props}>
        <TabbedForm>
            <FormTab label="hgw.summary">
                <TextInput disabled source="id" />
                <TextInput disabled source="wac" />
                <TextInput source="name" />
                <TextInput multiline source="description" />
                <TextInput source="url" />
                <BooleanInput label="hgw.businessaccount.autovalidresources" source="autoValidResources" options={{ icon: <PanToolIcon />, checkedIcon: <AutorenewIcon /> }} defaultValue={false} />
            </FormTab>
            <FormTab label="hgw.logo">
                <ImagePictureField label="hgw.picturecurrent" source="picture" />
                <ImageInput label="hgw.picturenew" source="pictureraw" accept="image/*">
                    <ImageField source="src" title="title" />
                </ImageInput>
            </FormTab>
            <FormTab label="hgw.organization.many">
                <SelectOrganization label="hgw.organization.owner" source="ownerCode" />
                <SelectOrganization label="hgw.organization.operator" source="operatorCode" />
            </FormTab>
            <FormTab label="hgw.address.label">
                <TextInput label="hgw.address.phone" source="address.phone" />
                <TextInput label="hgw.address.fax" source="address.fax" />
                <TextInput label="hgw.address.street" source="address.street" />
                <TextInput label="hgw.address.postalcode" source="address.postalCode" />
                <TextInput label="hgw.address.city" source="address.city" />
                <ReferenceInput label="hgw.country.label" source="address.countryId" reference="country">
                    <SelectInput optionText="name" />
                </ReferenceInput>
            </FormTab>
            <FormTab label="hgw.businessaccount.offer.label" disabled>
            </FormTab>
        </TabbedForm>
    </Edit>
);

export default BusinessAccountEdit;