const StatusEnums = {
    provisionning: { name: 'hgw.businessaccount.status.provisionning' },
    enabled: { name: 'hgw.enabled' },
    deleting: { name: 'hgw.businessaccount.status.deleting' },
    deleted: { name: 'hgw.businessaccount.status.deleted' }
}

export const StatusList = Object.entries(StatusEnums).map(([id, obj]) => {
    obj.id = id;
    return obj
})

export default StatusEnums;