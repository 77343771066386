import * as React from "react";
import { Admin, Resource } from 'react-admin';
import authProvider from './provider/services/authProvider';
import wapiProvider from './provider/factory/providerFactory';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import frenchMessages from 'ra-language-french';
import englishMessages from 'ra-language-english';
import hgwFrenchMessages from './i18n/fr';
import hgwEnglishMessages from './i18n/en';

// resources
import activity from './resources/activity';
import businessAccount from './resources/businessaccount';
import badge from './resources/badge';
import deviceLora from './resources/devicelora';
import infra from './resources/infra';
import infrausage from './resources/infra/usage';
import flux from './resources/flux';
import fluxitem from './resources/fluxitem';
import notificationPolicy from './resources/notificationpolicy';
import reflect from './resources/reflect';
import smartLocalUnit from './resources/smartlocalunit';
import tag from './resources/tag';
import scope from './resources/scope';
import authorization from './resources/authorization';
import role from './resources/role';
import extendedProperty from './resources/extendedproperty';

// layout
import CustomLayout from './layout/customLayout';
import CustomRoute from './layout/customRoute';
import CustomLogoutButton from './layout/customLogoutButton';
import { Login } from './oidc/Login';
import { theme } from "./theme";

//stylesheet
import './font.css';

const messages = {
    fr: { ...frenchMessages, ...hgwFrenchMessages },
    en: { ...englishMessages, ...hgwEnglishMessages },
};

const i18nProvider = polyglotI18nProvider(locale => messages.fr, 'fr');

// react-admin app initialization
const App = () =>
(
    <Admin theme={theme} layout={CustomLayout} loginPage={Login} logoutButton={CustomLogoutButton} authProvider={authProvider} dataProvider={wapiProvider} i18nProvider={i18nProvider} customRoutes={CustomRoute}>
        <Resource name="smartlocalunit" {...smartLocalUnit} options={{ label: i18nProvider.translate('hgw.smartlocalunit.many') }} />
        <Resource name="flux" {...flux} options={{ label: i18nProvider.translate('hgw.flux.many') }} />
        <Resource name="fluxitem" {...fluxitem} options={{ label: i18nProvider.translate('hgw.flux.item.many') }} />
        <Resource name="reflect" {...reflect} options={{ label: i18nProvider.translate('hgw.reflect.many') }} />
        <Resource name="devicelora" {...deviceLora} options={{ label: i18nProvider.translate('hgw.device.lora.many') }} />
        <Resource name="infra" {...infra} options={{ label: i18nProvider.translate('hgw.infra.many') }} />
        <Resource name="badge" {...badge} options={{ label: i18nProvider.translate('hgw.badge.many') }} />
        <Resource name="extendedproperty" {...extendedProperty} options={{ label: i18nProvider.translate('hgw.extendedproperty.many'), displaymenu: false }} />
        <Resource name="activity" {...activity} options={{ label: i18nProvider.translate('hgw.activity.many'), displaymenu: false }} />
        <Resource name="tag" {...tag} options={{ label: i18nProvider.translate('hgw.tag.many') }} />
        <Resource name="scope" {...scope} options={{ label: i18nProvider.translate('hgw.scope.many'), businessaccount: true }} />
        <Resource name="authorization" {...authorization} options={{ label: i18nProvider.translate('hgw.authorization.businessaccount.many'), businessaccount: true }} />
        <Resource name="role" {...role} options={{ label: i18nProvider.translate('hgw.role.many') }} />
        <Resource name="member" {...authorization} options={{ label: i18nProvider.translate('hgw.authorization.organization.many'), organization: true, displaymenu: false }} />
        <Resource name="businessaccount" {...businessAccount} options={{ displaymenu: false }} />
        <Resource name="notificationpolicy" {...notificationPolicy} options={{ label: i18nProvider.translate('hgw.notificationpolicy.many'), displaymenu: false }} />
        <Resource name="app" />
        <Resource name="country" />
        <Resource name="culture" />
        <Resource name="infrausage" {...infrausage} options={{ label: i18nProvider.translate('hgw.infra.usage.many'), displaymenu: false }} />
        <Resource name="operation" />
        <Resource name="organization" />
        <Resource name="reflectclass" />
        <Resource name="user" />
        <Resource name="wumfunction" />
        <Resource name="wummaterial" />
        <Resource name="wumtype" />
        <Resource name="wumunit" />
    </Admin>
);

export default App;