const MetricEnums = {
    delta: { name: 'hgw.activity.metric.delta' },
    min: { name: 'hgw.activity.metric.min' },
    max: { name: 'hgw.activity.metric.max' },
    in: { name: 'hgw.activity.metric.in' },
    out: { name: 'hgw.activity.metric.out' },
    average: { name: 'hgw.activity.metric.average' },
    sum: { name: 'hgw.activity.metric.sum' },
    count: { name: 'hgw.activity.metric.count' },
}

export const MetricList = Object.entries(MetricEnums).map(([id, obj]) => {
    obj.id = id;
    return obj
})

export default MetricEnums;
