import mainLogo from '../pictures/smartLocalUnit32.png';
import redyLogo from '../pictures/redy32.png';
import easyLogo from '../pictures/easy32.png';

const ModelEnums = {
    easy: { name: 'e@sy', logo: easyLogo },
    redyK4: { name: 'REDY (K4)', logo: redyLogo },
    redox: { name: 'Redox', logo: mainLogo },
    easyProPC: { name: 'REDY-Pc', logo: mainLogo },
    redyK7: { name: 'REDY (K7)', logo: redyLogo },
}

export const ModelList = Object.entries(ModelEnums).map(([id, obj]) => {
    obj.id = id;
    return obj
})

export default ModelEnums;