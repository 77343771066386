import * as React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Badge, Tooltip } from '@material-ui/core';
import { useTranslate } from 'react-admin';

const useStyles = makeStyles({
    badge: { color: "red", marginRight: "15px" }
});

const ErrorField = ({ record, addLabel }) => {
    const translate = useTranslate();
    const classes = useStyles();
    const error = record?.error ?? record;
    const count = record?.count ?? 0;
    return addLabel ?
        <Badge badgeContent={count} className={classes.badge} >
            <error.icon />
            <span>&nbsp;{translate(error.name)}</span>
        </Badge> :
        <Tooltip title={translate(error.name)} arrow>
            <Badge badgeContent={count} className={classes.badge} >
                <error.icon />
            </Badge>
        </Tooltip>
}

ErrorField.defaultProps = { addLabel: true }

export default ErrorField;