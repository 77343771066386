import httpClient from './services/httpClient';
import requestUtils from './factory/requestUtils';
import contextStore from '../provider/services/contextStore';
import ActivityTypeEnum from '../resources/activity/enums/type';

const root = () => 'activity';
const ActivityType = ActivityTypeEnum;
const getSources = (params) => {
    const sources = {};
    params.data.sources.forEach(s => {
        const key = s.key;
        delete s.key;
        sources[key] = s;
    });

    return sources;
}

export default {

    map: (json) => {
        json.sources = Object.entries(json.sources ?? {}).map(p => ({
            key: p[0],
            fluxItemId: p[1].fluxItemId,
            metric: p[1].metric
        }));

        return json;
    },

    getList: (params) => {
        params.wac = contextStore.getWac();
        const query = requestUtils.buildQuery(params);
        return httpClient(`${root()}?${query}`).then(({ json }) => json)
    },

    getOne: (params) => {
        return httpClient(`${root()}/${params.id}`).then(({ json }) => json)
    },

    getMany: (params) => {
        const query = params.ids.map((id) => `ids=${id}`).join("&");
        return httpClient(`${root()}/many?${query}`).then(({ json }) => json)
    },

    getManyReference: (params) => {
        params.wac = contextStore.getWac();
        const query = requestUtils.buildQuery(params);
        return httpClient(`${root()}?${query}`).then(({ json }) => {
            return json;
        })
    },

    update: (params) => {
        params.data.sources = getSources(params);
        return httpClient(`${root()}/${ActivityType[params.data.type].path}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => json)
    },

    updateMany: (params) => {
        //const query = {
        //    filter: JSON.stringify({ id: params.ids }),
        //};
        //return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
        //    method: 'PUT',
        //    body: JSON.stringify(params.data),
        //}).then(({ json }) => ({ data: json }));
    },

    create: (params) => {
        params.data.wac = contextStore.getWac();
        params.data.sources = getSources(params);
        return httpClient(`${root()}/${ActivityType[params.data.type].path}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => json)
    },

    delete: (params) => {
        return httpClient(`${root()}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => json)
    },

    deleteMany: (params) => {
        //const query = {
        //    filter: JSON.stringify({ id: params.ids }),
        //};
        //return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
        //    method: 'DELETE',
        //    body: JSON.stringify(params.data),
        //}).then(({ json }) => ({ data: json }));
    },
};


