import * as React from "react";
import {
    Create,
    SelectInput,
    SimpleForm,
    TextInput,
    useRedirect,
} from 'react-admin';
import { PeriodList } from "./enums/period";
import PeriodField from '../crossCutting/fields/periodField';
import title from './title';
const FluxTitle = title;

export const FluxCreate = props => {
    const redirect = useRedirect();
    const onSuccess = ({ data }) => {
        redirect('/fluxitem/create', null, null, null, { record: { fluxId: data.id } });
    };

    return (
        <Create title={<FluxTitle />} onSuccess={onSuccess} undoable={false} {...props}>
            <SimpleForm>
                <TextInput label="hgw.extkey" source="extKey" />
                <TextInput label="hgw.label" source="name" />
                <TextInput label="hgw.description" multiline source="description" />
                <SelectInput label="hgw.period.label" source="period" choices={PeriodList} optionValue="period" optionText={<PeriodField />} allowEmpty />
            </SimpleForm>
        </Create>
    )
};

export default FluxCreate;