import * as React from "react";
import {
    ArrayInput,
    AutocompleteInput,
    BooleanInput,
    Create,
    DateInput,
    FormTab,
    ReferenceInput,
    SelectInput,
    SimpleFormIterator,
    TabbedForm,
    TextInput,
} from 'react-admin';
import ActivityTypeField from './fields/type';
import MetricTypeField from './fields/metric';
import PeriodField from '../crossCutting/fields/periodField';
import title from './title';
import Aside from './aside';
import MetricEnum from './enums/metric';
import { PeriodList } from "../flux/enums/period";
import { TypeList } from './enums/type';

const ActivityTitle = title;
const ActivityAside = Aside;
const metricChoices = Object.keys(MetricEnum).map(l => ({ id: l }));
const fluxItemText = record => {
    return `${!record?.name || record?.name === "" ? record?.label : record.name} #${record?.id}`;
};

export const ActivityCreate = props => {
    return (
        <Create title={<ActivityTitle />} aside={<ActivityAside />} {...props}>
            <TabbedForm redirect="list">
                <FormTab label="hgw.summary">
                    <SelectInput label="hgw.activity.type.label" source="type" choices={TypeList} optionText={<ActivityTypeField />} initialValue={1} allowEmpty={false} />
                    <TextInput label="hgw.label" source="name" />
                    <TextInput label="hgw.description" source="description" multiline />
                    <BooleanInput label="hgw.enabled" source="enabled" initialValue={true} />
                </FormTab>
                <FormTab label="hgw.config">
                    <ReferenceInput label="hgw.activity.formula.targetfluxitem" source="targetFluxItemId" reference="fluxitem"
                        filterToQuery={searchText => ({ search: searchText, origin: 'cloud' })}
                        perPage={25}
                        emptyText="hgw.undefined">
                        <AutocompleteInput optionText={fluxItemText} optionValue="id" />
                    </ReferenceInput>
                    <SelectInput label="hgw.period.label" source="period" choices={PeriodList} optionValue="period" optionText={<PeriodField />} allowEmpty />
                    <TextInput label="hgw.activity.formula.label" source="formula" multiline />
                    <ArrayInput label="hgw.activity.formula.sources" source="sources">
                        <SimpleFormIterator>
                            <TextInput label="hgw.activity.formula.key" source="key" />
                            <ReferenceInput label="hgw.flux.item.label" source="fluxItemId" reference="fluxitem"
                                filterToQuery={searchText => ({ search: searchText })}
                                perPage={25}
                                emptyText="hgw.undefined">
                                <AutocompleteInput optionText={fluxItemText} optionValue="id" />
                            </ReferenceInput>
                            <SelectInput label="hgw.activity.metric.label" source="metric" choices={metricChoices} optionText={<MetricTypeField />} allowEmpty={false} />
                        </SimpleFormIterator>
                    </ArrayInput>
                    <DateInput label="hgw.activity.formula.startdate" source="startDate" />
                </FormTab>
            </TabbedForm>
        </Create>
    )
};

export default ActivityCreate;