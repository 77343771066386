import * as React from "react";
import InfraLogo from './pictures/site32.png';
import { Avatar, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    avatar: {
        height: 32,
        width: 32,
    }
});

export const InfraIcon = props => {
    const classes = useStyles();
    return (
        < Avatar src={InfraLogo} className={classes.avatar} />
    )
}

export default InfraIcon;