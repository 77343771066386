import * as React from "react";
import {
    Create,
    SimpleForm,
    NumberInput,
    RadioButtonGroupInput,
    TextInput,
    AutocompleteInput,
    ReferenceInput
} from 'react-admin';
import { TypeList } from './enums/type';

const InfraCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <TextInput label="hgw.label" source="name" />
            <RadioButtonGroupInput
                source="type"
                choices={TypeList}
                options={{ 'row': false }} />
            <TextInput label="hgw.description" multiline source="description" />
            <ReferenceInput label="hgw.infra.parent" source="parentId" reference="infra" filterToQuery={searchText => ({ search: searchText })} perPage={25}>
                <AutocompleteInput optionText="name" optionValue="id" />
            </ReferenceInput>
            <ReferenceInput label="hgw.infra.usage.label" source="usageId" reference="infrausage" filterToQuery={searchText => ({ search: searchText })} perPage={25}>
                <AutocompleteInput optionText="name" optionValue="id" />
            </ReferenceInput>
            <NumberInput label="hgw.infra.surface" source="surface" />
            <NumberInput label="hgw.infra.heatedsurface" source="heatedSurface" />
            <NumberInput label="hgw.location.latitude" source="location.latitude" />
            <NumberInput label="hgw.location.longitude" source="location.longitude" />
        </SimpleForm>
    </Create>
);

export default InfraCreate;