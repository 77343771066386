import httpClient from './services/httpClient';
import contextStore from '../provider/services/contextStore';

const root = () => `wac/${contextStore.getWac()}/reflect/class`;

function getLocaleClasses(clearCache = false) {
    const cacheKey = `${contextStore.getWac()}_reflectclass`;
    var classes = localStorage.getItem(cacheKey);
    return (!classes || clearCache ?
        httpClient(root()).then(({ json }) => {
            localStorage.setItem(cacheKey, JSON.stringify(json));
            return json;
        }) :
        Promise.resolve(JSON.parse(classes)));
}

export default {

    map: (json) => { return json; },

    getList: (params) => {
        return getLocaleClasses().then(
            (classes) => {
                return {
                    data: classes,
                    count: classes.length
                }
            })
    },

    getOne: (params) => {
        return getLocaleClasses().then(
            (classes) => {
                var reflectClass = classes.find(t => params.id === t.id.toString());
                return reflectClass ? reflectClass :
                    getLocaleClasses(true).then(
                        (classes) => {
                            return classes.find(t => params.id === t.id.toString());
                        })
            })
    },

    getMany: (params) => {
        return getLocaleClasses().then(
            (classes) => {
                var reflectClasses = classes.filter(t => params.ids.indexOf(t.id.toString()) !== -1);
                return reflectClasses.length === params.ids.length ? reflectClasses:
                    getLocaleClasses(true).then(
                        (classes) => {
                            return classes.filter(t => params.ids.indexOf(t.id.toString()) !== -1)
                        })
            })
    },

    getManyReference: (params) => {

    },
};
