import { useEffect, useState } from "react"
import { useDataProvider } from "react-admin";

export const useHubApplication = () => {
  const [hubApplication, setHubApplication] = useState(null);
  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider.getList('app', { pagination: { page: 1, perPage: 100 } })
      .then(({ data }) => {
        const app = data.find((e) => e.id === 1);
        setHubApplication(app);
      })
      .catch(error => {
        throw error;
      });
  }, [dataProvider]);

  return hubApplication;
}