import CloudDoneIcon from '@material-ui/icons/CloudDone';
import CloudQueueIcon from '@material-ui/icons/CloudQueue';
import AddToQueueIcon from '@material-ui/icons/AddToQueue';
import RemoveFromQueueIcon from '@material-ui/icons/RemoveFromQueue';

const DeployEnums = [
    { id: 1, key: "Deployed", name: 'hgw.badge.deploy.deployed', icon: CloudDoneIcon },
    { id: 2, key: "ToAdd", name: 'hgw.badge.deploy.toadd', icon: AddToQueueIcon },
    { id: 3, key: "ToUpdate", name: 'hgw.badge.deploy.toupdate', icon: CloudQueueIcon },
    { id: 4, key: "ToRemove", name: 'hgw.badge.deploy.toremove', icon: RemoveFromQueueIcon }
]

export default DeployEnums;