import * as React from "react";
import { useMediaQuery } from '@material-ui/core';
import {
    List,
    Filter,
    Datagrid,
    SelectField,
    SimpleList,
    TextField,
    TextInput,
    useTranslate,
} from 'react-admin';
import LabelField from './fields/label';
import RoleIcon from './icon';
import AlarmBadge from '../reflect/fields/alarmBadge';
import { CriticityList } from '../reflect/enums/criticity';

const ScopeFilter = (props) => (
    <Filter {...props}>
        <TextInput label="hgw.search" source="search" alwaysOn />
    </Filter>
);

const ScopeList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const translate = useTranslate();
    return (
        <List filters={<ScopeFilter />} {...props}>
            {isSmall ?
                (<SimpleList
                    linkType="show"
                    leftIcon={record => <RoleIcon />}
                    primaryText={record => record.label}
                />) :
                (<Datagrid rowClick="show">
                    <LabelField source="label" />
                    <TextField label="hgw.scope.query" source="description" />
                    <SelectField label="hgw.scope.criticitydiffusion" source="criticityDiffusion" choices={CriticityList} optionText={<AlarmBadge />} allowEmpty emptyText={translate("hgw.scope.criticitydiffusionempty")} />
                </Datagrid>)}
        </List>
    )
};

export default ScopeList;
