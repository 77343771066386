import * as React from "react";
import { useState, useEffect } from 'react';
import {
    SelectInput,
    TextInput,
    useDataProvider,
    Loading
} from 'react-admin';

const SelectBusinessAccount = ({ ...props }) => {
    const [businessaccounts, setBusinessAccounts] = useState([]);
    const [loading, setLoading] = useState(true);
    const dataProvider = useDataProvider();
    useEffect(() => {
        dataProvider.getList('businessaccount', { filter: { friendly: true } })
            .then(({ data }) => {
                setBusinessAccounts(data);
                setLoading(false);
            })
            .catch(error => {
                setBusinessAccounts([]);
                setLoading(false);
            })
    }, []);
    if (loading) return <Loading />;
    return (<>
        {businessaccounts.length > 0 ?
            <SelectInput {...props} label='hgw.businessaccount.near' choices={businessaccounts} optionText="name" optionValue="wac" emptyText="hgw.businessaccount.other" allowEmpty />
            : null}
        <br />
        <TextInput label='hgw.businessaccount.wac' source={props.source} />
    </>)
};

SelectBusinessAccount.defaultProps = { addLabel: true }

export default SelectBusinessAccount;