import * as React from "react";
import { useMediaQuery } from '@material-ui/core';
import {
    List,
    Filter,
    Datagrid,
    SelectField,
    TextInput,
    SimpleList,
    TextField
} from 'react-admin';
import ValueTypeEnums from './valueType';
import ResourceTypeField from './fields/resourceTypeField';
import LabelField from './fields/label';
import ExtendedPropertyIcon from './icon';

const ExtendedPropertyFilter = (props) => (
    <Filter {...props}>
        <TextInput label="hgw.search" source="search" alwaysOn />
        {/*  <BooleanInput label="Global" source="isGlobal" defaultValue="true" />*/}
    </Filter>
);

const ExtendedPropertyList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List filters={<ExtendedPropertyFilter />} {...props}>
            {isSmall ?
                (<SimpleList
                    linkType="edit"
                    leftIcon={record => <ExtendedPropertyIcon />}
                    primaryText={record => record.label}
                    secondaryText={record => record.resourceType}
                />) :
                (<Datagrid rowClick="edit">
                    <LabelField label="hgw.label" source="label" />
                    <TextField label="hgw.description" source="description" />
                    <SelectField label="hgw.extendedproperty.valuetype.label" source="valueType" choices={ValueTypeEnums} />
                    <ResourceTypeField label="hgw.extendedproperty.resourcetype" source="resourceType" />
                </Datagrid>)}
        </List>
    )
};

export default ExtendedPropertyList;