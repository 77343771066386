import AuthorizationList from './list';
import AuthorizationCreate from './create';
import AuthorizationEdit from './edit';
import AuthorizationIcon from './icon';

export default {
    list: AuthorizationList,
    create: AuthorizationCreate,
    edit: AuthorizationEdit,
    icon: AuthorizationIcon
};