import * as React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Badge, Tooltip } from '@material-ui/core';
import { useTranslate } from 'react-admin';

const useStyles = makeStyles({
    level1: { color: "grey", marginRight: "15px" },
    level2: { color: "yellow", marginRight: "15px" },
    level3: { color: "orange", marginRight: "15px" },
    level4: { color: "red", marginRight: "15px" }
});

const DeployField = ({ record, addLabel }) => {
    const translate = useTranslate();
    const classes = useStyles();
    const deploy = record?.deploy ?? record;
    const count = record?.count ?? 0;
    const className = classes[`level${deploy.id}`];
    return addLabel ?
        <Badge color="primary" badgeContent={count} className={className} >
            <deploy.icon />
            <span>&nbsp;{translate(deploy.name)}</span>
        </Badge> :
        <Tooltip title={translate(deploy.name)} arrow>
            <Badge color="primary" badgeContent={count} className={className} >
                <deploy.icon />
            </Badge>
        </Tooltip>
}

DeployField.defaultProps = { addLabel: true }

export default DeployField;