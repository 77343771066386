import httpClient from './services/httpClient';
import mapper from './factory/mapper';
import requestUtils from './factory/requestUtils';
import contextStore from '../provider/services/contextStore';

const root = () => `wac/${contextStore.getWac()}/infra`;

export default {

    map: (json) => {
        mapper.mapTags(json);


        return json;
    },

    getList: (params) => {
        const query = requestUtils.buildQuery(params);
        return httpClient(`${root()}?${query}`).then(({ json }) => {
            if (params.hasOwnProperty('exclude')) {
                json.data = json.data.filter(i => i.id !== parseInt(params.exclude));
            }

            return json;
        })
    },

    getOne: (params) => {
        return httpClient(`${root()}/${params.id}`).then(({ json }) => json);
    },

    getMany: (params) => {
        const query = params.ids.map((id) => `ids=${id}`).join("&");
        return httpClient(`${root()}/many?${query}`).then(({ json }) => json);
    },

    update: (params) => {

        const puts = [];

        var paramsLocation = { data: params.data.location, previousData: params.previousData.location };
        var dataLocation = requestUtils.getDatas(paramsLocation, ['latitude', 'longitude']);
        var dataSurface = requestUtils.getDatas(params, ['surface', 'isSurfaceOverride']);
        var dataHeatedSurface = requestUtils.getDatas(params, ['heatedSurface', 'isHeatedSurfaceOverride']);
        var dataUpdate = requestUtils.getDatas(params, ['id', 'name', 'description', 'type', 'usageId']);
        if (dataUpdate || dataLocation || dataSurface || dataHeatedSurface) {
            if (!dataUpdate) dataUpdate = { id: params.data.id };
            if (dataLocation) dataUpdate.location = dataLocation; // latitude and/or longitude
            if (dataSurface) dataUpdate.surface = dataSurface.isSurfaceOverride ? dataSurface.surface : 0; // isSurfaceOverride and/or surface has changed
            if (dataHeatedSurface) dataUpdate.heatedSurface = dataHeatedSurface.isHeatedSurfaceOverride ? dataHeatedSurface.heatedSurface : 0; // isHeatedSurfaceOverride and/or heatedSurface has changed

            puts.push({
                url: root(),
                method: 'PUT',
                data: JSON.stringify(dataUpdate)
            });
        }

        if (!!params.data.pictureraw) {
            let formData = new FormData();
            formData.append('id', params.previousData.id);
            formData.append('wac', params.previousData.wac);
            formData.append('picture', params.data.pictureraw.rawFile);
            puts.push({
                url: `${root()}/picture`,
                method: 'POST',
                data: formData
            });
        }

        var dataInfra = requestUtils.getDatas(params, ['id', 'parentId']);
        if (dataInfra) {
            puts.push({
                url: `${root()}/localize`,
                method: 'PATCH',
                data: JSON.stringify(dataInfra)
            });
        }

        return requestUtils.sendSynchrone(puts);
    },

    create: (params) => {
        return httpClient(root(), {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => json)
    },

    delete: (params) => {
        return httpClient(`${root()}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => json)
    }
};