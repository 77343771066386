function getLocaleApps() {

    var apps = sessionStorage.getItem('apps');
    if (apps) return Promise.resolve(JSON.parse(apps));

    const request = new Request('/spa/apps', {
        method: 'GET',
        headers: new Headers({ 'Content-Type': 'application/json' }),
    });

    return fetch(request)
        .then(response => {
            return response.json().then((data) => {
                sessionStorage.setItem('apps', JSON.stringify(data));
                return data;
            })
        });
}

export default {

    map: (json) => {
        return json
    },

    getList: (params) => {
        return getLocaleApps().then(items => {
            return {
                data: items,
                count: items.length
            }
        })
    },
};
