import * as React from "react";
import { useMediaQuery } from '@material-ui/core';
import {
    AutocompleteArrayInput,
    ChipField,
    Datagrid,
    Filter,
    List,
    NumberField,
    ReferenceArrayInput,
    ReferenceField,
    SelectInput,
    SimpleList,
    TextField,
    TextInput
} from 'react-admin';
import FluxItemLevelField from './fields/level';
import MomentField from '../crossCutting/fields/momentField';
import FluxReferenceField from '../flux/fields/reference';
import { LevelList } from './enums/level';

const FluxItemFilter = (props) => (
    <Filter {...props}>
        <TextInput label="hgw.search" source="search" alwaysOn />
        <ReferenceArrayInput label="hgw.scope.many" source="scopeIds" reference="scope" filterToQuery={searchText => ({ search: searchText })} perPage={25} alwaysOn>
            <AutocompleteArrayInput optionText="label" optionValue="id" />
        </ReferenceArrayInput>
        <ReferenceArrayInput label="hgw.infra.many" source="infraIds" reference="infra" filterToQuery={searchText => ({ search: searchText })} perPage={25} alwaysOn>
            <AutocompleteArrayInput optionText="name" optionValue="id" />
        </ReferenceArrayInput>
        <ReferenceArrayInput label="hgw.wum.label" source="wumUnits" reference="wumunit" filterToQuery={searchText => ({ search: searchText })} perPage={250} alwaysOn>
            <AutocompleteArrayInput optionText="name" optionValue="symbol" />
        </ReferenceArrayInput>
        <SelectInput label="hgw.flux.origin.label" source="origin" choices={LevelList} optionText={<FluxItemLevelField />} allowEmpty alwaysOn />
        <ReferenceArrayInput label="hgw.smartlocalunit.many" source="smartLocalUnitIds" reference="smartlocalunit" filterToQuery={searchText => ({ search: searchText })} perPage={25}>
            <AutocompleteArrayInput optionText="name" optionValue="id" />
        </ReferenceArrayInput>
        <ReferenceArrayInput label="hgw.flux.many" source="fluxIds" reference="flux" filterToQuery={searchText => ({ search: searchText })} perPage={25}>
            <AutocompleteArrayInput optionText="name" optionValue="id" />
        </ReferenceArrayInput>
    </Filter>
);

export const FluxItemList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List title="hgw.flux.item.many" filters={<FluxItemFilter />} filter={{ includePoints: true }} {...props}>
            {isSmall ?
                (<SimpleList
                    linkType="show"
                    leftIcon={record => <FluxItemLevelField record={record} source="origin" />}
                    primaryText={record => record.name}
                    secondaryText={record => record.description ?? '-'}
                    tertiaryText={record => !record.points || record.points.length === 0 ? '-' :
                        <span>{record.points[0].value} - <MomentField label="hgw.flux.point.lastdate" record={record} source="points.0.date" /></span>}
                />) :
                (<Datagrid rowClick="show">
                    <FluxItemLevelField label="hgw.flux.origin.label" source="origin" addLabel={false} />
                    <TextField label="hgw.label" source="name" />
                    <ReferenceField label="hgw.flux.label" source="fluxId" reference="flux" link="show" sortable={false}>
                        <FluxReferenceField />
                    </ReferenceField>
                    <NumberField label="hgw.flux.point.lastvalue" source="points.0.value" sortable={false} />
                    <MomentField label="hgw.date" source="points.0.date" sortable={false} />
                    <ReferenceField label="hgw.wum.unit" source="wum.unit" reference="wumunit" sortable={false}>
                        <ChipField source="name" size="small" />
                    </ReferenceField>
                    <ReferenceField label="hgw.wum.type" source="wum.type" reference="wumtype" sortable={false}>
                        <ChipField source="name" size="small" />
                    </ReferenceField>
                    <ReferenceField label="hgw.wum.function" source="wum.function" reference="wumfunction" sortable={false}>
                        <ChipField source="name" size="small" />
                    </ReferenceField>
                    <ReferenceField label="hgw.wum.material" source="wum.material" reference="wummaterial" sortable={false}>
                        <ChipField source="name" size="small" />
                    </ReferenceField>
                </Datagrid>)}
        </List>
    )
};

export default FluxItemList;
