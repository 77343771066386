import * as React from "react";
import {
    Edit,
    FormTab,
    TabbedForm,
    TextInput
} from 'react-admin';
import title from './title';

const InfraTitle = title;

const InfraEdit = props => (
    <Edit title={<InfraTitle />} {...props}>
        <TabbedForm>
            <FormTab label="hgw.summary">
                <TextInput disabled source="id" />
                <TextInput label="hgw.label" source="name" />
            </FormTab>
        </TabbedForm>
    </Edit>
);

export default InfraEdit;