import React from 'react'
import Box from "@material-ui/core/Box";
import Typography from '@material-ui/core/Typography';
import { useTranslate } from 'react-admin';

const CenteredMessage = ({ message, children }) => {
    const translate = useTranslate();
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"    >
            <Typography variant="overline" display="inline" gutterBottom>
                {translate(message)}
            </Typography>
          
            {children}
        </Box>
    ) 
};

export default CenteredMessage;