import * as React from 'react';
import { useState, useEffect } from 'react';
import {
    Layout,
    useRefresh
} from 'react-admin';
import CustomMenu from './customMenu';
import CustomAppBar from './customAppBar';
import contextStore from '../provider/services/contextStore';
import authService from '../oidc/AuthorizeService';
import httpClient from '../provider/services/httpClient';
import {
    useDataProvider
} from 'react-admin';

const loadUser = () => {
    return httpClient('core/user/my').then(({ json }) => {
        contextStore.setUser(json);
        return json;
    });
}

const loadOrganization = () => {
    return httpClient('core/organization/my').then(({ json }) => {
        contextStore.setOrganization(json);
        return json;
    });
}

const loadBusinessAccount = (dataProvider) => {
    const wac = contextStore.getWac();
    var businessAccount = contextStore.getBusinessAccount();
    if (businessAccount?.wac === wac) return businessAccount;
    return dataProvider.getList('businessaccount', {})
        .then(({ data }) => {
            var businessaccount = data.find(b => b.wac === wac) ?? data.find(b => b.isProvisionned);
            contextStore.setBusinessAccount(businessaccount);
            return businessaccount;

        }).catch(() => {
            /* contextStore.setBusinessAccount(null);*/
            return null;
        });
}

const CustomLayout = props => {
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const [user, setUser] = useState();
    const [organization, setOrganization] = useState();
    const [businessAccount, setBusinessAccount] = useState(contextStore.getBusinessAccount());

    const setupUserContext = () => {
        return authService.isAuthenticated().then(isAuthenticated => {
            if (isAuthenticated) {
                return Promise.all([loadUser(), loadOrganization(), loadBusinessAccount(dataProvider)]).then(info => {
                    const user = info[0];
                    const organization = info[1];
                    const businessAccount = info[2];
                    setUser(user);
                    setOrganization(organization);
                    setBusinessAccount(businessAccount);
                })
            } else {
                contextStore.setUser(null);
                contextStore.setOrganization(null);
                setUser(null);
                setOrganization(organization);
            }
        })
    };

    const handleBusinessAccountSelected = (businessAccount) => {
        contextStore.setBusinessAccount(businessAccount);
        setBusinessAccount(businessAccount);
        refresh();
    }

    useEffect(() => {
        const _subscription = authService.subscribe(() => {
            return setupUserContext();
        });
        setupUserContext();
        return function cleanup() {
            authService.unsubscribe(_subscription);
        }
    }, []);

    const CustomMenuContext = () => {
        return (<CustomMenu businessaccount={businessAccount} />);
    }

    const CustomAppBarContext = (props) => {

        return (<CustomAppBar {...props}
            user={user}
            organization={organization}
            businessaccount={businessAccount}
            onSelectedBusinessAccount={handleBusinessAccountSelected} />);
    }

    return (<Layout {...props} menu={CustomMenuContext} appBar={CustomAppBarContext} />)
};

export default CustomLayout;