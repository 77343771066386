import ReflectList from './list';
import ReflectShow from './show';
import ReflectEdit from './edit';
import ReflectIcon from './icon';

export default {
    list: ReflectList,
    show: ReflectShow,
    edit: ReflectEdit,
    icon: ReflectIcon
};