import FormulaIcon from '@material-ui/icons/Functions';

const TypeEnum = {
    formula: { icon: FormulaIcon, name: 'hgw.activity.formula.label', path: 'formula' }
}

export const TypeList = Object.entries(TypeEnum).map(([id, obj]) => {
    obj.id = id;
    return obj
})

export default TypeEnum;