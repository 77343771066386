import * as React from "react";
import {
    TextField,
    BooleanField,
    ChipField,
    TabbedShowLayout,
    Tab,
    ReferenceArrayField,
    RichTextField,
    ShowController,
    ShowView,
    SingleFieldList
} from 'react-admin';
import title from './title';

const RoleTitle = title;

const RoleShow = props => (
    <ShowController {...props}>
        {controllerProps =>
            <ShowView title={<RoleTitle />} {...props} {...controllerProps}>
                <TabbedShowLayout>
                    <Tab label="Summary">
                        <TextField source="name" />
                        <RichTextField source="description" />
                        <BooleanField label="Role par D&#233;faut" source="isDefaultUser" />
                        <BooleanField label="Administrateur" source="isAdmin" />
                        <BooleanField label="Role par D&#233;faut" source="isDefault" />
                        <BooleanField label="Editable" source="isCustom" />
                    </Tab>
                    <Tab label="Operations">
                        <ReferenceArrayField label="Operations" reference="operation" source="operations">
                            <SingleFieldList>
                                <ChipField source="name" />
                            </SingleFieldList>
                        </ReferenceArrayField>
                    </Tab>
                </TabbedShowLayout>
            </ShowView>
        }
    </ShowController>
);

export default RoleShow;