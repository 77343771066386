const englishMessages = {
    hgw: {
        businessaccount: {
            allbusinessaccount: "See all my business accounts",
            select: "Not defined",
            label: "BusinessAccount",
            mybusinessaccount: "My business accounts",
            status: {
                provisionning: "Provisionning",
                enabled: "Enabled",
                deleting: "Archiving",
                deleted: "Archived"
            },
            offer: {
                label: "Offer"
            },
            org: {
                owner: "Propri�taire",
                operator: "Exploitant"
            }
        },
        period: {
            se: "second",
            mi: "minute",
            ho: "hour",
            da: "day",
            we: "week",
            mo: "month",
            ye: "year"
        },
        app: {
            appselector: "Application selector",
            allapps: "See all my Applications",
        },
        organization: {
            label: "Organization",
            contract: {
                exchangeperiod: "Exchange periodicity",
                fluxminperiod: "Min Flux sampling period",
                datamax: "Max number of datas"
            }
        },
        smartlocalunits: {
            label: "Smart Local Units"
        },
        address: {
            label: "Addresse",
            phone: "Phone",
            fax: "Fax",
            street: "Addresse",
            postalcode: "Postal code",
            city: "City"
        },
        metrics: "M�triques",
        summary: "G�n�ral",
        logoutsuccessfull: "You successfully logged out !",
        processlogin: "Processing login ...",
        processlogincallback: "Processing login callback ...",
        processlogout: "Processing logout ...",
        processlogoutcallback: "Processing logout ..."
    }
};

export default englishMessages;
