import httpClient from './services/httpClient';

const root = () => 'core/culture';

function getLocaleCultures() {
    var cultures = localStorage.getItem('cultures');
    return (!cultures ?
        httpClient(root()).then(({ json }) => {
            localStorage.setItem('cultures', JSON.stringify(json));
            return json;
        }) :
        Promise.resolve(JSON.parse(cultures)));
}

export default {

    map: (json) => { return json },

    getList: (params) => {
        return getLocaleCultures().then(
            (cultures) => {
                return {
                    data: cultures,
                    count: cultures.length
                }
            })
    },

    getOne: (params) => {
        return getLocaleCultures().then(cs => cs.find(c => params.id === c.id));
    },

    getMany: (params) => {
        return getLocaleCultures().then(cs => cs.filter(c => params.ids.indexOf(c.id) !== -1));
    }
};
