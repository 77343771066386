import FluxList from './list';
import FluxShow from './show';
import FluxCreate from './create';
import FluxEdit from './edit';
import FluxIcon from './icon';

export default {
    list: FluxList,
    show: FluxShow,
    create: FluxCreate,
    edit: FluxEdit,
    icon: FluxIcon
};