const RecordSourceResolver = ({ record, source }) => {

    return !source ? record :
        source.split('.').reduce(function (o, k) {
            return o && o[k];
        }, record);
};

RecordSourceResolver.defaultProps = { addLabel: true, size: 32 }

export default RecordSourceResolver;