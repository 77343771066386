import FluxItemList from './list';
import FluxItemShow from './show';
import FluxItemCreate from './create';
import FluxItemEdit from './edit';
import FluxItemIcon from './icon';

export default {
    list: FluxItemList,
    show: FluxItemShow,
    create: FluxItemCreate,
    edit: FluxItemEdit,
    icon: FluxItemIcon
};