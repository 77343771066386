import * as React from 'react';
import { useState, useEffect } from 'react';
import { Logout } from 'react-admin';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import authService from '../oidc/AuthorizeService';

const CustomLogoutButton = props => {
    const [isAuthenticated, setIsAuthenticatedUser] = useState(false);
    useEffect(() => {
        authService.isAuthenticated().then(isAuthenticated => {
            setIsAuthenticatedUser(isAuthenticated);
        })
    }, []);

    return isAuthenticated ? (<Logout disabled={!isAuthenticated} {...props} icon={<ExitToAppIcon />} />) : null;
};
//import React from 'react';
//import { connect } from 'react-redux';
//import { Responsive, userLogout } from 'react-admin';
//import MenuItem from '@material-ui/core/MenuItem';
//import Button from '@material-ui/core/Button';
//import ExitIcon from '@material-ui/icons/PowerSettingsNew';

//const CustomLogoutButton = ({ userLogout, ...rest }) => (
//    <Responsive
//        xsmall={
//            <MenuItem onClick={userLogout} >
//                <ExitIcon /> Logout
//            </MenuItem>
//        }
//        medium={
//            <Button onClick={userLogout} size="small" >
//                <ExitIcon /> Logout
//            </Button>
//        }
//    />
//);
//export default connect(undefined, { userLogout })(CustomLogoutButton);

export default CustomLogoutButton;