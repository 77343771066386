import * as React from 'react';
import { useTranslate } from 'react-admin';
import recordSourceResolver from './recordSourceResolver';

const ImagePictureField = ({ record, source, size }) => {
    const translate = useTranslate();
    const idPhoto = recordSourceResolver({ record, source });
    return !idPhoto ?
        <span>{translate('hgw.undefined')}</span> :
        <img src={`picture/${idPhoto}/${size}`} alt={source} />
};

ImagePictureField.defaultProps = { addLabel: true, size: 128 }

export default ImagePictureField;