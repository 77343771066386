import * as React from "react";
import {
    Edit,
    TextInput,
    BooleanInput,
    TabbedForm,
    FormTab,
    ReferenceArrayInput,
    SelectArrayInput
} from 'react-admin';
import title from './title';

const RoleTitle = title;

const RoleEdit = props => (
    <Edit title={<RoleTitle />} {...props}>
        <TabbedForm>
            <FormTab label="hgw.summary">
                <TextInput disabled source="id" />
                <TextInput source="name" />
                <TextInput multiline source="description" />
                <BooleanInput label="Role par D&#233;faut" source="isDefaultUser" />
            </FormTab>
            <FormTab label="Operations">
                <ReferenceArrayInput source="operations" reference="operation">
                    <SelectArrayInput optionText="name" />
                </ReferenceArrayInput>
            </FormTab>
        </TabbedForm>
    </Edit>
);
export default RoleEdit;