import * as React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import LevelEnums from '../enums/level';

const useStyles = makeStyles({
    avatar: {
        height: 24,
        width: 24,
    },
});

const ReferenceField = ({ record }) => {
    const classes = useStyles();
    return (
        <Chip icon={<Avatar className={classes.avatar} src={LevelEnums[record.origin].icon} />} label={record.name} key={record} />
    )
}

ReferenceField.defaultProps = { addLabel: true }

export default ReferenceField;