const CommissioningEnums = {
    deconnected: { id: 1, name: 'hgw.smartlocalunit.commissioning.deconnected' },
    connectWanted: { id: 2, name: 'hgw.smartlocalunit.commissioning.connectwanted' },
    connected: { id: 1, name: 'hgw.smartlocalunit.commissioning.connected' },
    connectDenied: { id: 2, name: 'hgw.smartlocalunit.commissioning.connectdenied' }
}

export const CommissioningList = Object.entries(CommissioningEnums).map(([id, obj]) => {
    obj.id = id;
    return obj
})

export default CommissioningEnums;