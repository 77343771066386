import * as React from "react";
import Chip from '@material-ui/core/Chip';
import DomainIcon from '@material-ui/icons/Domain';

const ReferenceField = ({ record }) => {
    return (
        <Chip icon={<DomainIcon />} label={record.label} key={record} />
    )
}

ReferenceField.defaultProps = { addLabel: true }

export default ReferenceField;