import httpClient from './services/httpClient';

const root = () => 'global/wum/type';

function getLocaleWumTypes() {
    var wumTypes = localStorage.getItem('wumtypes');
    return (!wumTypes ?
        httpClient(root()).then(({ json }) => {
            localStorage.setItem('wumtypes', JSON.stringify(json));
            return json;
        }) :
        Promise.resolve(JSON.parse(wumTypes)));
}

export default {

    map: (json) => { return json },

    getList: (params) => {
        return getLocaleWumTypes().then(items => {

            if (params.hasOwnProperty("search")) {
                items = items.filter(w => w.name.indexOf(params.search) > -1);
            }

            var paged = (params.hasOwnProperty("pageSize") && params.hasOwnProperty("pageIndex")) ?
                items.slice((params.pageIndex - 1) * params.pageSize, params.pageIndex * params.pageSize) : items;

            return {
                data: paged,
                count: items.length
            }
        })
    },

    getMany: (params) => {
        return getLocaleWumTypes().then(items => {
            return items.filter(u => params.ids.includes(u.id));
        })
    }
};
