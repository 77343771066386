import * as React from "react";
import {
    useTranslate,
} from 'react-admin';
import { Box, Typography } from '@material-ui/core';

const Aside = () => {
    const translate = useTranslate();
    return (
        <div style={{ width: 200, margin: '1em' }}>
            <Typography variant="h5">{translate('hgw.help')}</Typography>
            <Typography variant="overline" display="block" >
                {translate('hgw.activity.globalhelp')}
            </Typography>
            <Box textAlign="center" fontWeight="fontWeightBold">
                {translate('hgw.config')}
            </Box>
            <Typography variant="overline" display="block" >
                {translate('hgw.activity.formulahelp')}
            </Typography>
            <Typography variant="caption" display="block" >
                {translate('hgw.activity.formulasample')}
            </Typography>
        </div>
    )
};

export default Aside;