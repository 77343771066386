import * as React from "react";
import { Alert, AlertTitle } from '@material-ui/lab';
import {
    Button,
    DateField,
    ReferenceField,
    RichTextField,
    SelectField,
    ShowController,
    ShowView,
    Tab,
    TabbedShowLayout,
    TextField,
    TopToolbar,
    UrlField,
    useRedirect,
    useTranslate
} from 'react-admin';
import { StatusList } from './enums/status';
import title from './title';
import { TypeList } from './enums/type';
import ImagePictureField from '../crossCutting/fields/imagePictureField';
import contextStore from '../../provider/services/contextStore';
import IconJoin from '@material-ui/icons/SystemUpdateAlt';
import PeopleIcon from '@material-ui/icons/People';
import EditIcon from '@material-ui/icons/Edit';

const OrganizationShowActions = ({ data }) => {
    const redirect = useRedirect();
    return (
        <TopToolbar>
            {data && !data.isMemberOnGoing ?
                <Button
                    onClick={() => { redirect('/member'); }}
                    label="hgw.authorization.organization.many" >
                    <PeopleIcon />
                </Button> : null}
            {data && !data.isMemberOnGoing ?
                <Button
                    onClick={() => { redirect('/organization/edit'); }}
                    label="hgw.edit" >
                    <EditIcon />
                </Button> : null}
            <Button
                onClick={() => { redirect('/organization/define'); }}
                label="hgw.define" >
                <IconJoin />
            </Button>
        </TopToolbar>
    )
};

const OrganizationTitle = title;

const OrganizationShow = props => {
    const organization = contextStore.getOrganization();
    const id = organization?.id;
    const translate = useTranslate();
    return (
        <ShowController id={id} {...props}>
            {controllerProps =>
                <ShowView title={<OrganizationTitle />} actions={<OrganizationShowActions />} {...props} {...controllerProps} >
                    <TabbedShowLayout>
                        <Tab label="hgw.summary">
                            {controllerProps.record && controllerProps.record.isMemberOnGoing ?
                                < Alert variant="filled" severity="info">
                                    <AlertTitle>{translate('hgw.remark')}</AlertTitle>
                                    {translate('hgw.organization.notcertified')}
                                </Alert> : null}
                            <ImagePictureField label="hgw.logo" source="picture" />
                            <TextField label="hgw.label" source="name" />
                            <TextField label="hgw.organization.code" source="code" />
                            <RichTextField label="hgw.description" source="description" />
                            <SelectField label="hgw.status.label" source="status" choices={StatusList} />
                            {controllerProps.record && !controllerProps.record.isMemberOnGoing ?
                                <>
                                    <UrlField label="hgw.url" source="url" required={false} />
                                    <TextField label="hgw.organization.businesssector" source="businessSector" />
                                    <SelectField label="hgw.type" source="type" choices={TypeList} />
                                    <DateField label="hgw.creationdate" source="creationDate" />
                                    <DateField label="hgw.updatedate" source="updateDate" />
                                </> : null}
                        </Tab>

                        {controllerProps.record && !controllerProps.record.isMemberOnGoing ?
                            <Tab label="hgw.organization.legalcode">
                                <TextField label="hgw.organization.siret" source="siret" />
                                <TextField label="hgw.organization.vatin" source="vatin" />
                                <TextField label="hgw.organization.otherin" source="otherIn" />
                                <TextField label="hgw.organization.legalname" source="legalName" />
                            </Tab> : null}

                        {controllerProps.record && !controllerProps.record.isMemberOnGoing ?
                            <Tab label="hgw.address.label">
                                <TextField label="hgw.address.phone" source="address.phone" />
                                <TextField label="hgw.address.fax" source="address.fax" />
                                <TextField label="hgw.address.street" source="address.street" />
                                <TextField label="hgw.address.postalcode" source="address.postalCode" />
                                <TextField label="hgw.address.city" source="address.city" />
                                <ReferenceField label="hgw.country.label" source="address.countryId" reference="country">
                                    <TextField source="name" />
                                </ReferenceField>
                            </Tab> : null}


                    </TabbedShowLayout>
                </ShowView>
            }
        </ShowController>
    )
};

export default OrganizationShow;
