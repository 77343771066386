import * as React from "react";
import {
    ArrayInput,
    AutocompleteInput,
    BooleanInput,
    DeleteButton,
    Edit,
    DateInput,
    FormDataConsumer,
    FormTab,
    NumberInput,
    RadioButtonGroupInput,
    ReferenceField,
    ReferenceInput,
    SaveButton,
    SelectInput,
    SimpleFormIterator,
    TabbedForm,
    Toolbar,
    TextInput
} from 'react-admin';
import UserCardField from '../user/fields/cardField';
import KindEnums from './kindEnums';
import MediaTypeEnums from './mediaTypeEnums';
import CentralKindEnums from './centralKindEnums';
import DisableButton from './buttons/DisableButton';
import ValidateButton from '../crossCutting/buttons/ValidateButton';
import SmartLocalUnitReferenceField from '../smartlocalunit/fields/referenceField';
import title from './title';

const BadgeTitle = title;

const BadgeToolbar = (props) => {
    return (
        <Toolbar {...props}>
            <SaveButton />
            <ValidateButton />
            <DisableButton />
            <DeleteButton />
        </Toolbar>
    )
};

const BadgeEdit = props => (
    <Edit title={<BadgeTitle />} {...props}>
        <TabbedForm redirect="list" toolbar={<BadgeToolbar />}>
            <FormTab label="hgw.summary">
                <TextInput source="id" disabled />
                <TextInput label="hgw.badge.code" source="code" disabled />
                <ReferenceInput label="hgw.user.label" source="userId" reference="user" emptyText="hgw.badge.emptyuser" filter="friendly" allowEmpty>
                    <SelectInput optionText={<UserCardField />} />
                </ReferenceInput>
                <TextInput label="hgw.label" source="label" />
            </FormTab>
            <FormTab label="hgw.badge.constraint">
                <ReferenceInput label="hgw.badge.infra" source="infraId" reference="infra"
                    filterToQuery={searchText => ({ search: searchText })}
                    perPage={25}
                    emptyText="hgw.undefined"
                    allowEmpty disabled>
                    <AutocompleteInput optionText="name" optionValue="id" />
                </ReferenceInput>
                <ReferenceInput label="hgw.scope.label" source="scopeId" reference="scope" allowEmpty>
                    <SelectInput optionText="label" />
                </ReferenceInput>
                <DateInput label="hgw.badge.startdate" source="startDate" />
                <DateInput label="hgw.badge.enddate" source="endDate" />
                <NumberInput label="hgw.badge.uselimit" source="useLimit" step={100} />
            </FormTab>
            <FormTab label="hgw.badge.kind.many">
                <RadioButtonGroupInput label="hgw.badge.kind.label"
                    source="kind"
                    choices={KindEnums} />
                <RadioButtonGroupInput label="hgw.badge.mediatype.label"
                    source="mediaType"
                    choices={MediaTypeEnums} />
                <RadioButtonGroupInput label="hgw.badge.centralkind.label"
                    source="centralKind"
                    choices={CentralKindEnums} />
            </FormTab>
            <FormTab label="hgw.badge.usage.many">
                <BooleanInput label="hgw.badge.usage.masterduplicate" source="usage.masterDuplicate" />
                <BooleanInput label="hgw.badge.usage.masterkill" source="usage.masterKill" />
                <BooleanInput label="hgw.badge.usage.resourcecommand" source="usage.resourceCommand" />
                <BooleanInput label="hgw.badge.usage.access" source="usage.access" />
                <BooleanInput label="hgw.badge.usage.zonecommand" source="usage.zoneCommand" />
            </FormTab>
            <FormTab label="hgw.badge.keybox.label">
                <ArrayInput label="" source="box">
                    <SimpleFormIterator disableRemove disableAdd >
                        <FormDataConsumer>
                            {({ getSource, scopedFormData }) => {
                                getSource();
                                return (
                                    <ReferenceField label="hgw.smartlocalunit.label" source={'smartLocalUnitId'} record={scopedFormData} reference="smartlocalunit" link="show">
                                        <SmartLocalUnitReferenceField />
                                    </ReferenceField>
                                );
                            }}
                        </FormDataConsumer>
                        <BooleanInput label="hgw.badge.exclude" source="excluded" />
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
        </TabbedForm>
    </Edit>
);

export default BadgeEdit;