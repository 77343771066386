import * as React from 'react';
import { AppBar } from 'react-admin';
import CustomUserMenu from './customUserMenu';
import BusinessAccountButton from './businessAccountButton';
import { Toolbar, makeStyles } from '@material-ui/core';
import logo from '../logos/logo.png';
import AppSelector from './appSelector';

const useStyles = makeStyles(() => ({
    spacer: {
        flex: 1,
    },
    logo: {
        height: "45px",
        marginLeft: 0,
    },
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    businessaccount: {
        flex: 1,
        alignItems: 'right',
    },
    toolbarButtons: {
        marginLeft: 'auto',
        display: 'flex',
        gap: '40px',
        alignItems: 'center',
    },
}));

const CustomAppBar = ({ user, organization, businessaccount, onSelectedBusinessAccount, ...props }) => {
    const classes = useStyles();

    const handleSelected = (businessAccount) => {
        onSelectedBusinessAccount(businessAccount);
    }

    return (
        <AppBar {...props} userMenu={false} >
            <Toolbar>
                <img src={logo} alt="logo" className={classes.logo} />
            </Toolbar>
            <span className={classes.spacer} />
            <div className={classes.toolbarButtons}>
                <BusinessAccountButton businessaccount={businessaccount} onSelected={handleSelected} className={classes.businessaccount} />
                <AppSelector />
                <CustomUserMenu user={user} />
            </div>
        </AppBar>
    );
};

export default CustomAppBar;