import React from 'react';
import { IconButtonWithTooltip, useTranslate } from 'react-admin';
import { Menu  } from '@material-ui/core';
import { ReactComponent as AppGridSvgIcon} from '../logos/apps_grid_ico.svg'
import { useState } from 'react';
import AppSelect from '../resources/app/select';
import { AppType } from '../enums/appEnum';
import contextStore from '../provider/services/contextStore';

export default function AppSelector() {
  const translate = useTranslate();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [businessAccount] = useState(contextStore.getBusinessAccount());

  const handleClickOpen = (event) => {
    setAnchorEl(event.currentTarget)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleSelected = (app) => {
    setOpen(false);
    let url = app.website
    switch (app.type) {
      case AppType.SynApp:
        url += `/${businessAccount.wac}/runtime/${app.id}`;
        break;
      case AppType.WebApp:
        if (businessAccount && businessAccount.wac) {
          url += `/${businessAccount.wac}`;
        }
      default:
        break;
    }
    window.location.href = url;
  }

  return(
    <>
      <IconButtonWithTooltip onClick={handleClickOpen} label={translate('hgw.app.appselector')}>
        <AppGridSvgIcon />
      </IconButtonWithTooltip>

      <Menu
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <AppSelect onSelected={handleSelected} />
      </Menu>
    </>
  )
}