import * as React from "react";
import {
    Create,
    TextInput,
    BooleanInput,
    TabbedForm,
    FormTab,
    ReferenceArrayInput,
    SelectArrayInput,
} from 'react-admin';

const RoleCreate = props => (
    <Create {...props}>
        <TabbedForm redirect="list">
            <FormTab label="hgw.summary">
                <TextInput source="name" />
                <TextInput multiline source="description" />
                <BooleanInput label="Role par D&#233;faut" source="isDefaultUser" />
            </FormTab>
            <FormTab label="Operations">
                <ReferenceArrayInput source="operations" reference="operation">
                    <SelectArrayInput optionText="name" />
                </ReferenceArrayInput>
            </FormTab>
        </TabbedForm>
    </Create>
);

export default RoleCreate;