import * as React from "react";
import { useTranslate } from 'react-admin';
import TypeEnum from './enums/type';

const ActivityTitle = ({ record }) => {
    const translate = useTranslate();
    const Type = TypeEnum;
    const type = Type[record?.type ?? 'formula']
    const name = translate(type.name);
    return <span>{translate('hgw.activity.label')} {record ? name : ''}</span>;
};

export default ActivityTitle;