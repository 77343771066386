import * as React from "react";
import BusinessAccountLogo from './pictures/businessAccount64.png';
import { Avatar, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    avatar: {
        height: 32,
        width: 32,
    }
});

const BusinessAccountIcon = props => {
    const classes = useStyles();
    return (
        <Avatar src={BusinessAccountLogo} className={classes.avatar} />
    )
}

export default BusinessAccountIcon;