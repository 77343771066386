import contextStore from './contextStore';
import { ApplicationPaths } from '../../oidc/ApiAuthorizationConstants';

/* eslint-disable import/no-anonymous-default-export */
export default {

    // called when the user attempts to log in
    login: (identity) => {
        //contextStore.setIdentity(identity);
        //return loadUserInfo();
    },

    // called when the user clicks on the logout button
    logout: () => {
        contextStore.clearSession();
        //  const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };

        // react-admin logout do not allow distinction between user logout action and http error status !
        // when checkError function above is rejected react-admin call logout function ... but here
        // we do not want to logout but only allow relogin. 
        // hasCheckError act as a workaround to identify if this is a user logout action or logout is invoked
        // by react-admin after http status code 401 in checkError
        if (sessionStorage.getItem("hasCheckError")) {
            sessionStorage.removeItem("hasCheckError");
            return Promise.resolve();
        }
        return Promise.resolve(ApplicationPaths.LogOut);
    },

    // called when the API returns an error
    checkError: ({ status }) => {
        if (!status || status === 401) {
            //contextStore.clearSession();
            sessionStorage.setItem("hasCheckError", true);
            return Promise.reject({ message: false, redirectTo: ApplicationPaths.Login });
        }

        return Promise.resolve();
    },

    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        return Promise.resolve();
        //return authService.isAuthenticated().then(isAuthenticated => {
        //    return isAuthenticated ? Promise.resolve() : Promise.reject();
        //})

        //const identity = contextStore.getToken();
        //if (identity.accessToken && identity.expiration && new Date(new Date(identity.expiration) - 60000) > new Date()) {
        //    return !contextStore.getUser() ? loadUserInfo(): Promise.resolve();
        //}

        //const request = new Request('/spa/identity', {
        //    method: 'GET',
        //    headers: new Headers({ 'Content-Type': 'application/json' }),
        //});

        //return fetch(request)
        //    .then(response => {
        //        switch (response.status) {
        //            case 200:
        //                return response.json().then((identity) => {
        //                    contextStore.setIdentity(identity);
        //                    return loadUserInfo();
        //                })
        //            default:
        //                return Promise.reject();
        //        }
        //    });
    },

    // called when the user navigates to a new location, to check for permissions / roles
    //getPermissions: () => Promise.resolve(),
    getPermissions: () => {
        return Promise.resolve('guest') // default 'guest'
    },
};