import * as React from "react";
import { useState, useEffect } from 'react';
import {
    AutocompleteArrayInput,
    AutocompleteInput,
    BooleanInput,
    DeleteButton,
    Edit,
    FormDataConsumer,
    FormTab,
    ListButton,
    ReferenceArrayInput,
    ReferenceInput,
    SaveButton,
    SelectInput,
    ShowButton,
    TabbedForm,
    TextInput,
    Toolbar,
    TopToolbar,
    useDataProvider
} from 'react-admin';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import LockIcon from '@material-ui/icons/Lock';
import contextStore from '../../provider/services/contextStore';
import title from './title';
import AlarmBadge from "./fields/alarmBadge";
import ValidateButton from '../crossCutting/buttons/ValidateButton';
import { InputFieldFx } from '../extendedproperty/fields/fieldFx';
import { CriticityList } from "./enums/criticity";

const ReflectTitle = title;

const ReflectActions = ({ data, hasExtendedProperties, ...props }) => {
    return (
        <TopToolbar>
            <ShowButton record={data} {...props} />
            <ListButton
                icon={<NoteAddIcon />}
                label={hasExtendedProperties ? "hgw.extendedproperty.edit" : "hgw.extendedproperty.create"}
                basePath={hasExtendedProperties ? "/extendedproperty" : "/extendedproperty/create"}>
            </ListButton>
        </TopToolbar>
    )
};

const ReflectToolbar = (props) => {
    return (
        <Toolbar {...props}>
            <SaveButton />
            <ValidateButton />
            <DeleteButton />
        </Toolbar>
    )
};

const ReflectEdit = props => {
    const businessAccount = contextStore.getBusinessAccount();
    const [extendedProperties, setExtendedProperties] = useState();
    const [loading, setLoading] = useState(true);
    const dataProvider = useDataProvider();
    useEffect(() => {
        dataProvider.getList('extendedproperty', { filter: { resourceType: 'reflect' } })
            .then(({ data }) => {
                setExtendedProperties(data);
                setLoading(false);
            })
            .catch(error => {
                setExtendedProperties(null);
                setLoading(false);
            })
    }, []);
    if (loading) return null;
    return (
        <Edit title={<ReflectTitle />} actions={<ReflectActions hasExtendedProperties={extendedProperties?.length ?? 0 > 0} />} {...props}>
            <TabbedForm toolbar={<ReflectToolbar />} >
                <FormTab label="hgw.summary">
                    <TextInput disabled source="id" />
                    <TextInput label="hgw.extkey" source="extKey" />
                    <SelectInput label="hgw.reflect.criticity.override" source="criticityOverride" choices={CriticityList} optionText={<AlarmBadge />} emptyText="hgw.reflect.criticity.undefined" allowEmpty />
                </FormTab>
                <FormTab label="hgw.tagsinfras">
                    <BooleanInput label="hgw.infra.isoverride" options={{ checkedIcon: <LockIcon /> }} source="isInfraOverride" />
                    <FormDataConsumer>
                        {({ formData, ...rest }) => formData.isInfraOverride &&
                            <ReferenceInput label="hgw.infra.label" source="infraId" reference="infra" filterToQuery={searchText => ({ search: searchText })} perPage={25} {...rest}>
                                <AutocompleteInput optionText="name" optionValue="id" />
                            </ReferenceInput>
                        }
                    </FormDataConsumer>
                    <ReferenceArrayInput label="hgw.tag.many" source="tags" reference="tag" filterToQuery={searchText => ({ search: searchText })} perPage={25}>
                        <AutocompleteArrayInput optionText="label" optionValue="label" />
                    </ReferenceArrayInput>
                </FormTab>
                {!!extendedProperties && extendedProperties.length > 0 && !!businessAccount &&
                    <FormTab label={businessAccount.name}>
                        {extendedProperties.map(ep => InputFieldFx({ extendedProperty: ep }))}
                    </FormTab>
                }
            </TabbedForm>
        </Edit>
    )
};

export default ReflectEdit;