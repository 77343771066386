const PeriodEnums = {
    1: { period: "1se" },
    2: { period: "2se" },
    3: { period: "5se" },
    4: { period: "10se" },
    5: { period: "15se" },
    6: { period: "30se" },
    7: { period: "1mi" },
    8: { period: "2mi" },
    9: { period: "5mi" },
    10: { period: "10mi" },
    11: { period: "15mi" },
    12: { period: "30mi" },
    13: { period: "1ho" },
    14: { period: "2ho" },
    15: { period: "4ho" },
    16: { period: "6ho" },
    17: { period: "8ho" },
    18: { period: "12ho" },
    19: { period: "1da" },
    20: { period: "2da" },
    21: { period: "1we" },
}

export const PeriodList = Object.entries(PeriodEnums).map(([id, obj]) => {
    obj.id = id;
    return obj
})

export default PeriodEnums;