import React, { useState, useEffect, forwardRef } from 'react';
import {
    makeStyles,
    Grid,
    Typography,
    Divider,
} from '@material-ui/core';
import { useDataProvider, useTranslate } from 'react-admin';
import { ReactComponent as ArrowSvgIcon } from '../../logos/right-arrow.svg.svg'
import { useHubApplication } from '../../hooks/useHubApplication';
import contextStore from '../../provider/services/contextStore';


const useStyles = makeStyles((theme) => ({
    gridList: {
        width: '100vw',
        spacing: 10,
        justify: 'space-around'
    },
    gridContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        padding: '30px',
        maxWidth: '400px',
        margin: 'auto',
        color: '#172B4D',
    },
    appGrid: {
        gridRowGap: '40px',
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },
    img: {
        height: '90%',
        width: 'auto',
        justify: 'center'
    },
    appcontainer: {
        width: '116px',
        height: '70px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        gap: '8px',
        cursor: 'pointer',
    },
    rightarrow: {
        transform: 'rotate(-90deg)'
    },
    appRow: {
        display: 'flex',
        padding: '0',
        gap: '12px',
        cursor: 'pointer',
    },
}));

const AppSelect = forwardRef((props, ref) => {
    const classes = useStyles();
    const [apps, setApps] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const dataProvider = useDataProvider();
    const translate = useTranslate();
    const hubApplication = useHubApplication();
    const [businessAccount] = useState(contextStore.getBusinessAccount());

    const getApps = () => {
        dataProvider.getList('app', { pagination: { page: 1, perPage: 100 } })
            .then(({ data }) => {
                const app = data.filter((d) => d.id !== 1);
                setApps(app);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
    }

    const navigateHub = () => {
        const url = `${hubApplication.website}${businessAccount && businessAccount.wac ? `/app/${businessAccount.wac}` : ''}`
        window.location.href = url;
    }

    useEffect(() => {
        getApps();
        return () => {
            setApps(undefined);
            setLoading(false);
            setError({});
        };
    }, []);

    if (loading) return null;
    if (error) return null;
    if (!apps) return null;

    return (
        <div ref={ref} className={classes.gridContainer}>
            <Grid container spacing={3} className={classes.appGrid}>
                {apps.map(app => (
                    <Grid
                        item
                        onClick={() => props.onSelected(app)}
                        xs={4}
                        key={app.id}
                    >
                        <div className={classes.appcontainer}>
                            <img src={`/picture/${app.logo}/32`} alt='app-img' />
                            <Typography variant='caption' className={classes.name}>
                                {app.name}
                            </Typography>
                        </div>
                    </Grid>
                ))}
            </Grid>
            <Divider variant='middle' />
            <div className={classes.appRow} onClick={navigateHub}>
                <ArrowSvgIcon className={classes.rightarrow} />
                <Typography variant='subtitle2' className={classes.name}>
                    {translate('hgw.app.allapps')}
                </Typography>
            </div>
        </div>
    );
});

export default AppSelect;
