const frenchMessages = {
    hgw: {
        activity: {
            create: "Configurer une activité entrante",
            direction: {
                in: "Entrant",
                out: "Sortant"
            },
            globalhelp: "Configurer le type d'activité avec un nom simple puis sélectioner l'onglet paramétrage",
            formulahelp: "Défnissez une formule de calcul avec une correspondance avec  les clefs des sources.",
            formulasample: "Exemple: ([A]+[B])/2 nécessite de définir 2 sources avec les clefs A et B",
            label: "Activité",
            executioncount: "Nombre d'éxécutions",
            executiondate: "Dernière éxécution",
            in: "Activité entrante",
            out: "Activités sortantes",
            many: "Activités",
            metric: {
                label: "Métrique",
                delta: "Delta",
                min: "Minimum",
                max: "Maximum",
                in: "Entrant",
                out: "Sortant",
                average: "Moyenne",
                sum: "Somme",
                count: "Nombre"
            },
            type: {
                label: "Type activité"
            },
            formula: {
                key: "Clé",
                label: "Formule",
                sources: "Données de flux source",
                startdate: "Date de démarrage",
                targetfluxitem: "Donné de flux cible",
            },
            view: "Configuration activité entrante",
            undefined: "Aucune activité sortante"
        },
        address: {
            label: "Adresse",
            phone: "Téléphone",
            fax: "Fax",
            street: "Adresse",
            postalcode: "Code postal",
            city: "Ville"

        },
        app: {
            change: "Naviguer vers",
            appselector: "Sélecteur d'application",
            allapps: "Voir toutes mes Applications",
        },
        authorization: {
            isvalid: "Validé",
            tovalidate: "Valider",
            businessaccount: {
                label: "Autorisation",
                many: "Autorisations",
            },
            invalidated: "L'autorisation est dévalidée",
            organization: {
                label: "Membre",
                many: "Membres"
            },
            validated: "L'autorisation est validée",
            validateerror: "Erreur de validation de l'autorisation",
        },
        badge: {
            centralkind: {
                label: "Centrale",
                standard: "Standard",
                dungle: "Dungle",
                encoder: "Encodeur"
            },
            code: "Code",
            constraint: "Contraintes",
            deploy: {
                label: "Déploiement",
                deployed: "Déployé",
                toadd: "A ajouter",
                toupdate: "A modifier",
                toremove: "A supprimer"
            },
            error: {
                label: "Erreur de configuration",
                emptyinfra: "Infra non configurée sur l'ULI",
                outsideinfra: "Infra de l'ULI en dehors de la contrainte du Badge"
            },
            emptyuser: "Aucun utilisateur",
            enddate: "Date fin",
            exclude: "Exclure",
            excluded: "Exclu",
            infra: "Restriction sur Infra",
            infraempty: "Aucune restriction",
            invalidated: "Le badge est dévalidé",
            keybox: {
                label: "Boîtes à clefs",
                many: "Synthèse des déploiements dans les boîtes à clefs des ULIs du Scope",
            },
            kind: {
                label: "Type de badge",
                many: "Types",
                wiegand: "Wiegand",
                iso2: "ISO2",
                dallas: "Dallas"
            },
            label: "Badge",
            many: "Badges",
            mediatype: {
                label: "Type de support",
                keyboard: "Clavier"
            },
            startdate: "Date début",
            summary: "Déploiement",
            usage: {
                label: "Usage",
                many: "Usages",
                masterduplicate: "Dupplication maître",
                masterkill: "Suppression maître",
                resourcecommand: "Commande de ressource",
                access: "Accès",
                zonecommand: "Commande de zone"
            },
            usecount: "Nombre d'utilisations",
            uselimit: "Nombre max d'utilisations",
            validated: "Le badge est validé",
            validateerror: "Erreur de validation du badge",
        },
        businessaccount: {
            allbusinessaccount: "Voir toutes mes affaires",
            autovalidresources: "Validation manuel/automatique des ULIs",
            autovalidresourcesno: "Validation manuelle des ULIs",
            autovalidresourcesyes: "Validation automatique des ULIs",
            empty: "Vous n'avez pas encore d'affaires ...",
            create: 'Créer une nouvelle affaire',
            createsuccessfully: "L'affaire a été créée avec succes et est en en cours de provisionnenement ...",
            label: "Affaire",
            many: "Affaires",
            mybusinessaccount: "Mes affaires",
            wac: "Identifiant affaire WIT",
            join: "Rejoindre une affaire",
            joinsuccessfully: "Demande d'accès à l'affaire effectutée avec succes ... L'affaire sera visible des que votre autorisation sera validée par son administrateur",
            other: "Autre affaire",
            near: "Affaires de votre organization",
            select: "Sélectionner une affaire",
            selectorg: "Sélectionnez une affaire dans votre organisation",
            setwac: "Saisissez un WAC (Identifiant affaire WIT)",
            status: {
                provisionning: "Provisionnement en cours",
                deleting: "Archivage en cours",
                deleted: "Archive"
            },
            offer: {
                label: "Offre"
            }
        },
        country: {
            label: "Pays"
        },
        culture: {
            label: "Langue"
        },
        device: {
            lora: {
                many: "Lora",
                deveui: "DevEUI",
                commissioningMode: "Mode",
                lorawanClass: "Classe",
                model: "Model",
                manufacturer: "Fabricant"
            }
        },
        extendedproperty: {
            label: "Propriété étendue",
            many: "Propriétés étendues",
            nullable: "Nullable",
            create: "Ajouter une propriété étendue",
            edit: "Editer les propriétés étendues",
            list: {
                label: "Liste des valeurs",
                key: "Valeur",
                value: "Texte affiché"
            },
            resourcetype: "Type de resource",
            shared: "Global",
            valuetype: {
                label: "Type de valeur",
                bool: "Booléen",
                int: "Entier",
                decimal: "Décimal",
                string: "Texte"
            }
        },
        flux: {
            label: "Flux",
            origin: {
                label: "Origine",
                acquiring: "Acquisition",
                cloud: "Cloud"
            },
            many: "Flux",
            count: "Nombre de flux",
            size: "Taille des flux (MB)",
            parent: "Flux parent",
            invalidated: "Le flux est dévalidé",
            item: {
                code: "Label court (alpha numérique)",
                count: "Nombre de données",
                label: "Donnée de Flux",
                many: "Données",
                type: {
                    label: "Type de valeur",
                    boolean: "Booléen",
                    decimal: "Décimal",
                    float: "Flottant",
                    int: "Entier",
                    money: "Montant",
                    text: "Texte",
                    tinyint: "Petit entier",
                    datetime: "Date",
                }
            },
            point: {
                label: "Point",
                many: "Points",
                lastmany: "Derniers points",
                firstdate: "Date du premier point",
                lastdate: "Date du dernier point",
                lastvalue: "Dernière valeur",
                count: "Nombre de points"
            },
            validated: "Le flux est validé",
            validateerror: "Erreur de validation du flux",
        },
        infra: {
            building: "Bâtiment",
            heatedsurface: "Surface chauffée",
            isheatedsurfaceoverride: "Définir surface chauffée (sinon calculée avec enfants)",
            isoverride: "Verrouiller Infra (sinon identique à ULI)",
            issurfaceoverride: "Définir surface globale (sinon calculée avec enfants)",
            includechildren: "Inclure les enfants des Infras",
            notincludechildren: "Ne pas inclure les enfants des Infras",
            label: "Infra",
            many: "Infras",
            parent: "Infra parente",
            site: "Site",
            surface: "Surface globale",
            surfaces: "Surfaces et localisations",
            zone: "Zone",
            usage: {
                label: "Usage",
                many: "Usages"
            }
        },
        journal: {
            label: "Journal",
            count: "Nombre d'évènements",
            size: "Taille du journal (MB)",
        },
        location: {
            latitude: "Latitude",
            longitude: "Longitude"
        },
        notificationpolicy: {
            event: "Evènement",
            global: "Global",
            businessaccount: "Spécifique à cette affaire",
            sendmail: "Mail",
            isdefault: "Défaut",
            isoverride: "Forcé",
            many: "Notifications",
            label: "Notification",
            deleteglobal: "Suppression global",
            deletewac: "Suppression affaire",
            resetglobal: "Réinitialisation global",
            resetwac: "Réinitialisation affaire",
            globalhelp: "Définir la notification globalement pour toutes les affaires",
            businessaccounthelp: "Définir la notification spécifiquement pour cette affaire. Si non défini, la stratégie globale s'applique"
        },
        organization: {
            businesssector: "Domaine d'activité",
            code: "Code organisation",
            create: "Créer votre organisation",
            createsuccessfully: "Votre organisation a été créée avec succès. Vous en êtes désormais l'administrateur",
            define: "Définir votre organisation",
            definesuccessfully: "Demande d'accès en tant que membre de l'organisation effectutée avec succes ... ",
            label: "Organisation",
            legalcode: "Codes legaux",
            legalname: "Nom légal",
            many: "Organisations",
            notcertified: "Votre appartenance à l'organisation est en cours de validation ...",
            near: "Organisation proche",
            operator: "Organisation exploitante",
            other: "Autre organisation",
            otherin: "Autre référence",
            owner: "Organisation propriétaire",
            select: "Sélectionnez dans vos organisations proches",
            setcode: "Saisissez un code",
            siret: "Siret",
            status: {
                tovalidate: "En cours de validation",
                validated: "Validée",
                deleted: "Supprimée"
            },
            type: {
                enterprise: "Entreprise",
                collectivity: "Collectivitée",
                association: "Association"
            },
            vatin: "Vatin",
            your: "Votre organisation"
        },
        period: {
            label: "Période",
            undefined: "Autre période",
            se: "seconde(s)",
            mi: "minute(s)",
            ho: "heure(s)",
            da: "jour(s)",
            we: "semaine(s)",
            mo: "mois",
            ye: "année(s)"
        },
        reflect: {
            alarm: "Alarme",
            class: {
                label: "Classe",
                many: "Classes",
            },
            criticity: {
                override: "Spécification criticité",
                undefined: "Criticité du journal",
                veryhigh: "Très critique",
                high: "Critique",
                normal: "Moyennement critique",
                low: "Peu critique",
                verylow: "Très peu critique",
                none: "Aucune criticité",
            },
            invalidated: "Le reflet est dévalidé",
            state: {
                count: "Nombre d'états"
            },
            label: "Reflet",
            localId: "ULI local Id (nod)",
            many: "Reflets",
            count: "Nombre de reflets",
            path: "Chemin de la ressource",
            size: "Taille des reflets (MB)",
            validated: "Le reflet est validé",
            validateerror: "Erreur de validation du reflet",
        },
        role: {
            label: "Role",
            many: "Roles"
        },
        scope: {
            label: "Scope",
            many: "Scopes",
            criticitydiffusion: "Notification mail du journal",
            criticitydiffusionempty: "Ne pas notifier",
            query: "Requête"
        },
        smartlocalunit: {
            alarms: "Alarmes",
            autovalidresources: "Validation manuel/automatique des Flux et Reflets de l'ULI",
            autovalidresourcesno: "Validation manuelle des Flux et Reflets de l'ULI",
            autovalidresourcesyes: "Validation automatique des Flux et Reflets de l'ULI",
            contract: {
                label: "Contrat",
                exchangeperiod: "Périodicité des échanges",
                heartBeatPeriod: "Périodicité du rendez-vous",
                fluxminperiod: "Période minimale d'échantillonnage des Flux",
                datamax: "Nombre de canaux max",
                dailyEventMax: "Nombre d'événements journalier max par reflet",
                endDate: "Date de fin de contrat"
            },
            exchangedate: "Dernier échange",
            hard: "Matériel",
            invalidated: "L'ULI est dévalidée",
            label: "ULI",
            late: "Retard",
            maintenance: "Maintenance",
            many: "ULIs",
            lateperiod: "Période avant retard",
            latesince: "En retard depuis",
            nextcall: "Prochain appel",
            networkmedia: {
                label: "Média",
                lan: "Lan",
                gprs: "GPRS",
                rtc: "RTC",
                g3: "3G",
                g4: "4G"
            },
            commissioning: {
                label: "Mise en service",
                deconnected: "Déconnecté",
                connectwanted: "Connection demandée",
                connected: "Connecté",
                connectdenied: "Connection refusée"
            },
            softhard: "Matériel et logiciel",
            softname: "Logiciel",
            softversion: "Version",
            softdate: "Date",
            substitutionwid: "Wid de substitution (SAV)",
            validated: "L'ULI est validée",
            validateerror: "Erreur de validation de l'ULI",
            wid: "Wid (identifiant)",
        },
        tag: {
            label: "Tag",
            many: "Tags",
            isglobal: "Global",
            isglobaldetail: "Global (Tag synchro avec ULIs)"
        },
        user: {
            label: "Utilisateur",
            profile: "Profile",
            many: "Utilisateurs",
            mail: "Mail",
            phone: "Téléphone",
            firstname: "Prénom",
            lastname: "Nom",
            photo: "Photo",
            photocurrent: "Actuelle",
            photonew: "Uploader nouvelle"
        },
        wum: {
            label: "Unités",
            unit: "Unité",
            function: "Fonction",
            type: "Type",
            material: "Matériel"
        },
        status: {
            label: "Status",
            disabled: "Désactivé",
            tovalidate: "A valider",
            validated: "Validé",
            locked: "Verrouillé",
            offline: "Hors-ligne",
            customtokill: "Suppression demandée",
            admintokill: "Suppression demandée",
            killed: "Supprimé"
        },
        about: "A propos",
        code: "Code",
        communication: "Communication",
        config: "Paramétrage",
        creationdate: "Date de création",
        date: "Date",
        define: "Définir",
        disable: "Désactiver",
        description: "Description",
        edit: "Editer",
        enabled: "Actif",
        extkey: "Votre référence",
        help: "Aide",
        invalidate: "Dévalider",
        label: "Libellé",
        logo: "Logo",
        join: "Rejoindre",
        metrics: "Métriques",
        other: "Autre",
        picturecurrent: "Actuel",
        picturenew: "Uploader nouveau",
        or: "OU",
        remark: "Remarque",
        tagsinfras: "Tags et localisation",
        search: "Recherche",
        show: "Afficher",
        summary: "Général",
        type: "Type",
        undefined: "-",
        unknown: "Inconnu",
        updatedate: "Date de modification",
        url: "Url",
        validate: "Valider",
        youcan: "Vous pouvez",
        logoutsuccessfull: "Vous avez été déconnecté avec succès !",
        login: "Se connecter",
        processlogin: "Connexion en cours ...",
        processlogincallback: "Finalisation de connexion",
        processlogout: "Déconnexion en cours ...",
        processlogoutcallback: "Finalisation de déconnexion",
    }
};

export default frenchMessages;
