import * as React from "react";
import {
    NumberField,
    ReferenceField,
    RichTextField,
    Show,
    Tab,
    TabbedShowLayout,
    TextField
} from 'react-admin';
import TypeField from './fields/type';
import InfraReferenceField from '../infra/fields/reference';
import InfraUsageReferenceField from './usage/fields/reference';
import ImagePictureField from '../crossCutting/fields/imagePictureField';
import title from './title';

const InfraTitle = title;

export const FluxShow = props => (
    <Show title={<InfraTitle />} {...props} >
        <TabbedShowLayout>
            <Tab label="hgw.summary">
                <TypeField label="" source="name" />
                <ReferenceField label="hgw.infra.usage.label" source="usageId" reference="infrausage">
                    <InfraUsageReferenceField />
                </ReferenceField>
                <TextField label="hgw.label" source="name" />
                <RichTextField label="hgw.description" source="description" />
            </Tab>
            <Tab label="hgw.infra.surfaces">
                <ReferenceField label="hgw.infra.parent" source="parentId" reference="infra">
                    <InfraReferenceField />
                </ReferenceField>
                <NumberField label="hgw.infra.surface" source="surface" />
                <NumberField label="hgw.infra.heatedsurface" source="heatedSurface" />
                <NumberField label="hgw.location.latitude" source="location.latitude" />
                <NumberField label="hgw.location.longitude" source="location.longitude" />
            </Tab>
            <Tab label="hgw.logo">
                <ImagePictureField label="hgw.picturecurrent" source="picture" />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default FluxShow;
