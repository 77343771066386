import * as React from "react";
import SmartLocalUnitLogo from './pictures/smartLocalUnit32.png';
import { Avatar, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    avatar: {
        height: 32,
        width: 32,
    }
});

export const SmartLocalUniIcon = props => {
    const classes = useStyles();
    return (
        < Avatar src={SmartLocalUnitLogo} className={classes.avatar} />
    )
}

export default SmartLocalUniIcon;