import * as React from "react";
import { useMediaQuery } from '@material-ui/core';
import {
    Datagrid,
    Filter,
    List,
    NumberField,
    SimpleList,
} from 'react-admin';
import MomentField from '../crossCutting/fields/momentField';
import ActivityTypeField from './fields/type';

const ActivityFilter = (props) => (
    <Filter {...props}>

    </Filter>
);

export const ActivityList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List title="hgw.flux.item.many" filters={<ActivityFilter />} {...props}>
            {isSmall ?
                (<SimpleList
                    linkType="show"
                    primaryText={record => record.name}
                    secondaryText={record => record.description ?? '-'}
                />) :
                (<Datagrid rowClick="show">
                    <ActivityTypeField label="hgw.activity.type.label" source="type" addLabel={true} />
                    <MomentField label="hgw.activity.executiondate" source="executionDate" sortable={true} />
                    <NumberField label="hgw.activity.executioncount" source="executionCount" sortable={true} />
                </Datagrid>)}
        </List>
    )
};

export default ActivityList;
