import * as React from "react";
import LevelEnum from '../enums/level';
import { useTranslate } from 'react-admin';
import { Tooltip } from '@material-ui/core';

const Level = LevelEnum;

const FluxLevelField = ({ record, source, addLabel }) => {
    const translate = useTranslate();
    const value = record[source ?? 'id'];
    const level = Level[value];
    const name = translate(level.name);
    return addLabel ?
        <>
            <img src={level.icon} alt={name} />
            <span>&nbsp;{name}</span>
        </> :
        <Tooltip title={name} arrow placement="right" >
            <img src={level.icon} alt={name} />
        </Tooltip>
}

FluxLevelField.defaultProps = { addLabel: true }

export default FluxLevelField;