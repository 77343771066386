import ActivityList from './list';
import ActivityShow from './show';
import FluxItemCreate from './create';
import FluxItemEdit from './edit';
import FluxItemIcon from './icon';

export default {
    list: ActivityList,
    show: ActivityShow,
    create: FluxItemCreate,
    edit: FluxItemEdit,
    icon: FluxItemIcon
};