import * as React from "react";
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'react-admin';
import recordSourceResolver from '../../crossCutting/fields/recordSourceResolver';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const useStyles = makeStyles({
    toValidate: { backgroundColor: "orange" },
    validated: {}
});

const ValidateField = ({ record, source }) => {
    const translate = useTranslate();
    const classes = useStyles();
    const validate = recordSourceResolver({ record, source });
    if (validate === undefined) return <div />;
    const className = classes[validate ? 'validated' : 'toValidate'];
    return (
        <Chip icon={validate ? <CheckCircleOutlineIcon /> : <ErrorOutlineIcon />} className={className} color="primary" label={translate(validate ? "hgw.status.validated" : "hgw.status.tovalidate")} size="small" />
    )
}

ValidateField.defaultProps = { addLabel: true }

export default ValidateField;