import * as React from "react";
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'react-admin';
import BadgeStatusEnums from '../statusEnums';

const useStyles = makeStyles({
    status0: { backgroundColor: "yellow" },
    status1: { backgroundColor: "orange" },
    status2: {},
    status9: { backgroundColor: "red" }
});

const BadgeStatusField = ({ record }) => {
    const translate = useTranslate();
    const classes = useStyles();
    const id = record?.status;
    const status = id === undefined ? record : BadgeStatusEnums.find(s => s.id === id);
    if (!status) return <div />;
    const className = classes[`status${status?.id ?? 0}`];
    return (
        <Chip icon={<status.icon />} className={className} color="primary" label={translate(status.name)} key={status} size="small" />
    )
}

BadgeStatusField.defaultProps = { addLabel: true }

export default BadgeStatusField;