import * as React from "react";
import {
    BooleanField,
    BooleanInput,
    DateField,
    DateInput,
    NumberField,
    NumberInput,
    SelectField,
    SelectInput,
    TextField,
    TextInput
} from 'react-admin';

const getPath = (extendedProperty) => {
    return `extendedProperties.${extendedProperty.label}`;
}

const ValueFieldFx = ({ extendedProperty }) => {
    const extendedPropertyPath = getPath(extendedProperty);
    if (extendedProperty.list?.length > 0) {
        return (<SelectField label={extendedProperty.description} source={extendedPropertyPath} choices={extendedProperty.list} optionText="value" optionValue="key" />)
    }
    switch (extendedProperty.valueType) {
        case "bool":
            return (<BooleanField label={extendedProperty.description} source={extendedPropertyPath} />);
        case "int":
            return (<NumberField label={extendedProperty.description} source={extendedPropertyPath} options={{ maximumFractionDigits: 0 }} />);
        case "decimal":
            return (<NumberField label={extendedProperty.description} source={extendedPropertyPath} />);
        case "date":
            return (<DateField label={extendedProperty.description} source={extendedPropertyPath} />);
        case "string":
        default:
            return (<TextField label={extendedProperty.description} source={extendedPropertyPath} />);
    }
}

const InputFieldFx = ({ extendedProperty }) => {
    const extendedPropertyPath = getPath(extendedProperty);
    if (extendedProperty.list?.length > 0) {
        return extendedProperty.nullable ?
            (<SelectInput label={extendedProperty.description} source={extendedPropertyPath} choices={extendedProperty.list} optionText="value" optionValue="key" allowEmpty />) :
            (<SelectInput label={extendedProperty.description} source={extendedPropertyPath} choices={extendedProperty.list} optionText="value" optionValue="key" />)
    }

    switch (extendedProperty.valueType) {
        case "bool":
            return (<BooleanInput label={extendedProperty.description} source={extendedPropertyPath} />);
        case "int":
            return (<NumberInput label={extendedProperty.description} source={extendedPropertyPath} options={{ maximumFractionDigits: 0 }} />);
        case "decimal":
            return (<NumberInput label={extendedProperty.description} source={extendedPropertyPath} />);
        case "date":
            return (<DateInput label={extendedProperty.description} source={extendedPropertyPath} />);
        case "string":
        default:
            return (<TextInput label={extendedProperty.description} source={extendedPropertyPath} />);
    }
}

export { ValueFieldFx, InputFieldFx };