import httpClient from './services/httpClient';
import contextStore from './services/contextStore';
import requestUtils from './factory/requestUtils';

const root = () => {
    return "core/user";
};

function getCacheKey() {
    return 'user_friendly';
}

function getCached() {
    const cacheKey = getCacheKey();
    var users = localStorage.getItem(cacheKey);
    return !users ?
        httpClient(`${root()}/friendly`).then(({ json }) => {
            localStorage.setItem(cacheKey, JSON.stringify(json));
            return json;
        }) :
        Promise.resolve(JSON.parse(users));
}

function clearCached() {
    const cacheKey = getCacheKey();
    localStorage.removeItem(cacheKey);
}

export default {

    map: (json) => {
        json.toValidate = !json.validated;
        if (!json.lastname) {
            json.name = json.mail;
        } else if (!json.firstname) {
            json.name = json.lastname;
        }
        else {
            json.name = `${json.firstname} ${json.lastname}`;
        }

        return json;
    },

    getList: (params) => {
        clearCached();
        return getCached().then(
            (users) => {
                return {
                    data: users,
                    count: users.length
                }
            })
    },

    getOne: (params) => {
        return contextStore.getUser()?.id === params.id ?
            httpClient(`${root()}/my`).then(({ json }) => json) :
            getCached().then((users) => users.find(t => params.id === t.id))
    },

    getMany: (params) => {
        return getCached().then(
            (users) => {
                return users.filter(t => params.ids.indexOf(t.id) !== -1);
            })
    },

    update: (params) => {
        clearCached();
        const puts = [];
        var dataNaming = requestUtils.getDatas(params, ['id', 'firstname', 'lastname', 'phone', 'culture']);
        if (dataNaming) {
            puts.push({
                url: root(),
                method: 'PUT',
                data: JSON.stringify(dataNaming)
            });
        }

        if (!!params.data.photoraw) {
            let formData = new FormData();
            formData.append('photo', params.data.photoraw.rawFile);
            puts.push({
                url: `${root()}/photo`,
                method: 'POST',
                data: formData
            });
        }

        return requestUtils.sendSynchrone(puts);
    }
};
