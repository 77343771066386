import * as React from "react";
import { useMediaQuery } from '@material-ui/core';
import {
    ArrayField,
    ChipField,
    Datagrid,
    DateField,
    Filter,
    List,
    ReferenceField,
    SelectField,
    SelectInput,
    SimpleList,
    SingleFieldList,
    TextInput
} from 'react-admin';
import LabelField from './fields/label';
import BadgeIcon from './icon';
import ScopeReferenceField from '../scope/fields/referenceField';
import BadgeStatusField from './fields/statusField';
import KindEnums from './kindEnums';
import MediaTypeEnums from './mediaTypeEnums';
import CentralKindEnums from './centralKindEnums';
import DeployField from "./fields/deployField";
import StatusEnums from "./statusEnums";

const BadgeFilter = (props) => (
    <Filter {...props}>
        <TextInput label="hgw.search" source="search" alwaysOn />
        <SelectInput label="hgw.status.label" source="status" choices={StatusEnums} optionText={<BadgeStatusField />} emptyText="hgw.undefined" allowEmpty alwaysOn />
        <SelectInput label="hgw.badge.kind.label" source="kind" choices={KindEnums} emptyText="hgw.undefined" />
        <SelectInput label="hgw.badge.mediatype.label" source="mediaType" choices={MediaTypeEnums} emptyText="hgw.undefined" />
        <SelectInput label="hgw.badge.centralkind.label" source="centralKind" choices={CentralKindEnums} emptyText="hgw.undefined" />
    </Filter>
);

const BadgeList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List filters={<BadgeFilter />} {...props}>
            {isSmall ?
                (<SimpleList
                    linkType="show"
                    leftIcon={record => <BadgeIcon />}
                    primaryText={record => record.label}
                    secondaryText={record => record.code}
                />) :
                (<Datagrid rowClick="show">
                    <LabelField label="hgw.label" source="label" />
                    <ChipField label="hgw.badge.code" source="code" />
                    <ReferenceField label="hgw.scope.label" source="scopeId" reference="scope" link="show">
                        <ScopeReferenceField />
                    </ReferenceField>
                    <ArrayField label="hgw.badge.deploy.label" source="summary" >
                        <SingleFieldList>
                            <DeployField addLabel={false} />
                        </SingleFieldList>
                    </ArrayField>
                    <SelectField label="hgw.badge.kind.label" source="kind" choices={KindEnums} />
                    <SelectField label="hgw.badge.mediatype.label" source="mediaType" choices={MediaTypeEnums} />
                    <SelectField label="hgw.badge.centralkind.label" source="centralKind" choices={CentralKindEnums} />
                    <DateField label="hgw.badge.startdate" source="startDate" />
                    <DateField label="hgw.badge.enddate" source="endDate" />
                    <BadgeStatusField label="hgw.status.label" source="status" />
                </Datagrid>)}
        </List>
    )
};

export default BadgeList;