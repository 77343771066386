import RoleList from './list';
import RoleShow from './show';
import RoleCreate from './create';
import RoleEdit from './edit';
import RoleIcon from './icon';

export default {
    list: RoleList,
    show: RoleShow,
    create: RoleCreate,
    edit: RoleEdit,
    icon: RoleIcon
};