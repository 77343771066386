const StatusEnums = {
    tovalidate: { id: 0, name: 'hgw.organization.status.tovalidate' },
    validated: { id: 1, name: 'hgw.organization.status.validated' },
    deleted: { id: 9, name: 'hgw.organization.status.deleted' }
}

export const StatusList = Object.entries(StatusEnums).map(([id, obj]) => {
    obj.id = id;
    return obj
})

export default StatusEnums;