import * as React from "react";
import {
    AutocompleteArrayInput,
    BooleanInput,
    Edit,
    FormDataConsumer,
    ReferenceArrayInput,
    SelectInput,
    SimpleForm,
    TextInput
} from 'react-admin';
import title from './title';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import AlarmBadge from '../reflect/fields/alarmBadge';
import { CriticityList } from '../reflect/enums/criticity';

const ScopeTitle = title;

export const ScopeEdit = props => (
    <Edit title={<ScopeTitle />} {...props}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <TextInput label="hgw.label" source="label" />
            <ReferenceArrayInput label="hgw.tag.many" source="query.tags" reference="tag" filterToQuery={searchText => ({ search: searchText })} perPage={25}>
                <AutocompleteArrayInput optionText="label" optionValue="label" />
            </ReferenceArrayInput>
            <ReferenceArrayInput label="hgw.infra.many" source="query.infraIds" reference="infra" filterToQuery={searchText => ({ search: searchText })} perPage={25}>
                <AutocompleteArrayInput optionText="name" optionValue="id" />
            </ReferenceArrayInput>
            <FormDataConsumer>
                {({ formData, ...rest }) => formData.query.infraIds?.length > 0 &&
                    <BooleanInput label="hgw.infra.includechildren" source="query.includeInfraChildren" options={{ checkedIcon: <AccountTreeIcon /> }} {...rest} />
                }
            </FormDataConsumer>
            <SelectInput label="hgw.scope.criticitydiffusion" source="criticityDiffusion" choices={CriticityList} optionText={<AlarmBadge />} emptyText="hgw.scope.criticitydiffusionempty" allowEmpty />
        </SimpleForm>
    </Edit>
);

export default ScopeEdit;