import * as React from "react";
import {
    ArrayField,
    BooleanField,
    ChipField,
    Datagrid,
    DateField,
    NumberField,
    ReferenceField,
    SelectField,
    Show,
    Tab,
    TabbedShowLayout,
    TextField,
    useTranslate
} from 'react-admin';
import title from './title';
import ScopeReferenceField from '../scope/fields/referenceField';
import InfraReferenceField from '../infra/fields/reference';
import KindEnums from './kindEnums';
import MediaTypeEnums from './mediaTypeEnums';
import CentralKindEnums from './centralKindEnums';
import DeployField from "./fields/deployField";
import ErrorField from "./fields/errorField";
import UserCardField from '../user/fields/cardField';
import BadgeStatusField from './fields/statusField';
import SmartLocalUnitReferenceField from '../smartlocalunit/fields/referenceField';
import DeployEnums from "./deployEnums";
import ErrorEnums from "./errorEnums";

const BadgeTitle = title;

const BusinessAccountShow = props => {
    var translate = useTranslate();
    return (
        <Show title={<BadgeTitle />}{...props} >
            <TabbedShowLayout>
                <Tab label="hgw.summary">
                    <ChipField label="hgw.badge.code" source="code" />
                    <TextField label="hgw.label" source="label" />
                    <ReferenceField label="hgw.user.label" source="userId" reference="user" emptyText={translate("hgw.badge.emptyuser")} link="show">
                        <UserCardField />
                    </ReferenceField>
                    <DateField label="hgw.creationdate" source="creationDate" />
                    <DateField label="hgw.updatedate" source="updateDate" />
                    {/* <UserCardField label="hgw.user.label" source="userId" />*/}
                    <BadgeStatusField label="hgw.status.label" source="status" />
                </Tab>
                <Tab label="hgw.badge.constraint">
                    <ReferenceField label="hgw.badge.infra" source="infraId" reference="infra" emptyText={translate("hgw.badge.infraempty")} link="show">
                        <InfraReferenceField />
                    </ReferenceField>
                    <ReferenceField label="hgw.scope.label" source="scopeId" reference="scope" link="show">
                        <ScopeReferenceField />
                    </ReferenceField>
                    <DateField label="hgw.badge.startdate" source="startDate" />
                    <DateField label="hgw.badge.enddate" source="endDate" />
                    <NumberField label="hgw.badge.usecount" source="useCount" />
                    <NumberField label="hgw.badge.uselimit" source="useLimit" />
                </Tab>
                <Tab label="hgw.badge.kind.many">
                    <SelectField label="hgw.badge.kind.label" source="kind" choices={KindEnums} />
                    <SelectField label="hgw.badge.mediatype.label" source="mediaType" choices={MediaTypeEnums} />
                    <SelectField label="hgw.badge.centralkind.label" source="centralKind" choices={CentralKindEnums} />
                </Tab>
                <Tab label="hgw.badge.usage.many">
                    <BooleanField label="hgw.badge.usage.masterduplicate" source="usage.masterDuplicate" />
                    <BooleanField label="hgw.badge.usage.masterkill" source="usage.masterKill" />
                    <BooleanField label="hgw.badge.usage.resourcecommand" source="usage.resourceCommand" />
                    <BooleanField label="hgw.badge.usage.access" source="usage.access" />
                    <BooleanField label="hgw.badge.usage.zonecommand" source="usage.zoneCommand" />
                </Tab>
                <Tab label="hgw.badge.keybox.label">
                    <ArrayField label="hgw.badge.keybox.many" source="box" fieldKey="smartLocalUnitId">
                        <Datagrid>
                            <ReferenceField label="hgw.smartlocalunit.label" source="smartLocalUnitId" reference="smartlocalunit" link="show">
                                <SmartLocalUnitReferenceField />
                            </ReferenceField>
                            <BooleanField label="hgw.badge.excluded" source="excluded" />
                            <SelectField label="hgw.badge.deploy.label" source="deploy" choices={DeployEnums} optionText={<DeployField />} />
                            <SelectField label="hgw.badge.error.label" source="error" optionText={<ErrorField />} choices={ErrorEnums} />
                        </Datagrid>
                    </ArrayField>
                </Tab>
            </TabbedShowLayout>
        </Show>
    )
};

export default BusinessAccountShow;