import * as React from "react";
import { useState, useEffect } from 'react';
import {
    ChipField,
    Datagrid,
    DateField,
    NumberField,
    ReferenceField,
    ReferenceManyField,
    RichTextField,
    Show,
    Tab,
    TabbedShowLayout,
    TextField,
    useDataProvider
} from 'react-admin';
import FluxLevelField from './fields/level';
import FluxItemLevelField from '../fluxitem/fields/level';
import TagArrayField from '../tag/fields/arrayField';
import MomentField from '../crossCutting/fields/momentField';
import ResourceStatusField from '../crossCutting/fields/resourceStatusField';
import PeriodField from '../crossCutting/fields/periodField';
import contextStore from '../../provider/services/contextStore';
import { ValueFieldFx } from '../extendedproperty/fields/fieldFx';
import InfraReferenceField from '../infra/fields/reference';
import FluxReferenceField from './fields/reference';
import SmartLocalUnitReferenceField from '../smartlocalunit/fields/referenceField';
import title from './title';

const FluxTitle = title;

export const FluxShow = props => {
    const businessAccount = contextStore.getBusinessAccount();
    const [extendedProperties, setExtendedProperties] = useState();
    const [loading, setLoading] = useState(true);
    const dataProvider = useDataProvider();
    useEffect(() => {
        dataProvider.getList('extendedproperty', { filter: { resourceType: 'flux' } })
            .then(({ data }) => {
                setExtendedProperties(data);
                setLoading(false);
            })
            .catch(error => {
                setExtendedProperties(null);
                setLoading(false);
            })
    }, []);
    if (loading) return null;
    return (
        <Show title={<FluxTitle />} {...props} >
            <TabbedShowLayout>
                <Tab label="hgw.summary">
                    <FluxLevelField label="hgw.flux.origin.label" source="origin" />
                    <TextField label="hgw.extkey" source="extKey" />
                    <TextField label="hgw.label" source="name" />
                    <RichTextField label="hgw.description" source="description" />
                    <TextField source="wci" />
                    <ReferenceField label="hgw.flux.parent" source="parentFluxId" reference="flux" link="show">
                        <FluxReferenceField />
                    </ReferenceField>
                    <ResourceStatusField label="hgw.status.label" source="status" />
                </Tab>
                <Tab label="hgw.tagsinfras">
                    <ReferenceField label="hgw.infra.label" source="infraId" reference="infra">
                        <InfraReferenceField />
                    </ReferenceField>
                    <TagArrayField label="hgw.tag.many" source="tags" />
                </Tab>
                <Tab label="hgw.communication">
                    <MomentField source="exchangeDate" />
                    <ReferenceField label="hgw.smartlocalunit.label" source="smartLocalUnitId" reference="smartlocalunit" link="show">
                        <SmartLocalUnitReferenceField />
                    </ReferenceField>
                    <DateField source="creationDate" />
                    <DateField source="updateDate" />
                </Tab>
                <Tab label="hgw.flux.item.many">
                    <ReferenceManyField label="hgw.flux.item.many" reference="fluxitem" target="fluxIds">
                        <Datagrid rowClick="show">
                            <FluxItemLevelField source="origin" addLabel={false} />
                            <TextField source="name" />
                            <TextField source="label" />
                            <TextField source="description" />
                            <ReferenceField label="hgw.wum.unit" source="wum.unit" reference="wumunit">
                                <ChipField source="name" />
                            </ReferenceField>
                            <ReferenceField label="hgw.wum.type" source="wum.type" reference="wumtype">
                                <ChipField source="name" />
                            </ReferenceField>
                            <ReferenceField label="hgw.wum.function" source="wum.function" reference="wumfunction">
                                <ChipField source="name" />
                            </ReferenceField>
                            <ReferenceField label="hgw.wum.material" source="wum.material" reference="wummaterial">
                                <ChipField source="name" />
                            </ReferenceField>
                        </Datagrid>
                    </ReferenceManyField>
                    <MomentField label="hgw.flux.point.firstdate" source="firstPointDate" />
                    <MomentField label="hgw.flux.point.lastdate" source="lastPointDate" />
                    <PeriodField label="hgw.period.label" source="period" />
                </Tab>
                <Tab label="hgw.metrics">
                    <NumberField label="hgw.flux.size" source="size" />
                    <NumberField label="hgw.flux.point.count" source="pointsCount" />
                </Tab>
                {!!extendedProperties && extendedProperties.length > 0 && !!businessAccount &&
                    <Tab label={businessAccount.name}>
                        {extendedProperties.map(ep => ValueFieldFx({ extendedProperty: ep }))}
                    </Tab>
                }
            </TabbedShowLayout>
        </Show>
    )
};

export default FluxShow;
