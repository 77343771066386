import BuildingLogo from '../pictures/building32.png';
import SiteLogo from '../pictures/site32.png';
import ZoneLogo from '../pictures/zone32.png';

const TypeEnums = {
    site: { name: 'hgw.infra.site', logo: SiteLogo },
    building: { name: 'hgw.infra.building', logo: BuildingLogo },
    zone: { name: 'hgw.infra.zone', logo: ZoneLogo }
}

export const TypeList = Object.entries(TypeEnums).map(([id, obj]) => {
    obj.id = id;
    return obj
})

export default TypeEnums;