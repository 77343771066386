import SmartLocalUnitList from './list';
import SmartLocalUnitShow from './show';
import SmartLocalUnitEdit from './edit';
import SmartLocalUnitIcon from './icon';

export default {
    list: SmartLocalUnitList,
    show: SmartLocalUnitShow,
    edit: SmartLocalUnitEdit,
    icon: SmartLocalUnitIcon
};