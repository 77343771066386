import httpClient from './services/httpClient';
import mapper from './factory/mapper';
import requestUtils from './factory/requestUtils';
import contextStore from '../provider/services/contextStore';

const root = () => `wac/${contextStore.getWac()}/reflect`;

export default {

    map: (json) => {
        json.validated = json.status === 2;
        mapper.mapTags(json);
        return json;
    },

    getList: (params) => {
        if (!params.toValidate) delete params.toValidate;
        const query = requestUtils.buildQuery(params);
        return httpClient(`${root()}?${query}`).then(({ json }) => json)
    },

    getOne: (params) => {
        return httpClient(`${root()}/${params.id}`).then(({ json }) => json)
    },

    getMany: (params) => {
        const query = params.ids.map((id) => `ids=${id}`).join("&");
        return httpClient(`${root()}/many?${query}`).then(({ json }) => json)
    },

    getManyReference: (params) => {
        //const { page, perPage } = params.pagination;
        //const { field, order } = params.sort;
        //const query = {
        //    sort: JSON.stringify([field, order]),
        //    range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        //    filter: JSON.stringify({
        //        ...params.filter,
        //        [params.target]: params.id,
        //    }),
        //};
        //const url = `reflect?${stringify(query)}`;

        //return httpClient(url).then(({ headers, json }) => ({
        //    data: json,
        //    total: parseInt(headers.get('content-range').split('/').pop(), 10),
        //}));
    },

    update: (params) => {
        const puts = [];
        var dataUpdate = requestUtils.getDatas(params, ['id', 'extKey', 'criticityOverride', 'extendedProperties']);
        if (dataUpdate) {
            puts.push({
                url: root(),
                method: 'PUT',
                data: JSON.stringify(dataUpdate)
            });
        }

        var dataValid = requestUtils.getDatas(params, ['id', 'validated']);
        if (dataValid) {
            dataValid.validate = dataValid.validated;
            delete dataValid.validated;
            puts.push({
                url: `${root()}/validate`,
                method: 'PATCH',
                data: JSON.stringify(dataValid)
            });
        }

        var dataInfra = requestUtils.getDatas(params, ['id', 'infraId', 'isInfraOverride']);
        if (dataInfra) {
            if (!dataInfra.isInfraOverride) {
                delete dataInfra.infraId;
            }

            delete dataInfra.isInfraOverride;
            puts.push({
                url: `${root()}/localize`,
                method: 'PATCH',
                data: JSON.stringify(dataInfra)
            });
        }

        var newTags = requestUtils.getNewTags(params);
        newTags.forEach(tag =>
            puts.push({
                url: `${root()}/tag`,
                method: 'PATCH',
                data: JSON.stringify({ id: params.data.id, tag: tag })
            })
        )

        var removedTags = requestUtils.getRemovedTags(params);;
        removedTags.forEach(tag => puts.push({
            url: `${root()}/${params.data.id}/tag/${tag}`,
            method: 'DELETE'
        }))

        return requestUtils.sendSynchrone(puts);
    },

    updateMany: (params) => {
        const puts = [];
        if (params.data.validate) {
            params.ids.forEach(id => {
                var cmd = { id, validate: true };
                puts.push({
                    url: `${root()}/validate`,
                    method: 'PATCH',
                    data: JSON.stringify(cmd)
                });
            })
        }

        return requestUtils.sendSynchrone(puts).then(() => params.ids);
    },

    create: (params) => {
        return httpClient(root(), {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => json)
    },

    delete: (params) => {
        return httpClient(`${root()}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => json)
    },

    deleteMany: (params) => { },
};
