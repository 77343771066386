import * as React from 'react';
import contextStore from '../provider/services/contextStore';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import {
    MenuItemLink,
    getResources,
} from 'react-admin';

const CustomMenu = ({ onMenuClick, logout, businessaccount }) => {
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    useSelector((state) => state.theme); // force rerender on theme change
    const resources = useSelector(getResources);
    var hasBadge = contextStore.getHasBadge();
    var displayedResources = resources.filter(r => {
        if (!r.hasList || r.options.displaymenu === false || !businessaccount) return false;
        switch (r.name) {
            case "reflect":
                return businessaccount.reflectCount > 0;
            case "flux":
                return businessaccount.fluxCount > 0;
            case "fluxitem":
                return businessaccount.fluxItemCount > 0;
            case "devicelora":
                return businessaccount.deviceCount > 0;
            case "badge":
                return hasBadge;
            default:
                return true;
        }
    });
    return (
        <>
            <br />
            {displayedResources.map(resource => (
                <MenuItemLink
                    key={resource.name}
                    to={`/${resource.name}`}
                    primaryText={
                        (resource.options && resource.options.label) ||
                        resource.name
                    }
                    leftIcon={<resource.icon />}

                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
            ))}
            {isXSmall && logout}
        </>
    );
};

export default CustomMenu;