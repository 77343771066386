import * as React from "react";
import {
    AutocompleteInput,
    BooleanInput,
    Create,
    DateInput,
    FormDataConsumer,
    NumberInput,
    RadioButtonGroupInput,
    ReferenceInput,
    SelectInput,
    TabbedForm,
    FormTab,
    TextInput
} from 'react-admin';
import UserCardField from '../user/fields/cardField';
import KindEnums from './kindEnums';
import MediaTypeEnums from './mediaTypeEnums';
import CentralKindEnums from './centralKindEnums';

const BadgeCreate = props => (
    <Create {...props} re>
        <TabbedForm redirect="list">
            <FormTab label="hgw.summary">
                <TextInput label="hgw.badge.code" source="code" allowEmpty={false} />
                <ReferenceInput label="hgw.user.label" source="userId" reference="user" emptyText="hgw.badge.emptyuser" filter="friendly" allowEmpty>
                    <SelectInput optionText={<UserCardField />} />
                </ReferenceInput>
                <FormDataConsumer>
                    {({ formData, ...rest }) => !formData.userId &&
                        <TextInput label="hgw.label" source="label" {...rest} />
                    }
                </FormDataConsumer>
            </FormTab>
            <FormTab label="hgw.badge.constraint">
                <ReferenceInput label="hgw.badge.infra" source="infraId" reference="infra"
                    filterToQuery={searchText => ({ search: searchText })}
                    perPage={25}
                    emptyText="hgw.undefined"
                    allowEmpty>
                    <AutocompleteInput optionText="name" optionValue="id" />
                </ReferenceInput>
                <DateInput label="hgw.badge.startdate" source="startDate" />
                <DateInput label="hgw.badge.enddate" source="enddate" />
                <NumberInput label="hgw.badge.uselimit" source="useLimit" step={100} />
            </FormTab>
            <FormTab label="hgw.badge.kind.many">
                <RadioButtonGroupInput label="hgw.badge.kind.label"
                    source="kind"
                    choices={KindEnums} />
                <RadioButtonGroupInput label="hgw.badge.mediatype.label"
                    source="mediaType"
                    choices={MediaTypeEnums} />
                <RadioButtonGroupInput label="hgw.badge.centralkind.label"
                    source="centralKind"
                    choices={CentralKindEnums} />
            </FormTab>
            <FormTab label="hgw.badge.usage.many">
                <BooleanInput label="hgw.badge.usage.masterduplicate" source="usage.masterDuplicate" allowEmpty={false} />
                <BooleanInput label="hgw.badge.usage.masterkill" source="usage.masterKill" />
                <BooleanInput label="hgw.badge.usage.resourcecommand" source="usage.resourceCommand" />
                <BooleanInput label="hgw.badge.usage.access" source="usage.access" />
                <BooleanInput label="hgw.badge.usage.zonecommand" source="usage.zoneCommand" />
            </FormTab>
        </TabbedForm>
    </Create>
);

export default BadgeCreate;