import DeviceLoraList from './list';
import DeviceLoraShow from './show';
import DeviceLoraEdit from './edit';
import DeviceLoraIcon from './icon';

export default {
    list: DeviceLoraList,
    show: DeviceLoraShow,
    edit: DeviceLoraEdit,
    icon: DeviceLoraIcon
};