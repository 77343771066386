import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import recordSourceResolver from './recordSourceResolver';

const useStyles = makeStyles({
    avatar: {
        height: 30,
        width: 30,
    },
});

const PhotoAvatar = ({ record, source, size, fallbackSrc, children }) => {
    const classes = useStyles();
    const idPhoto = recordSourceResolver({ record, source });
    return (
        <Avatar className={classes.avatar} src={!idPhoto ? fallbackSrc : `picture/${idPhoto}/${size}`} >
            {children}
        </Avatar>
    )
};

PhotoAvatar.defaultProps = { addLabel: true, size: 32 }

export default PhotoAvatar;