import * as React from "react";
import {
    BooleanField,
    DateField,
    EditButton,
    ListButton,
    ReferenceField,
    RichTextField,
    SelectField,
    Show,
    Tab,
    TabbedShowLayout,
    TextField,
    TopToolbar,
    UrlField
} from 'react-admin';
import title from './title';
import ImagePictureField from '../crossCutting/fields/imagePictureField';
import ReferencetOrganization from "../organization/fields/reference";
import AutorenewIcon from '@material-ui/icons/Autorenew';
import PanToolIcon from '@material-ui/icons/PanTool';
import { StatusList } from './enums/status';

const BusinessAccountTitle = title;

const BusinessAccountEditActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <ListButton basePath={basePath} />
        <EditButton basePath={basePath} record={data} />
    </TopToolbar>
);

const BusinessAccountShow = props => (
    <Show title={<BusinessAccountTitle />} actions={<BusinessAccountEditActions />} {...props} >
        <TabbedShowLayout>
            <Tab label="hgw.summary">
                <ImagePictureField label="hgw.logo" source="picture" />
                <TextField source="name" />
                <TextField source="wac" />
                <RichTextField source="description" />
                <UrlField source="url" required={false} />
                <BooleanField label="hgw.businessaccount.autovalidresources" source="autoValidResources" valueLabelTrue="hgw.businessaccount.autovalidresourcesyes" valueLabelFalse="hgw.businessaccount.autovalidresourcesno" TrueIcon={AutorenewIcon} FalseIcon={PanToolIcon} />
                <SelectField source="status" choices={StatusList} />
                <DateField source="creationDate" />
                <DateField source="updateDate" />
            </Tab>
            <Tab label="hgw.organization.many">
                <ReferencetOrganization label="hgw.organization.owner" source="ownerCode" />
                <ReferencetOrganization label="hgw.organization.operator" source="operatorCode" />
            </Tab>
            <Tab label="hgw.address.label">
                <TextField label="hgw.address.phone" source="address.phone" />
                <TextField label="hgw.address.fax" source="address.fax" />
                <TextField label="hgw.address.street" source="address.street" />
                <TextField label="hgw.address.postalcode" source="address.postalCode" />
                <TextField label="hgw.address.city" source="address.city" />
                <ReferenceField label="hgw.country.label" source="address.countryId" reference="country">
                    <TextField source="name" />
                </ReferenceField>
            </Tab>
            <Tab label="hgw.metrics" disabled>
            </Tab>
            <Tab label="hgw.businessaccount.offer.label" disabled>
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default BusinessAccountShow;