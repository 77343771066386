import * as React from "react";
import TypeEnum from '../enums/type';
import { useTranslate } from 'react-admin';
import { Tooltip } from '@material-ui/core';
import recordSourceResolver from '../../crossCutting/fields/recordSourceResolver';
import Chip from '@material-ui/core/Chip';

const Type = TypeEnum;

const ActivityTypeField = ({ record, source, addLabel }) => {
    const translate = useTranslate();
    const value = recordSourceResolver({ record, source: source ?? "id" });
    if (!value) return <span>{translate('hgw.undefined')}</span>;
    const type = Type[value];
    const name = translate(type.name);
    return addLabel ?
        <Chip icon={<type.icon />} label={name} key={record} /> :
        <Tooltip title={name} arrow placement="right" >
            {<type.icon />}
        </Tooltip>
}

ActivityTypeField.defaultProps = { addLabel: true }

export default ActivityTypeField;