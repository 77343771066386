import httpClient from './services/httpClient';
import contextStore from '../provider/services/contextStore';

const rootBusinessAccount = () => `core/role/${contextStore.getWac()}`;
const rootGlobal = () => 'core/role';

function getCachedLevelBusinessAccount() {
    if (!contextStore.getWac()) return Promise.resolve([]);
    const cacheKey = GetCacheKeyLevelBusinessAccount();
    var roles = sessionStorage.getItem(cacheKey);
    return !roles ?
        httpClient(rootBusinessAccount()).then(({ json }) => {
            sessionStorage.setItem(cacheKey, JSON.stringify(json));
            return json;
        }) :
        Promise.resolve(JSON.parse(roles));
}

function getCachedLevelGlobal(filterOrganization = false) {
    const cacheKey = GetCacheKeyLevelGlobal();
    var roles = sessionStorage.getItem(cacheKey);
    return (!roles ?
        httpClient(rootGlobal()).then(({ json }) => {
            sessionStorage.setItem(cacheKey, JSON.stringify(json));
            return json;
        }) :
        Promise.resolve(JSON.parse(roles))
    ).then(roles => {
        return filterOrganization ? roles.filter(r => r.level === 'organization') : roles;
    });
}

function GetCacheKeyLevelBusinessAccount() {
    return `${contextStore.getWac()}_role`;
}

function GetCacheKeyLevelGlobal() {
    return 'role';
}

function clearCached() {
    sessionStorage.removeItem(GetCacheKeyLevelBusinessAccount());
    sessionStorage.removeItem(GetCacheKeyLevelGlobal());
}

function getCached(id) {
    id = (typeof (id) === "string") ? parseInt(id) : id;
    return getCachedLevelBusinessAccount().then(roles => {
        var roleBus = roles.find(r => r.id === id);
        return roleBus ?? getCachedLevelGlobal().then(roles => {
            return roles.find(r => r.id === id);
        })
    })
}

export default {

    map: (json) => {
        return json;
    },

    getList: (params) => {
        clearCached();
        var promise = params.organization ? getCachedLevelGlobal(true) : getCachedLevelBusinessAccount();
        return promise.then(
            (json) => {
                return {
                    data: json,
                    count: json.length
                }
            })
    },

    getOne: (params) => {
        return getCached(params.id).then(role => {
            if (!role) {
                clearCached();
                return getCached(params.id);
            }

            return role;
        });
    },

    getMany: (params) => {
        return Promise.all(params.ids.map(r => getCached(r))).then(roles => roles.filter(r => r != null));
    },

    update: (params) => {
        return httpClient(rootBusinessAccount(), {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => {
            clearCached();
            return json;
        })
    },

    create: (params) => {
        return httpClient(rootBusinessAccount(), {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => {
            clearCached();
            return json;
        })
    },

    delete: (params) => {
        return httpClient(`${rootBusinessAccount()}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => {
            clearCached();
            return json;
        })
    }
};
