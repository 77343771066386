import * as React from "react";
import {
    BooleanInput,
    Edit,
    FormDataConsumer,
    FormTab,
    TabbedForm,
    useTranslate
} from 'react-admin';
import MailIcon from '@material-ui/icons/Mail';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import { Box, Typography } from '@material-ui/core';
import title from './title';
import contextStore from '../../provider/services/contextStore';

const NotificationPolicyTitle = title;

const Aside = () => {
    const translate = useTranslate();
    return (
        <div style={{ width: 200, margin: '1em' }}>
            <Typography variant="h5">{translate('hgw.help')}</Typography>
            <Typography variant="overline" display="block" >
                {translate('hgw.notificationpolicy.globalhelp')}
            </Typography>
            <Box textAlign="center" fontWeight="fontWeightBold">
                {translate('hgw.or')}
            </Box>
            <Typography variant="overline" display="block" >
                {translate('hgw.notificationpolicy.businessaccounthelp')}
            </Typography>
        </div>
    )
};

const NotificationPolicyEdit = props => {
    const businessAccount = contextStore.getBusinessAccount()?.name ?? "hgw.businessaccount.label";
    return (
        <Edit title={<NotificationPolicyTitle />} aside={<Aside />} {...props}>
            <TabbedForm>
                <FormTab label={businessAccount}>
                    <BooleanInput label="hgw.notificationpolicy.isdefault" source="wac.isDefault" options={{ icon: <ClearOutlinedIcon />, checkedIcon: <CheckOutlinedIcon /> }} />
                    <FormDataConsumer>
                        {({ formData, ...rest }) => !formData.wac.isDefault &&
                            <BooleanInput label="hgw.notificationpolicy.sendmail" source="wac.sendMail" options={{ icon: <ClearOutlinedIcon />, checkedIcon: <MailIcon /> }} {...rest} />
                        }
                    </FormDataConsumer>
                </FormTab>
                <FormTab label="hgw.notificationpolicy.global">
                    <BooleanInput label="hgw.notificationpolicy.isdefault" source="global.isDefault" options={{ icon: <ClearOutlinedIcon />, checkedIcon: <CheckOutlinedIcon /> }} />
                    <FormDataConsumer>
                        {({ formData, ...rest }) => !formData.global.isDefault &&
                            <BooleanInput label="hgw.notificationpolicy.sendmail" source="global.sendMail" options={{ icon: <ClearOutlinedIcon />, checkedIcon: <MailIcon /> }} {...rest} />
                        }
                    </FormDataConsumer>
                </FormTab>
            </TabbedForm>
        </Edit>
    )
};

export default NotificationPolicyEdit;