import httpClient from './services/httpClient';
import requestUtils from './factory/requestUtils';
import contextStore from '../provider/services/contextStore';

const root = () => `wac/${contextStore.getWac()}/flux/item`;

export default {

    map: (json) => {
        json.points = Object.entries(json.points ?? {}).map(p => ({ date: p[0], value: p[1] }));
        return json;
    },

    getList: (params) => {
        const query = requestUtils.buildQuery(params);
        return httpClient(`${root()}?${query}`).then(({ json }) => json)
    },

    getOne: (params) => {
        return httpClient(`${root()}/${params.id}?includePoints=true`).then(({ json }) => {
            if (json.origin === 'acquiring') return json;

            // get activity targetting fluxItem: origin can not be acquiring
            const fluxItem = json;

            const paramsActivity = {
                wac: contextStore.getWac(),
                targetFluxItemId: params.id
            };

            const queryparamsActivity = requestUtils.buildQuery(paramsActivity);

            return httpClient(`activity?${queryparamsActivity}`).then(({ json }) => {
                if (json.count > 0) fluxItem.activity = json.data[0];
                return fluxItem;
            })
        })
    },

    getMany: (params) => {
        const query = params.ids.map((id) => `ids=${id}`).join("&");
        return httpClient(`${root()}/many?${query}`).then(({ json }) => json)
    },

    getManyReference: (params) => {
        const query = requestUtils.buildQuery(params);
        return httpClient(`${root()}?${query}`).then(({ json }) => json)
    },

    update: (params) => {
        return httpClient(root(), {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => json)
    },

    updateMany: (params) => {
        //const query = {
        //    filter: JSON.stringify({ id: params.ids }),
        //};
        //return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
        //    method: 'PUT',
        //    body: JSON.stringify(params.data),
        //}).then(({ json }) => ({ data: json }));
    },

    create: (params) => {
        return httpClient(`${root()}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => json)
    },

    delete: (params) => {
        return httpClient(`${root()}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => json)
    },

    deleteMany: (params) => {
        //const query = {
        //    filter: JSON.stringify({ id: params.ids }),
        //};
        //return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
        //    method: 'DELETE',
        //    body: JSON.stringify(params.data),
        //}).then(({ json }) => ({ data: json }));
    },
};
