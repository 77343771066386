import * as React from "react";
import { useMediaQuery } from '@material-ui/core';
import {
    List,
    Filter,
    Datagrid,
    TextField,
    ReferenceField,
    ReferenceInput,
    RadioButtonGroupInput,
    SelectInput,
    SimpleList,
    TextInput
} from 'react-admin';
import InfraTypeField from './fields/type';
import InfraUsageReferenceField from './usage/fields/reference';
import InfraReferenceField from './fields/reference';
import { TypeList } from './enums/type';
import ImagePictureField from '../crossCutting/fields/imagePictureField';

const InfraFilter = (props) => (
    <Filter {...props}>
        <TextInput label="hgw.search" source="search" alwaysOn />
        <RadioButtonGroupInput source="type" choices={TypeList} alwaysOn ></RadioButtonGroupInput>
        <ReferenceInput label="hgw.infra.usage.label" source="typeId" reference="infrausage">
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

const InfraList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List filters={<InfraFilter />} {...props}>
            {isSmall ?
                (<SimpleList
                    linkType="show"
                    leftIcon={record => <InfraUsageReferenceField record={record} source="type" />}
                    primaryText={record => record.name}
                    secondaryText={record => record.description ?? '-'}
                    tertiaryText={record => <ReferenceField source="parentId" reference="infra" basePath="infra" record={record}>
                        <TextField source="name" />
                    </ReferenceField>}
                />) :
                (<Datagrid rowClick="show">
                    <InfraTypeField label="hgw.label" source="name" />
                    <TextField label="hgw.description" source="description" />
                    <ReferenceField label="hgw.infra.parent" source="parentId" reference="infra" sortable={false}>
                        <InfraReferenceField />
                    </ReferenceField>
                    <ReferenceField label="hgw.infra.usage.label" source="usageId" reference="infrausage" sortable={false}>
                        <InfraUsageReferenceField />
                    </ReferenceField>
                    <ImagePictureField label="hgw.logo" source="picture" size="64" />
                </Datagrid>)}
        </List>
    )
};

export default InfraList;