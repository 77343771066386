import InfraUsageList from './list';
import InfraUsageCreate from './create';
import InfraUsageEdit from './edit';
import InfraUsageIcon from './icon';

export default {
    list: InfraUsageList,
    create: InfraUsageCreate,
    edit: InfraUsageEdit,
    icon: InfraUsageIcon
};