import smartLocalUnitLogo from '../../smartlocalunit/pictures/smartLocalUnit32.png';
import fluxLogo from '../../flux/pictures/level1.png';
import reflectLogo from '../../reflect/pictures/reflectDefaultCmd32.png';

const ResourceTypeEnums = {
    shared: { name: 'hgw.extendedproperty.shared' },
    flux: { name: 'hgw.flux.many', logo: fluxLogo },
    reflect: { name: 'hgw.reflect.many', logo: reflectLogo },
    smartLocalUnit: { name: 'hgw.smartlocalunit.many', logo: smartLocalUnitLogo }
}

export const ResourceTypeList = Object.entries(ResourceTypeEnums).map(([id, obj]) => {
    obj.id = id;
    return obj
})

export default ResourceTypeEnums;