import * as React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Badge, Tooltip } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import { Mail, Cancel } from '@material-ui/icons';

const useStyles = makeStyles({
    override: { color: "White" },
    default: { color: "Gray" },
});

const SendMail = ({ record, source }) => {
    const translate = useTranslate();
    const classes = useStyles();
    if (!record[source]) return null;
    const isDefault = record[source].isDefault;
    const className = isDefault ? classes.default : classes.override;
    return <Tooltip title={translate(isDefault ? "hgw.notificationpolicy.isdefault" : "hgw.notificationpolicy.isoverride")} arrow>
        <Badge color="primary" className={className} >
            {(record[source].sendMail ? <Mail /> : <Cancel />)}
        </Badge>
    </Tooltip>;
}

export default SendMail;