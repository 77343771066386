import * as React from "react";
import { Avatar, makeStyles } from '@material-ui/core';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';

const useStyles = makeStyles({
    avatar: {
        height: 32,
        width: 32,
    }
});

const TagIcon = props => {
    const classes = useStyles();
    return (
        <Avatar className={classes.avatar} >
            <LocalOfferIcon />
        </Avatar>
    )
}

export default TagIcon;