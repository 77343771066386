import * as React from "react";
import {
    Create,
    FormTab,
    ReferenceInput,
    SelectInput,
    TabbedForm,
    TextInput,
} from 'react-admin';
import { ValueTypeList } from "./enums/valueType";
import FluxReferenceField from '../flux/fields/reference';
import ValueTypeField from './fields/valueType';
import title from './title';
const FluxItemTitle = title;

export const FluxItemCreate = props => {
    return (
        <Create title={<FluxItemTitle />} {...props}>
            <TabbedForm redirect="list">
                <FormTab label="hgw.summary">
                    <TextInput label="hgw.label" source="name" allowEmpty={false} />
                    <ReferenceInput label="hgw.flux.label" source="fluxId" reference="flux" filter={{ origin: 'cloud' }} allowEmpty={false}>
                        <SelectInput optionText={<FluxReferenceField />} />
                    </ReferenceInput>
                    <TextInput label="hgw.flux.item.code" source="label" allowEmpty={false} />
                    <TextInput label="hgw.description" multiline source="description" />
                    <SelectInput label="hgw.flux.item.type.label" source="type" choices={ValueTypeList} optionText={<ValueTypeField />} allowEmpty={false} />
                </FormTab>
                <FormTab label="hgw.wum.label">
                    <ReferenceInput label="hgw.wum.unit" source="wum.unit" reference="wumunit">
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <ReferenceInput label="hgw.wum.type" source="wum.type" reference="wumtype">
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <ReferenceInput label="hgw.wum.function" source="wum.function" reference="wumfunction">
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <ReferenceInput label="hgw.wum.material" source="wum.material" reference="wummaterial">
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                </FormTab>
            </TabbedForm>
        </Create>
    )
};

export default FluxItemCreate;