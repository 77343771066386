import * as React from "react";
import { makeStyles } from '@material-ui/core/styles';
import InfraTypeEnums from '../enums/type';

const useStyles = makeStyles({
    div: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap'
    }
});

const InfraTypeField = ({ record, source }) => {
    const classes = useStyles();
    if (!record.type) return (<div />);
    const type = InfraTypeEnums[record.type];
    return  (
        <>
            <div className={classes.div}>
                <img src={type?.logo} alt={`type${record[source]}`} />
                <span>&nbsp;{record[source]}</span>
            </div>
        </>
    )
}

InfraTypeField.defaultProps = { addLabel: true }

export default InfraTypeField;
