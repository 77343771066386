import InfraList from './list';
import InfraShow from './show';
import InfraCreate from './create';
import InfraEdit from './edit';
import InfraIcon from './icon';

export default {
    list: InfraList,
    show: InfraShow,
    create: InfraCreate,
    edit: InfraEdit,
    icon: InfraIcon
};