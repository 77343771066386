import httpClient from "../services/httpClient";

export default {
    buildQuery: (data) => {
        const query = [];
        Object.keys(data).forEach(key => {
            var value = data[key];
            if (Array.isArray(value)) {
                value.forEach(val => query.push(`${key}=${encodeURIComponent(val)}`))
            } else {
                query.push(`${key}=${encodeURIComponent(value)}`);
            }
        })

        return query.join('&');
    },

    getDatas: (params, props) => {
        if (!params.data) return null;
        const hasChanged = !params.hasOwnProperty("previousData") || !params.previousData || props.some(p => (params.data[p] ?? null) !== (params.previousData[p] ?? null));
        if (!hasChanged) return null;
        const data = {};
        props.forEach(p => {
            if (params.data.hasOwnProperty(p)) {
                data[p] = params.data[p];
            }
        })
        return data;
    },

    getNewTags: (params) => {
        return (params.data.tags ?? []).filter(t => (params.previousData.tags ?? []).indexOf(t) === -1);
    },

    getRemovedTags: (params) => {
        return (params.previousData.tags ?? []).filter(t => (params.data.tags ?? []).indexOf(t) === -1)
    },

    sendSynchrone: (actions) => {
        if (actions.length === 0) return Promise.reject("No changes");

        return actions.reduce(function (p, item) {
            return p.then(function () {
                return httpClient(item.url, {
                    method: item.method,
                    body: item.data,
                });
            });
        }, Promise.resolve())
            .then(({ json }) => {
                return json;
            })
        //.catch(function (err) {
        //    var a = err;
        //});
    }
};
