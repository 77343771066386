import * as React from "react";
import { useState, useEffect } from 'react';
import {
    FormDataConsumer,
    SelectInput,
    TextInput,
    useDataProvider,
    Loading
} from 'react-admin';

const SelectOrganization = ({ ...props }) => {
    const [organizations, setOrganizations] = useState([]);
    const [loading, setLoading] = useState(true);
    const dataProvider = useDataProvider();
    useEffect(() => {
        dataProvider.getList('organization', {})
            .then(({ data }) => {
                setOrganizations(data);
                setLoading(false);
            })
            .catch(error => {
                setOrganizations([]);
                setLoading(false);
            })
    }, []);
    if (loading) return <Loading />;
    return (<>
        {organizations.length > 0 ?
            <SelectInput {...props} label='hgw.organization.near' choices={organizations} optionText="name" optionValue="code" emptyText="hgw.organization.other" allowEmpty />
            : null}
        <br />
        <FormDataConsumer>
            {({ formData, ...rest }) => {
                return !organizations.some(o => o.code === formData[props.source]) &&
                    <TextInput label='hgw.organization.code' source={props.source} {...rest} />
            }}
        </FormDataConsumer>
    </>)
};

SelectOrganization.defaultProps = { addLabel: true }

export default SelectOrganization;