import fetchJson from './fetchJson';
import contextStore from './contextStore';
import authService from '../../oidc/AuthorizeService'

const spaInfo = () => {
    var version = contextStore.getVersion();
    if (!!version) return Promise.resolve();

    const request = new Request('/spa/info', {
        method: 'GET',
        headers: new Headers({ 'Content-Type': 'application/json' }),
    });

    return fetch(request)
        .then(response => {
            return response.json().then((data) => {
                contextStore.setSpaInfo(data);
            })
        });
};

const httpClient = (path, options = {}) => {
    return spaInfo().then(() => {

        if (!options.headers) {
            options.headers = new Headers({ Accept: 'application/json' });
        }

        return authService.getAccessToken().then(accessToken => {

            options.headers.set('Authorization', `Bearer ${accessToken}`);
            return fetchJson(`/api/v2/${path}`, options);
        })
    })
};

export default httpClient;