import ScopeList from './list';
import ScopeShow from './show';
import ScopeCreate from './create';
import ScopeEdit from './edit';
import ScopeIcon from './icon';

export default {
    list: ScopeList,
    show: ScopeShow,
    create: ScopeCreate,
    edit: ScopeEdit,
    icon: ScopeIcon
};