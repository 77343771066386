import httpClient from './services/httpClient';

const root = () => `core/role/operation`;

function getLocaleOperations() {
    const cacheKey = 'operation';
    var operations = localStorage.getItem(cacheKey);
    return (!operations ?
        httpClient(root()).then(({ json }) => {
            json.forEach(j => j.name = `${j.entity} ${j.action}`);
            localStorage.setItem(cacheKey, JSON.stringify(json));
            return json;
        }) :
        Promise.resolve(JSON.parse(operations)));
}

export default {

    map: (json) => { return json; },

    getList: (params) => {
        return getLocaleOperations().then(
            (operations) => {
                return {
                    data: operations,
                    count: operations.length
                }
            })
    },

    getOne: (params) => {
        return getLocaleOperations().then(
            (tags) => {
                return tags.find(t => params.id === t.id.toString());
            })
    },

    getMany: (params) => {
        return getLocaleOperations().then(
            (tags) => {
                return tags.filter(t => params.ids.indexOf(t.id) !== -1);
            })
    },

    getManyReference: (params) => { }
};
