import httpClient from './services/httpClient';
import requestUtils from './factory/requestUtils';
import contextStore from './services/contextStore';

const root = () => {
    return "core/user/notification";
};

function getPolicyGlobal() {
    const globalKey = getKey(true);
    var globalNotifications = sessionStorage.getItem(globalKey);
    return (!globalNotifications ?
        httpClient(root()).then(({ json }) => {
            sessionStorage.setItem(globalKey, JSON.stringify(json));
            return json;
        }) :
        Promise.resolve(JSON.parse(globalNotifications))
    )
}

function getPolicyWac() {
    const wacKey = getKey(false);
    var wacNotifications = sessionStorage.getItem(wacKey);
    return (!wacNotifications ?
        httpClient(`${root()}?wac=${contextStore.getWac()}`)
            .then(({ json }) => {
                json.forEach(j => j.id = j.eventType);
                sessionStorage.setItem(wacKey, JSON.stringify(json));
                return json;
            }) :
        Promise.resolve(JSON.parse(wacNotifications))
    )
}

function getKey(isGlobal) {
    const userId = contextStore.getUser()?.id;
    const key = `NOTIFICATION-POLICY-${isGlobal ? 'GLOBAL' : contextStore.getWac()}-${userId}`;
    return key;
}

function clearPolicy() {
    sessionStorage.removeItem(getKey(true));
    sessionStorage.removeItem(getKey(false));
}

export default {

    map: (json) => {
        // json.id = json.eventType;
        return json;
    },

    getList: (params) => {
        return getPolicyGlobal()
            .then(global => {
                return getPolicyWac().then(wac => {
                    var notifications = global.map(g => {
                        const w = wac.find(w => w.eventType === g.eventType);
                        return {
                            id: g.eventType,
                            label: g.label,
                            global: g,
                            wac: w
                        };
                    })

                    return {
                        data: notifications,
                        count: notifications.length
                    }
                })
            })
    },

    getOne: (params) => {
        const id = parseInt(params.id);
        return getPolicyGlobal().then(global => {
            const g = global.find(p => p.eventType === id);
            if (!g) return Promise.resolve(null);
            return getPolicyWac().then(wac => {
                const w = wac.find(p => p.eventType === id);
                return {
                    id: g.eventType,
                    label: g.label,
                    global: g,
                    wac: w
                };
            })
        })
    },

    getMany: (params) => { },

    getManyReference: (params) => { },

    update: (params) => {
        const puts = [];

        var paramsGlobal = { data: params.data.global, previousData: params.previousData.global };
        var dataGlobal = requestUtils.getDatas(paramsGlobal, ['eventType', 'isDefault', 'sendMail'])
        if (dataGlobal) {
            var cmdGlobal = {
                policies: [
                    {
                        eventType: dataGlobal.eventType,
                        sendMail: dataGlobal.isDefault ? null : dataGlobal.sendMail
                    }]
            };

            puts.push({
                url: root(),
                method: 'PUT',
                data: JSON.stringify(cmdGlobal)
            });
        }

        var paramsWac = { data: params.data.wac, previousData: params.previousData.wac };
        var dataWac = requestUtils.getDatas(paramsWac, ['eventType', 'isDefault', 'sendMail']);
        if (dataWac) {
            var cmdWac = {
                wac: contextStore.getWac(),
                policies: [
                    {
                        eventType: dataWac.eventType,
                        sendMail: dataWac.isDefault ? null : dataWac.sendMail
                    }]
            };

            puts.push({
                url: root(),
                method: 'PUT',
                data: JSON.stringify(cmdWac)
            });
        }

        clearPolicy();
        return requestUtils.sendSynchrone(puts).then(() => params.data);
    },

    updateMany: (params) => {
        const puts = [];

        if (!!params.data.global) {
            var cmdGlobal = {
                policies: params.ids.map(id => (
                    {
                        eventType: id,
                        sendMail: params.data.global.isDefault ? null : params.data.global.sendMail
                    }))
            };

            puts.push({
                url: root(),
                method: 'PUT',
                data: JSON.stringify(cmdGlobal)
            });
        }

        if (!!params.data.wac && !!contextStore.getWac()) {
            var cmdWac = {
                wac: contextStore.getWac(),
                policies: params.ids.map(id => (
                    {
                        eventType: id,
                        sendMail: params.data.wac.isDefault ? null : params.data.wac.sendMail
                    }))
            };

            puts.push({
                url: root(),
                method: 'PUT',
                data: JSON.stringify(cmdWac)
            });
        }

        clearPolicy();
        return requestUtils.sendSynchrone(puts).then(() => params.ids);
    },

    create: (params) => { },

    delete: (params) => { },

    deleteMany: (params) => { },
};
