import * as React from "react";
import {
    AutocompleteArrayInput,
    BooleanInput,
    Create,
    FormDataConsumer,
    ReferenceArrayInput,
    SelectInput,
    SimpleForm,
    TextInput
} from 'react-admin';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import AlarmBadge from '../reflect/fields/alarmBadge';
import { CriticityList } from '../reflect/enums/criticity';

export const ScopeCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <TextInput label="hgw.label" source="label" />
            <ReferenceArrayInput label="hgw.tag.many" source="query.tags" reference="tag" filterToQuery={searchText => ({ search: searchText })} perPage={25}>
                <AutocompleteArrayInput optionText="label" optionValue="label" />
            </ReferenceArrayInput>
            <ReferenceArrayInput label="hgw.infra.many" source="query.infraIds" reference="infra" filterToQuery={searchText => ({ search: searchText })} perPage={25}>
                <AutocompleteArrayInput optionText="name" optionValue="id" />
            </ReferenceArrayInput>
            <FormDataConsumer>
                {({ formData, ...rest }) => formData.query?.infraIds?.length > 0 &&
                    <BooleanInput label="hgw.infra.includechildren" source="query.includeInfraChildren" {...rest} options={{ checkedIcon: <AccountTreeIcon /> }} defaultValue={true} />
                }
            </FormDataConsumer>
            <SelectInput label="hgw.scope.criticitydiffusion" source="criticityDiffusion" choices={CriticityList} optionText={<AlarmBadge />} emptyText="criticitydiffusionempty" allowEmpty />
        </SimpleForm>
    </Create>
);

export default ScopeCreate;