import * as React from "react";
import {
    Button,
    Edit,
    FormTab,
    ImageField,
    ImageInput,
    RadioButtonGroupInput,
    ReferenceInput,
    SelectInput,
    TabbedForm,
    TextInput,
    TopToolbar,
    useRedirect
} from 'react-admin';
import { TypeList } from './enums/type';
import title from './title';
import ImagePictureField from '../crossCutting/fields/imagePictureField';
import contextStore from '../../provider/services/contextStore';
import IconShow from '@material-ui/icons/Visibility';

const OrganizationTitle = title;

//<EditButton basePath='/organization/edit' record={data} />
const OrganizationEditActions = ({ props, basePath, data, resource }) => {
    const redirect = useRedirect();
    return (
        <TopToolbar>
            <Button
                onClick={() => { redirect('/organization/show'); }}
                label="hgw.show" >
                <IconShow />
            </Button>
        </TopToolbar>
    )
};

const OrganizationEdit = props => {
    const organization = contextStore.getOrganization();
    const id = organization?.id;
    const redirect = useRedirect();
    const onSuccess = ({ data }) => {
        contextStore.setOrganization(data);
        redirect('/organization/show');
    };

    return (
        <Edit id={id} onSuccess={onSuccess} undoable={false} title={<OrganizationTitle />} actions={<OrganizationEditActions />} {...props}>
            <TabbedForm>
                <FormTab label="hgw.summary">
                    <TextInput disabled source="id" />
                    <TextInput label="hgw.organization.code" source="code" disabled />
                    <TextInput label="hgw.label" source="name" required={true} />
                    <TextInput label="hgw.description" source="description" multiline />
                    <TextInput label="hgw.url" source="url" />
                    <TextInput label="hgw.organization.businesssector" source="businessSector" />
                    <RadioButtonGroupInput label="hgw.type" source="type" choices={TypeList} />
                </FormTab>
                <FormTab label="hgw.logo">
                    <ImagePictureField label="hgw.picturecurrent" source="picture" />
                    <ImageInput label="hgw.picturenew" source="pictureraw" accept="image/*">
                        <ImageField source="src" title="title" />
                    </ImageInput>
                </FormTab>
                <FormTab label="hgw.organization.legalcode">
                    <TextInput label="hgw.organization.siret" source="siret" />
                    <TextInput label="hgw.organization.vatin" source="vatin" />
                    <TextInput label="hgw.organization.otherin" source="otherIn" />
                    <TextInput label="hgw.organization.legalname" source="legalName" />
                </FormTab>
                <FormTab label="hgw.address.label">
                    <TextInput label="hgw.address.phone" source="address.phone" />
                    <TextInput label="hgw.address.fax" source="address.fax" />
                    <TextInput label="hgw.address.street" source="address.street" multiline />
                    <TextInput label="hgw.address.postalcode" source="address.postalCode" />
                    <TextInput label="hgw.address.city" source="address.city" />
                    <ReferenceInput label="hgw.country.label" source="address.countryId" reference="country">
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                </FormTab>
            </TabbedForm>
        </Edit>
    )
};

export default OrganizationEdit;