import * as React from "react";
import { useMediaQuery } from '@material-ui/core';
import {
    ArrayField,
    AutocompleteArrayInput,
    BooleanInput,
    BulkDeleteButton,
    ChipField,
    Datagrid,
    Filter,
    List,
    ReferenceArrayInput,
    ReferenceField,
    SelectInput,
    SimpleList,
    SingleFieldList,
    TextField,
    TextInput,
    useRedirect
} from 'react-admin';
import SmartLocalUnitModelField from './fields/modelField';
import TagArrayField from '../tag/fields/arrayField';
import MomentField from '../crossCutting/fields/momentField';
import AlarmBadge from "../reflect/fields/alarmBadge";
import ResourceStatusField from '../crossCutting/fields/resourceStatusField';
import InfraReferenceField from '../infra/fields/reference';
import contextStore from '../../provider/services/contextStore';
import BulkValidateButton from '../crossCutting/buttons/BulkValidateButton';
import { CriticityList } from '../reflect/enums/criticity';
import { Fragment } from 'react';

const SmartLocalUnitListBulkActionButtons = props => {
    return <Fragment>
        <BulkValidateButton {...props} />
        <BulkDeleteButton {...props} />
    </Fragment>
};

const SmartLocalUnitFilter = (props) => (
    <Filter {...props}>
        <TextInput label="hgw.search" source="search" alwaysOn />
        <ReferenceArrayInput label="hgw.scope.many" source="scopeIds" reference="scope" filterToQuery={searchText => ({ search: searchText })} perPage={25} alwaysOn>
            <AutocompleteArrayInput optionText="label" optionValue="id" />
        </ReferenceArrayInput>
        <ReferenceArrayInput label="hgw.infra.many" source="infraIds" reference="infra" filterToQuery={searchText => ({ search: searchText })} perPage={25} alwaysOn>
            <AutocompleteArrayInput optionText="name" optionValue="id" />
        </ReferenceArrayInput>
        <SelectInput label="hgw.reflect.alarm" source="alarm" choices={CriticityList} optionText={<AlarmBadge />} emptyText="hgw.undefined" allowEmpty alwaysOn />
        <ReferenceArrayInput label="hgw.tag.many" source="tags" reference="tag" filterToQuery={searchText => ({ search: searchText })} perPage={25}>
            <AutocompleteArrayInput optionText="label" optionValue="label" />
        </ReferenceArrayInput>
        <BooleanInput label="hgw.status.tovalidate" source="toValidate" alwaysOn />
    </Filter>
);

const SmartLocalUnitList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const redirect = useRedirect();
    const wac = contextStore.getWac();
    if (!wac) {
        redirect('list', '/businessaccount');
        return null;
    }

    return (
        <List
            empty={false}
            title="hgw.smartlocalunit.many"
            bulkActionButtons={<SmartLocalUnitListBulkActionButtons />}
            filters={<SmartLocalUnitFilter />} {...props}>
            {isSmall ?
                (<SimpleList
                    linkType="show"
                    leftIcon={record => <SmartLocalUnitModelField record={record} source="model" />}
                    primaryText={record => record.name}
                    secondaryText={record => record.wid ?? '-'}
                    tertiaryText={record => <MomentField record={record} source="exchangeDate" />}
                />) :
                (<Datagrid rowClick="show">
                    <SmartLocalUnitModelField source="model" sortable={false} />
                    <TextField label="hgw.label" source="name" />
                    <TextField label="hgw.smartlocalunit.wid" source="wid" />
                    <ArrayField label="hgw.smartlocalunit.alarms" source="alarms" sortable={false}>
                        <SingleFieldList>
                            <AlarmBadge addLabel={false} />
                        </SingleFieldList>
                    </ArrayField>
                    <ChipField label="hgw.code" source="code" />
                    <TagArrayField label="hgw.tag.many" source="tags" sortable={false} />
                    <ReferenceField label="hgw.infra.label" source="infraId" reference="infra" sortable={false}>
                        <InfraReferenceField />
                    </ReferenceField>
                    <MomentField label="hgw.smartlocalunit.exchangedate" source="exchangeDate" />
                    <MomentField label="hgw.smartlocalunit.nextcall" source="nextCall" />
                    <ResourceStatusField label="hgw.status.label" source="status" />
                </Datagrid>)}
        </List>
    )
};

export default SmartLocalUnitList;