import * as React from "react";
import { useMediaQuery } from '@material-ui/core';
import {
    AutocompleteArrayInput,
    BooleanInput,
    BulkDeleteButton,
    ChipField,
    Datagrid,
    Filter,
    List,
    ReferenceArrayInput,
    ReferenceField,
    SimpleList,
    TextField,
    TextInput,
    useRedirect
} from 'react-admin';
import LoraIcon from './icon';
import LabelField from './fields/labelField';
import TagArrayField from '../tag/fields/arrayField';
import MomentField from '../crossCutting/fields/momentField';
import ResourceStatusField from '../crossCutting/fields/resourceStatusField';
import InfraReferenceField from '../infra/fields/reference';
import SmartLocalUnitReferenceField from '../smartlocalunit/fields/referenceField';
import contextStore from '../../provider/services/contextStore';
import BulkValidateButton from '../crossCutting/buttons/BulkValidateButton';
import { Fragment } from 'react';

const DeviceLoraListBulkActionButtons = props => {
    return <Fragment>
        <BulkValidateButton {...props} />
        <BulkDeleteButton {...props} />
    </Fragment>
};

const DeviceLoraFilter = (props) => (
    <Filter {...props}>
        <TextInput label="hgw.search" source="search" alwaysOn />
        <ReferenceArrayInput label="hgw.scope.many" source="scopeIds" reference="scope" filterToQuery={searchText => ({ search: searchText })} perPage={25} alwaysOn>
            <AutocompleteArrayInput optionText="label" optionValue="id" />
        </ReferenceArrayInput>
        <ReferenceArrayInput label="hgw.infra.many" source="infraIds" reference="infra" filterToQuery={searchText => ({ search: searchText })} perPage={25} alwaysOn>
            <AutocompleteArrayInput optionText="name" optionValue="id" />
        </ReferenceArrayInput>
        <BooleanInput label="hgw.status.tovalidate" source="toValidate" alwaysOn />
        <ReferenceArrayInput label="hgw.smartlocalunit.many" source="smartLocalUnitIds" reference="smartlocalunit" filterToQuery={searchText => ({ search: searchText })} perPage={25}>
            <AutocompleteArrayInput optionText="name" optionValue="id" />
        </ReferenceArrayInput>
        <ReferenceArrayInput label="hgw.tag.many" source="tags" reference="tag" filterToQuery={searchText => ({ search: searchText })} perPage={25}>
            <AutocompleteArrayInput optionText="label" optionValue="label" />
        </ReferenceArrayInput>
    </Filter>
);

const DeviceLoraList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const redirect = useRedirect();
    const wac = contextStore.getWac();
    if (!wac) {
        redirect('list', '/businessaccount');
        return null;
    }

    return (
        <List
            empty={false}
            title="hgw.device.lora.many"
            bulkActionButtons={<DeviceLoraListBulkActionButtons />}
            filters={<DeviceLoraFilter />} {...props}>
            {isSmall ?
                (<SimpleList
                    linkType="show"
                    leftIcon={record => <LoraIcon />}
                    primaryText={record => record.name}
                    secondaryText={record => record.devEUI}
                    tertiaryText={record => <MomentField record={record} source="exchangeDate" />}
                />) :
                (<Datagrid rowClick="show">
                    <LabelField label="hgw.label" source="name" />
                    <ChipField label="hgw.device.lora.commissioningMode" source="commissioningMode" />
                    <ChipField label="hgw.device.lora.lorawanClass" source="lorawanClass" />
                    <TextField label="hgw.device.lora.model" source="model" />
                    <TextField label="hgw.device.lora.manufacturer" source="manufacturer" />
                    <TagArrayField label="hgw.tag.many" source="tags" sortable={false} />
                    <ReferenceField label="hgw.infra.label" source="infraId" reference="infra" sortable={false}>
                        <InfraReferenceField />
                    </ReferenceField>
                    <ReferenceField label="hgw.smartlocalunit.label" source="smartLocalUnitId" reference="smartlocalunit" sortable={false} link="show">
                        <SmartLocalUnitReferenceField />
                    </ReferenceField>
                    <MomentField label="hgw.smartlocalunit.exchangedate" source="exchangeDate" />
                    <ResourceStatusField label="hgw.status.label" source="status" />
                </Datagrid>)}
        </List>
    )
};

export default DeviceLoraList;