import * as React from "react";
import {
    BooleanInput,
    Button,
    Create,
    FormTab,
    ImageField,
    ImageInput,
    ListButton,
    ReferenceInput,
    TabbedForm,
    TextInput,
    TopToolbar,
    SelectInput,
    useRedirect,
    useTranslate,
    useNotify
} from 'react-admin';
import IconJoin from '@material-ui/icons/SystemUpdateAlt';
import contextStore from "../../provider/services/contextStore";
import SelectOrganization from "../organization/inputs/select";
import AutorenewIcon from '@material-ui/icons/Autorenew';
import PanToolIcon from '@material-ui/icons/PanTool';

const BusinessAccountCreateTitle = () => {
    const translate = useTranslate();
    return <span>{translate('hgw.businessaccount.create')}</span>;
};

const BusinessAccountCreateActions = ({ props, basePath, data, resource }) => {
    const redirect = useRedirect();
    return (
        <TopToolbar>
            <ListButton basePath={basePath} />
            <Button
                onClick={() => {
                    redirect('/businessaccount/join');
                }}
                label="hgw.join" >
                <IconJoin />
            </Button>
        </TopToolbar>
    )
};

export const BusinessAccountCreate = ({ record, ...props }) => {
    const redirect = useRedirect();
    const notify = useNotify();
    const organization = contextStore.getOrganization();
    const onSuccess = ({ data }) => {
        notify('hgw.businessaccount.createsuccessfully');
        if (!contextStore.getWac()) {
            contextStore.setBusinessAccount(data);
        }

        redirect('/businessaccount');
    };

    return (
        <Create {...props} onSuccess={onSuccess} title={<BusinessAccountCreateTitle />} actions={<BusinessAccountCreateActions />}>
            <TabbedForm>
                <FormTab label="hgw.summary">
                    <TextInput source="name" />
                    <TextInput multiline source="description" />
                    <TextInput source="url" />
                    <BooleanInput label="hgw.businessaccount.autovalidresources" source="autoValidResources" options={{ icon: <PanToolIcon />, checkedIcon: <AutorenewIcon /> }} defaultValue={true} />
                </FormTab>
                <FormTab label="hgw.logo">
                    <ImageInput label="hgw.logo" source="pictureraw" accept="image/*">
                        <ImageField source="src" title="title" />
                    </ImageInput>
                </FormTab>
                <FormTab label="hgw.address.label">
                    <TextInput label="hgw.address.phone" source="address.phone" />
                    <TextInput label="hgw.address.fax" source="address.fax" />
                    <TextInput label="hgw.address.street" source="address.street" />
                    <TextInput label="hgw.address.postalcode" source="address.postalCode" />
                    <TextInput label="hgw.address.city" source="address.city" />
                    <ReferenceInput label="hgw.country.label" source="address.countryId" reference="country">
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                </FormTab>
                <FormTab label="hgw.organization.many">
                    <SelectOrganization label="hgw.organization.owner" source="ownerCode" initialValue={organization?.code} />
                    <SelectOrganization label="hgw.organization.operator" source="operatorCode" initialValue={organization?.code} />
                </FormTab>
                <FormTab label="hgw.businessaccount.offer.label" disabled>
                </FormTab>
            </TabbedForm>
        </Create>
    )
};
export default BusinessAccountCreate;
