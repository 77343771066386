import DeleteIcon from '@material-ui/icons/Delete';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import LockIcon from '@material-ui/icons/Lock';
import CloudOffIcon from '@material-ui/icons/CloudOff';

const ResourceStatusEnums = {
    disabled: { name: 'hgw.status.disabled', icon: DeleteIcon },
    toValidate: { name: 'hgw.status.tovalidate', icon: ErrorOutlineIcon },
    validated: { name: 'hgw.status.validated', icon: CheckCircleOutlineIcon },
    locked: { name: 'hgw.status.locked', icon: LockIcon },
    offline: { name: 'hgw.status.offline', icon: CloudOffIcon },
    customToKill: { name: 'hgw.status.customtokill', icon: DeleteIcon },
    adminToKill: { name: 'hgw.status.admintokill', icon: DeleteIcon },
    killed: { name: 'hgw.status.killed', icon: DeleteIcon }
}

export default ResourceStatusEnums;