import React from 'react';
import {
    Edit,
    ReferenceInput,
    SelectInput,
    TextInput,
    ImageInput,
    ImageField,
    TabbedForm,
    FormTab,
    useRedirect
} from 'react-admin';
import contextStore from '../../provider/services/contextStore';
import ImagePictureField from '../crossCutting/fields/imagePictureField';

const UserEdit = props => {
    const user = contextStore.getUser();
    const id = user?.id;
    const redirect = useRedirect();
    const onSuccess = ({ data }) => {
        if (!!data) contextStore.setUser(data);
        redirect('/profile/show');
    };

    return (
        <Edit id={id} title="hgw.user.profile" onSuccess={onSuccess} undoable={false} {...props}>
            <TabbedForm>
                <FormTab label="hgw.summary">
                    <TextInput source="id" disabled />
                    <TextInput label="hgw.user.firstname" source="firstname" />
                    <TextInput label="hgw.user.lastname" source="lastname" />
                    <TextInput label="hgw.user.phone" source="phone" />
                    <ReferenceInput label="hgw.culture.label" source="culture" reference="culture">
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                </FormTab>
                <FormTab label="hgw.user.photo">
                    <ImagePictureField label="hgw.user.photocurrent" source="photo" />
                    <ImageInput label="hgw.user.photonew" source="photoraw" accept="image/*">
                        <ImageField source="src" title="title" />
                    </ImageInput>
                </FormTab>
            </TabbedForm>
        </Edit>
    )
};

export default UserEdit;