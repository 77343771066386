import BadgeList from './list';
import BadgeShow from './show';
import BadgeCreate from './create';
import BadgeEdit from './edit';
import BadgeIcon from './icon';

export default {
    list: BadgeList,
    show: BadgeShow,
    create: BadgeCreate,
    edit: BadgeEdit,
    icon: BadgeIcon
};