import * as React from "react";
import FluxLogo from './pictures/level1.png';
import { Avatar, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    avatar: {
        height: 32,
        width: 32,
    }
});

export const FluxIcon = props => {
    const classes = useStyles();
    return (
        < Avatar src={FluxLogo} className={classes.avatar} />
    )
}

export default FluxIcon;