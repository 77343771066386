import OrganizationShow from './show';
import OrganizationEdit from './edit';
import OrganizationDefine from './define';
import OrganizationCreate from './create';
import OrganizationIcon from './icon';

export default {
    show: OrganizationShow,
    edit: OrganizationEdit,
    define: OrganizationDefine,
    create: OrganizationCreate,
    icon: OrganizationIcon
};