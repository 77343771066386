import * as React from "react";
import { useState, useEffect } from 'react';
import {
    TextField,
    ReferenceField,
    SelectField,
    TabbedShowLayout,
    Tab,
    BooleanField,
    RichTextField,
    DateField,
    NumberField,
    ShowController,
    ShowView,
    useDataProvider,
    useTranslate
} from 'react-admin';
import ReflectClassField from './fields/reflectClassField';
import MomentField from '../crossCutting/fields/momentField';
import EventOnIcon from '@material-ui/icons/EventAvailable';
import EventOffIcon from '@material-ui/icons/EventBusy';
import CommandIcon from '@material-ui/icons/Edit';
import TagArrayField from '../tag/fields/arrayField';
import contextStore from '../../provider/services/contextStore';
import AlarmBadge from "./fields/alarmBadge";
import title from './title';
import ResourceStatusField from '../crossCutting/fields/resourceStatusField';
import InfraReferenceField from '../infra/fields/reference';
import SmartLocalUnitReferenceField from '../smartlocalunit/fields/referenceField';
import { CriticityList } from "./enums/criticity";
import { ValueFieldFx } from '../extendedproperty/fields/fieldFx';

const ReflectTitle = title;

const ReflectShow = props => {
    const businessAccount = contextStore.getBusinessAccount();
    const [extendedProperties, setExtendedProperties] = useState();
    const [loading, setLoading] = useState(true);
    const dataProvider = useDataProvider();
    const translate = useTranslate();
    useEffect(() => {
        dataProvider.getList('extendedproperty', { filter: { resourceType: 'reflect' } })
            .then(({ data }) => {
                setExtendedProperties(data);
                setLoading(false);
            })
            .catch(error => {
                setExtendedProperties(null);
                setLoading(false);
            })
    }, []);
    if (loading) return null;
    return (
        <ShowController {...props}>
            {controllerProps =>
                //<ShowView aside={<Aside />} title={<SmartLocalUnitTitle />} {...props} {...controllerProps}>
                <ShowView title={<ReflectTitle />} {...props} {...controllerProps}>
                    <TabbedShowLayout>
                        <Tab label="hgw.summary">
                            <ReflectClassField source="classId" />
                            <TextField label="hgw.extkey" source="extKey" />
                            <TextField label="hgw.reflect.localid" source="localId" />
                            <SelectField label="hgw.reflect.criticity.override" source="criticityOverride" choices={CriticityList} optionText={<AlarmBadge />} emptyText={translate("hgw.reflect.criticity.undefined")} allowEmpty />
                            <TextField label="hgw.label" source="name" />
                            <TextField label="hgw.reflect.path" source="resourcePath" />
                            <RichTextField label="hgw.description" source="description" />
                            <ResourceStatusField label="hgw.status.label" source="status" />
                        </Tab>
                        <Tab label="hgw.tagsinfras">
                            <ReferenceField label="hgw.infra.label" source="infraId" reference="infra">
                                <InfraReferenceField />
                            </ReferenceField>
                            <TagArrayField label="hgw.tag.many" source="tags" />
                        </Tab>
                        <Tab label="hgw.communication">
                            <MomentField source="exchangeDate" />
                            <ReferenceField label="hgw.smartlocalunit.label" source="smartLocalUnitId" reference="smartlocalunit" link="show">
                                <SmartLocalUnitReferenceField />
                            </ReferenceField>
                            <BooleanField source="raiseEvents" TrueIcon={EventOnIcon} FalseIcon={EventOffIcon} />
                            <BooleanField source="allowCommand" TrueIcon={CommandIcon} />
                            <DateField source="creationDate" />
                            <DateField source="updateDate" />
                        </Tab>
                        <Tab label="hgw.metrics">
                            <NumberField label="hgw.reflect.size" source="size" />
                            <NumberField label="hgw.reflect.state.count" source="statesCount" />
                        </Tab>
                        {!!extendedProperties && extendedProperties.length > 0 && !!businessAccount &&
                            <Tab label={businessAccount.name}>
                                {extendedProperties.map(ep => ValueFieldFx({ extendedProperty: ep }))}
                            </Tab>
                        }
                    </TabbedShowLayout>
                </ShowView>
            }
        </ShowController>
    )
};

export default ReflectShow;