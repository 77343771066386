import * as React from "react";
import { useState, useEffect } from 'react';
import {
    ArrayField,
    BooleanField,
    ChipField,
    DateField,
    NumberField,
    ReferenceField,
    RichTextField,
    SelectField,
    ShowController,
    ShowView,
    SingleFieldList,
    Tab,
    TabbedShowLayout,
    TextField,
    UrlField,
    useDataProvider
} from 'react-admin';
import { CommissioningList } from './enums/commissioning';
import { NetworkMediaList } from './enums/networkMedias';
import { ValueFieldFx } from '../extendedproperty/fields/fieldFx';
import SmartLocalUnitModelField from './fields/modelField';
import PeriodField from '../crossCutting/fields/periodField';
import TagArrayField from '../tag/fields/arrayField';
import MomentField from '../crossCutting/fields/momentField';
import title from './title';
import contextStore from '../../provider/services/contextStore';
import AlarmBadge from "../reflect/fields/alarmBadge";
import ResourceStatusField from '../crossCutting/fields/resourceStatusField';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import PanToolIcon from '@material-ui/icons/PanTool';
import InfraReferenceField from '../infra/fields/reference';

const SmartLocalUnitTitle = title;

const SmartLocalUnitShow = props => {
    const businessAccount = contextStore.getBusinessAccount();
    const [extendedProperties, setExtendedProperties] = useState();
    const [loading, setLoading] = useState(true);
    const dataProvider = useDataProvider();
    useEffect(() => {
        dataProvider.getList('extendedproperty', { filter: { resourceType: 'smartLocalUnit' } })
            .then(({ data }) => {
                setExtendedProperties(data);
                setLoading(false);
            })
            .catch(error => {
                setExtendedProperties(null);
                setLoading(false);
            })
    }, []);
    if (loading) return null;
    return (
        <ShowController {...props}>
            {controllerProps =>
                <ShowView title={<SmartLocalUnitTitle />} {...props} {...controllerProps}>
                    <TabbedShowLayout>
                        <Tab label="hgw.summary">
                            <SmartLocalUnitModelField source="model" />
                            <TextField label="hgw.extkey" source="extKey" />
                            <TextField label="hgw.label" source="name" />
                            <ArrayField label="hgw.smartlocalunit.alarms" source="alarms">
                                <SingleFieldList>
                                    <AlarmBadge addLabel={false} />
                                </SingleFieldList>
                            </ArrayField>
                            <TextField label="hgw.smartlocalunit.wid" source="wid" />
                            <RichTextField label="hgw.description" source="description" />
                            <UrlField label="hgw.url" source="url" />
                            <ChipField label="hgw.code" source="code" />
                            <BooleanField label="hgw.smartlocalunit.autovalidresources" source="autoValidResources" valueLabelTrue="hgw.smartlocalunit.autovalidresourcesyes" valueLabelFalse="hgw.smartlocalunit.autovalidresourcesno" TrueIcon={AutorenewIcon} FalseIcon={PanToolIcon} />
                            <ResourceStatusField label="hgw.status.label" source="status" />
                        </Tab>
                        <Tab label="hgw.tagsinfras">
                            <ReferenceField label="hgw.infra.label" source="infraId" reference="infra">
                                <InfraReferenceField />
                            </ReferenceField>
                            <TagArrayField label="hgw.tag.many" source="tags" />
                            <NumberField label="hgw.location.latitude" source="location.latitude" />
                            <NumberField label="hgw.location.longitude" source="location.longitude" />
                        </Tab>
                        <Tab label="hgw.smartlocalunit.contract.label">
                            <PeriodField label="hgw.smartlocalunit.contract.exchangeperiod" source="contract.exchangePeriod" />
                            <PeriodField label="hgw.smartlocalunit.contract.fluxminperiod" source="contract.fluxMinPeriod" />
                            <PeriodField label="hgw.smartlocalunit.contract.heartBeatPeriod" source="contract.heartBeatPeriod" />
                            <NumberField label="hgw.smartlocalunit.contract.datamax" source="contract.dataMax" />
                            <NumberField label="hgw.smartlocalunit.contract.dailyEventMax" source="contract.dailyEventMax" />
                            <DateField label="hgw.smartlocalunit.contract.endDate" source="contract.endDate" />
                        </Tab>
                        <Tab label="hgw.metrics">
                            <NumberField label="hgw.flux.count" source="metrics.fluxCount" />
                            <NumberField label="hgw.flux.size" source="metrics.fluxSize" />
                            <NumberField label="hgw.flux.item.count" source="metrics.fluxItemCount" />
                            <NumberField label="hgw.reflect.count" source="metrics.reflectCount" />
                            <NumberField label="hgw.reflect.size" source="metrics.reflectSize" />
                            <NumberField label="hgw.journal.count" source="metrics.journalCount" />
                            <NumberField label="hgw.journal.size" source="metrics.journalSize" />
                        </Tab>
                        <Tab label="hgw.communication">
                            <MomentField label="hgw.smartlocalunit.nextcall" source="nextCall" />
                            <MomentField label="hgw.smartlocalunit.exchangedate" source="exchangeDate" />
                            <SelectField label="hgw.smartlocalunit.commissioning.label" source="commissioning" choices={CommissioningList} />
                            <SelectField label="hgw.smartlocalunit.networkmedia.label" source="networkMedia" choices={NetworkMediaList} />
                            <DateField label="hgw.creationdate" source="creationDate" />
                        </Tab>
                        {controllerProps.record && controllerProps.record.substitutionWid &&
                            <Tab label="hgw.smartlocalunit.maintenance">
                                <TextField source="substitutionWid" />
                            </Tab>
                        }
                        {controllerProps.record && controllerProps.record.minutesLate &&
                            <Tab label="hgw.smartlocalunit.late">
                                <PeriodField label="hgw.smartlocalunit.lateperiod" source="latePeriod" />
                                {controllerProps.record.isLate &&
                                    <MomentField label="hgw.smartlocalunit.latesince" source="nextCall" />
                                }
                            </Tab>
                        }
                        <Tab label="hgw.smartlocalunit.softhard">
                            <TextField label="hgw.smartlocalunit.softname" source="softName" />
                            <TextField label="hgw.smartlocalunit.softversion" source="softVersion" />
                            <DateField label="hgw.smartlocalunit.softdate" source="softDate" />
                            <TextField label="hgw.smartlocalunit.hard" source="hard" />
                        </Tab>
                        {!!extendedProperties && extendedProperties.length > 0 && !!businessAccount &&
                            <Tab label={businessAccount.name}>
                                {extendedProperties.map(ep => ValueFieldFx({ extendedProperty: ep }))}
                            </Tab>
                        }
                    </TabbedShowLayout>
                </ShowView>
            }
        </ShowController>
    )
};

export default SmartLocalUnitShow;