import * as React from "react";
import { useState, useEffect } from 'react';
import {
    ChipField,
    DateField,
    NumberField,
    ReferenceField,
    RichTextField,
    ShowController,
    ShowView,
    Tab,
    TabbedShowLayout,
    TextField,
    useDataProvider
} from 'react-admin';
import { ValueFieldFx } from '../extendedproperty/fields/fieldFx';
import LabelField from './fields/labelField';
import TagArrayField from '../tag/fields/arrayField';
import MomentField from '../crossCutting/fields/momentField';
import title from './title';
import contextStore from '../../provider/services/contextStore';
import ResourceStatusField from '../crossCutting/fields/resourceStatusField';
import InfraReferenceField from '../infra/fields/reference';
import SmartLocalUnitReferenceField from '../smartlocalunit/fields/referenceField';

const DeviceLoraTitle = title;

const SmartLocalUnitShow = props => {
    const businessAccount = contextStore.getBusinessAccount();
    const [extendedProperties, setExtendedProperties] = useState();
    const [loading, setLoading] = useState(true);
    const dataProvider = useDataProvider();
    useEffect(() => {
        dataProvider.getList('extendedproperty', { filter: { resourceType: 'smartLocalUnit' } })
            .then(({ data }) => {
                setExtendedProperties(data);
                setLoading(false);
            })
            .catch(error => {
                setExtendedProperties(null);
                setLoading(false);
            })
    }, []);
    if (loading) return null;
    return (
        <ShowController {...props}>
            {controllerProps =>
                <ShowView title={<DeviceLoraTitle />} {...props} {...controllerProps}>
                    <TabbedShowLayout>
                        <Tab label="hgw.summary">
                            <LabelField label="hgw.label" source="name" />
                            <RichTextField label="hgw.description" source="description" />
                            <TextField label="hgw.device.lora.deveui" source="devEUI" />
                            <ChipField label="hgw.device.lora.lorawanClass" source="lorawanClass" />
                            <TextField label="hgw.device.lora.model" source="model" />
                            <TextField label="hgw.device.lora.manufacturer" source="manufacturer" />
                            <ResourceStatusField label="hgw.status.label" source="status" />
                        </Tab>
                        <Tab label="hgw.tagsinfras">
                            <ReferenceField label="hgw.infra.label" source="infraId" reference="infra">
                                <InfraReferenceField />
                            </ReferenceField>
                            <TagArrayField label="hgw.tag.many" source="tags" />
                            <NumberField label="hgw.location.latitude" source="location.latitude" />
                            <NumberField label="hgw.location.longitude" source="location.longitude" />
                        </Tab>
                        <Tab label="hgw.communication">
                            <ChipField label="hgw.device.lora.commissioningMode" source="commissioningMode" />
                            <MomentField label="hgw.smartlocalunit.exchangedate" source="exchangeDate" />
                            <ReferenceField label="hgw.smartlocalunit.label" source="smartLocalUnitId" reference="smartlocalunit" link="show">
                                <SmartLocalUnitReferenceField />
                            </ReferenceField>
                            <DateField label="hgw.creationdate" source="creationDate" />
                            <DateField label="hgw.updatedate" source="updateDate" />
                        </Tab>
                        {!!extendedProperties && extendedProperties.length > 0 && !!businessAccount &&
                            <Tab label={businessAccount.name}>
                                {extendedProperties.map(ep => ValueFieldFx({ extendedProperty: ep }))}
                            </Tab>
                        }
                    </TabbedShowLayout>
                </ShowView>
            }
        </ShowController>
    )
};

export default SmartLocalUnitShow;