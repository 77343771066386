import httpClient from './services/httpClient';
import contextStore from '../provider/services/contextStore';
import ValueTypeEnums from '../resources/extendedproperty/valueType';

const root = () => `wac/${contextStore.getWac()}/extendedproperty`;

function getCacheKey() {
    return `${contextStore.getWac()}_extendedproperty`;
}

function getCached() {
    const cacheKey = getCacheKey();
    var extendedProperty = sessionStorage.getItem(cacheKey);
    return !extendedProperty ?
        httpClient(root()).then(({ json }) => {
            sessionStorage.setItem(cacheKey, JSON.stringify(json));
            return json;
        }) :
        Promise.resolve(JSON.parse(extendedProperty));
}

function clearCached() {
    const cacheKey = getCacheKey();
    sessionStorage.removeItem(cacheKey);
}

function parse(data) {
    if (!!data.list) {
        var obj = {};
        data.list.forEach(l => obj[l.key] = l.value);
        data.list = obj;
    }

    if (data.resourceType === 'shared') data.resourceType = null;
}

export default {

    map: (json) => {
        if (!!json.list) {

            // list key returned by Highway is always string.
            // need to convert to true type to avoid value matching with resources extended properties
            const valueType = ValueTypeEnums.find(x => x.id === json.valueType);
            json.list = Object.keys(json.list).map(key => {
                return { key: valueType?.format(key) ?? key, value: json.list[key] };
            });

            if (!json.resourceType) {
                json.resourceType = 'shared';
            }
        };

        return json;
    },

    getList: (params) => {
        return getCached().then(
            (extendedProperties) => {
                if (params.resourceType) {
                    extendedProperties = extendedProperties.filter(j => j.resourceType === 'shared' || j.resourceType === params.resourceType);
                }
                return {
                    data: extendedProperties,
                    count: extendedProperties.length
                }
            })
    },

    getOne: (params) => {
        return getCached().then(
            (extendedProperties) => {
                return extendedProperties.find(t => params.id === t.id.toString());
            })
    },

    getMany: (params) => {
        return getCached().then(
            (extendedProperties) => {
                return extendedProperties.filter(t => params.ids.indexOf(t.id.toString()) !== -1);
            })
    },

    update: (params) => {
        parse(params.data);
        return httpClient(root(), {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => {
            clearCached();
            return json;
        })
    },

    create: (params) => {
        parse(params.data);
        return httpClient(root(), {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => {
            clearCached();
            return json;
        })
    },

    delete: (params) => {
        return httpClient(`${root()}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => {
            clearCached();
            return json;
        })
    }
};



