import httpClient from './services/httpClient';
import requestUtils from './factory/requestUtils';
import contextStore from '../provider/services/contextStore';

const root = () => 'core/businessaccount';

const getFriendly = () => {
    var org = contextStore.getOrganization();
    return !org ? Promise.resolve([]) :
        httpClient(`${root()}/organization/${org.code}?pageSize=1000`).then(
            ({ json }) => json.data)
}

export default {

    map: (json) => {
        json.isProvisionned = json.status === 2;
        return json
    },

    getList: (params) => {
        if (!!params && params.friendly) {
            return getFriendly().then(businessaccounts => {
                return {
                    data: businessaccounts,
                    count: businessaccounts.length
                }
            })
        }

        const query = requestUtils.buildQuery(params);
        return httpClient(`${root()}/my?${query}`).then(({ json }) => json);
    },

    getOne: (params) => {
        if (params.id) {
            return httpClient(`${root()}/${params.id}`).then(({ json }) => json);
        } else if (params.wac) {
            return httpClient(`${root()}/wac/${params.wac}`).then(({ json }) => json);
        }
    },

    getMany: (params) => {
        return getFriendly().then(
            businessaccounts => {
                return businessaccounts.filter(t => params.ids.indexOf(t.id) !== -1);
            })
    },

    update: (params) => {
        const puts = [];
        var paramsAddress = { data: params.data.address, previousData: params.previousData.address };
        var dataAddress = requestUtils.getDatas(paramsAddress, ['phone', 'fax', 'street', 'postalCode', 'city', 'countryId']);
        var dataMain = requestUtils.getDatas(params, ['wac', 'name', 'url', 'description', 'autoValidResources']);
        if (dataMain || dataAddress) {
            if (!dataMain) dataMain = { wac: params.previousData.wac };
            dataMain.address = dataAddress;
            puts.push({
                url: root(),
                method: 'PUT',
                data: JSON.stringify(dataMain)
            });
        }

        if (!!params.data.pictureraw) {
            let formData = new FormData();
            formData.append('wac', params.previousData.wac);
            formData.append('picture', params.data.pictureraw.rawFile);
            puts.push({
                url: `${root()}/picture`,
                method: 'POST',
                data: formData
            });
        }

        var dataOwner = requestUtils.getDatas(params, ['wac', 'ownerCode']);
        if (dataOwner) {
            puts.push({
                url: `${root()}/owner`,
                method: 'PATCH',
                data: JSON.stringify({ wac: dataOwner.wac, code: dataOwner.ownerCode })
            });
        }

        var dataOperator = requestUtils.getDatas(params, ['wac', 'operatorCode']);
        if (dataOperator) {
            puts.push({
                url: `${root()}/operator`,
                method: 'PATCH',
                data: JSON.stringify({ wac: dataOperator.wac, code: dataOperator.operatorCode })
            });
        }

        return requestUtils.sendSynchrone(puts);
    },

    create: (params) => {
        var hasWac = params.data.hasOwnProperty("wac");
        var hasId = params.data.hasOwnProperty("id");
        if (hasId || hasWac) {
            // join businessAccount by id or by wac
            return (hasWac ?
                Promise.resolve(params.data.wac) :
                getFriendly().then(businessAccounts => {
                    var businessAccount = businessAccounts.find(t => t.id === params.data.id);
                    return !businessAccount ?
                        Promise.reject(`id ${params.data.id} is not in your friendly businessAccount`) : // should not occur ...
                        businessAccount.wac;
                })
            ).then(wac => {
                return httpClient(`${root()}/join`, {
                    method: 'POST',
                    body: JSON.stringify({ wac: wac })
                }).then(({ json }) => {
                    return {
                        id: json.accountId,
                        wac: wac
                    }
                })
            })
        } else {
            // new businessAccount
            var dataMain = requestUtils.getDatas(params, ['name', 'url', 'description', 'address', 'autoValidResources', 'ownerCode', 'operatorCode']);
            return httpClient(root(), {
                method: 'POST',
                body: JSON.stringify(dataMain),
            }).then(({ json }) => {
                if (!params.data.pictureraw) return json;

                // create before uploading picture: need organization code
                let formData = new FormData();
                formData.append('wac', json.wac);
                formData.append('picture', params.data.pictureraw.rawFile);
                return httpClient(`${root()}/picture`, {
                    method: 'POST',
                    body: formData
                }).then(({ json }) => json)
            })
        }
    },

    delete: (params) => {
        return httpClient(`${root()}/${params.id}`).then(({ json }) => {
            var wac = json.wac;
            return httpClient(`${root()}/${wac}`, {
                method: 'DELETE',
            }).then(({ json }) => json)
        });
    },

    deleteMany: (params) => {
        //const query = {
        //    filter: JSON.stringify({ id: params.ids }),
        //};
        //return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
        //    method: 'DELETE',
        //    body: JSON.stringify(params.data),
        //}).then(({ json }) => ({ data: json }));
    },
};
