import * as React from 'react';
import { useState } from 'react';
import { Avatar, Button, Slide, makeStyles, Menu  } from '@material-ui/core';
import BusinessAccountSelect from '../resources/businessaccount/select';
import BusinessAccountIcon from '../resources/businessaccount/pictures/businessAccount64.png';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ReactComponent as ArrowSvgIcon} from '../logos/right-arrow.svg.svg'

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    avatar: {
        height: 32,
        width: 32,
    }
}));

const BusinessAccountButton = ({ businessaccount, onSelected }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const currentBusinessAccount = businessaccount;
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClickOpen = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSelected = (businessAccount) => {
        setOpen(false);
        onSelected(businessAccount);
    }

    return (
        <>
            <Button
                onClick={handleClickOpen}
                color='inherit'
                startIcon={
                    <Avatar
                        src={currentBusinessAccount && currentBusinessAccount.picture ? `picture/${currentBusinessAccount.picture}/32` : BusinessAccountIcon}
                        className={classes.avatar}
                    />
                }
                endIcon={
                    <ArrowSvgIcon />} >{currentBusinessAccount?.name}
            </Button>

            <Menu
                id='business-selector-menu'
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                
                <BusinessAccountSelect selected={currentBusinessAccount} onSelected={handleSelected} />
            </Menu>
        </>
    );
};

export default BusinessAccountButton;
