import * as React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import InfraTypeEnums from '../enums/type';

const useStyles = makeStyles({
    avatar: {
        height: 24,
        width: 24,
    },
});

const ReferenceField = ({ record }) => {
    const classes = useStyles();
    if (!record.id) return (<div />)
    var type = InfraTypeEnums[record.type];
    return (
        <Chip icon={<Avatar className={classes.avatar} src={type?.logo} />} label={record.name} key={record} />
    )
}

ReferenceField.defaultProps = { addLabel: true }

export default ReferenceField;
