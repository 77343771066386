const CriticityEnums = {
    veryHigh: { key: "VeryHigh", name: 'hgw.reflect.criticity.veryhigh' },
    high: { key: "High", name: 'hgw.reflect.criticity.high' },
    normal: { key: "Normal", name: 'hgw.reflect.criticity.normal' },
    low: { key: "Low", name: 'hgw.reflect.criticity.low' },
    veryLow: { key: "VeryLow", name: 'hgw.reflect.criticity.verylow' },
    none: { key: "None", name: 'hgw.reflect.criticity.none' }
}

export const CriticityList = Object.entries(CriticityEnums).map(([id, obj]) => {
    obj.id = id;
    return obj
})

export default CriticityEnums;