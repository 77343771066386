// In theme.js
import { defaultTheme } from "react-admin";
//import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import { createTheme } from '@material-ui/core/styles';
import merge from "lodash/merge";

const discoveryTheme = {
    ...defaultTheme,
    palette: {
        primary: {
            main: '#172B4D'
        },
        secondary: {
            main: '#F1F3F4',
            contrastText: '#172B4D',
        },
    },
    typography: {
        fontFamily: [
            'Regular',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif'
        ].join(','),
    }
}

export const theme = createTheme(
    merge({}, defaultTheme, discoveryTheme)
);