import httpClient from './services/httpClient';
import contextStore from '../provider/services/contextStore';
import requestUtils from './factory/requestUtils';

const root = () => `wac/${contextStore.getWac()}/tag`;

export default {

    map: (json) => { return json; },

    getList: (params) => {
        const query = requestUtils.buildQuery(params);
        return httpClient(`${root()}?${query}`).then(({ json }) => json)
    },

    getOne: (params) => {
        return httpClient(`${root()}/${params.id}`).then(({ json }) => json)
    },

    getMany: (params) => {
        var areIntegers = params.ids.every(id => Number.isInteger(id));
        const query = params.ids.map((id) => `${areIntegers ? "ids" : "labels"}=${id}`).join("&");
        return httpClient(`${root()}/many?${query}`).then(({ json }) => json)
    },

    update: (params) => {
        return httpClient(root(), {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => json)
    },

    create: (params) => {
        return httpClient(root(), {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => json)
    },

    delete: (params) => {
        return httpClient(`${root()}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => json)
    }
};