import * as React from 'react';
import { Fragment } from 'react';
import { useMediaQuery } from '@material-ui/core';
import {
    AutocompleteArrayInput,
    BooleanInput,
    BulkDeleteButton,
    Datagrid,
    Filter,
    List,
    ReferenceArrayInput,
    ReferenceField,
    SelectInput,
    SimpleList,
    TextField,
    TextInput,
} from 'react-admin';
import FluxLevelField from './fields/level';
import TagArrayField from '../tag/fields/arrayField';
import PeriodField from '../crossCutting/fields/periodField';
import MomentField from '../crossCutting/fields/momentField';
import ResourceStatusField from '../crossCutting/fields/resourceStatusField';
import InfraReferenceField from '../infra/fields/reference';
import SmartLocalUnitReferenceField from '../smartlocalunit/fields/referenceField';
import BulkValidateButton from '../crossCutting/buttons/BulkValidateButton';
import LevelEnum from './enums/level';

const levelChoices = Object.keys(LevelEnum).map(l => ({ id: l }));

const FluxListBulkActionButtons = props => {
    return <Fragment>
        <BulkValidateButton {...props} />
        <BulkDeleteButton {...props} />
    </Fragment>
};

const FluxFilter = (props) => (
    <Filter {...props}>
        <TextInput label="hgw.search" source="search" alwaysOn />
        <ReferenceArrayInput label="hgw.scope.many" source="scopeIds" reference="scope" alwaysOn filterToQuery={searchText => ({ search: searchText })} perPage={25}>
            <AutocompleteArrayInput optionText="label" optionValue="id" />
        </ReferenceArrayInput>
        <ReferenceArrayInput label="hgw.tag.many" source="tags" reference="tag" filterToQuery={searchText => ({ search: searchText })} perPage={25}>
            <AutocompleteArrayInput optionText="label" optionValue="label" />
        </ReferenceArrayInput>
        <ReferenceArrayInput label="hgw.infra.many" source="infraIds" reference="infra" filterToQuery={searchText => ({ search: searchText })} perPage={25} alwaysOn>
            <AutocompleteArrayInput optionText="name" optionValue="id" />
        </ReferenceArrayInput>
        <SelectInput label="hgw.flux.origin.label" source="origin" choices={levelChoices} optionText={<FluxLevelField />} allowEmpty alwaysOn />
        <BooleanInput label="hgw.status.tovalidate" source="toValidate" alwaysOn />
        <ReferenceArrayInput label="hgw.smartlocalunit.many" source="smartLocalUnitIds" reference="smartlocalunit" filterToQuery={searchText => ({ search: searchText })} perPage={25}>
            <AutocompleteArrayInput optionText="name" optionValue="id" />
        </ReferenceArrayInput>
    </Filter>
);

export const FluxList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List
            title="hgw.flux.many"
            bulkActionButtons={<FluxListBulkActionButtons />}
            filters={<FluxFilter />} {...props}>
            {isSmall ?
                (<SimpleList
                    linkType="show"
                    leftIcon={record => <FluxLevelField record={record} source="origin" />}
                    primaryText={record => record.name}
                    secondaryText={record => record.description ?? '-'}
                    tertiaryText={record => <MomentField record={record} source="lastPointDate" />}
                />) :
                (<Datagrid rowClick="show">
                    <FluxLevelField label="hgw.flux.origin.label" source="origin" addLabel={false} />
                    <TextField label="hgw.label" source="name" />
                    <PeriodField label="hgw.period.label" source="period" />
                    <TagArrayField label="hgw.tag.many" source="tags" sortable={false} />
                    <ReferenceField label="hgw.infra.label" source="infraId" reference="infra" sortable={false}>
                        <InfraReferenceField />
                    </ReferenceField>
                    <ReferenceField label="hgw.smartlocalunit.label" source="smartLocalUnitId" reference="smartlocalunit" sortable={false} link="show">
                        <SmartLocalUnitReferenceField />
                    </ReferenceField>
                    <MomentField label="hgw.flux.point.firstdate" source="firstPointDate" />
                    <MomentField label="hgw.flux.point.lastdate" source="lastPointDate" />
                    <ResourceStatusField label="hgw.status.label" source="status" />
                </Datagrid>)}
        </List>
    )
};

export default FluxList;
