import { HttpError } from 'react-admin';

const fetchJson = async (url, options = {}) => {
    const requestHeaders = (options.headers ||
        new Headers({
            Accept: 'application/json',
        })
    );

    if (!requestHeaders.has('Content-Type') &&
        !(options && options.body && options.body instanceof FormData)) {
        requestHeaders.set('Content-Type', 'application/json');
    }

    const response = await fetch(url, { ...options, headers: requestHeaders })
    const text = await response.text();

    let json;
    try {
        json = JSON.parse(text);
    }
    catch (e) {
        // not json, no big deal
    }

    if (response.status >= 200 && response.status < 300) {
        return Promise.resolve({
            status: response.status,
            headers: response.headers,
            body: text,
            json: json
        });
    }

    const status = !json || !json.status ? response.status : json.status;
    return Promise.reject(new HttpError(json?.detail || json?.title || json?.instance || json?.type || 'An error occur.Try it again.', status, json));
};

export default fetchJson;