import * as React from "react";
import {
    ArrayField,
    Button,
    ChipField,
    DateField,
    Datagrid,
    NumberField,
    ReferenceField,
    ReferenceManyField,
    RichTextField,
    ShowController,
    ShowView,
    Tab,
    TabbedShowLayout,
    TextField,
    useRedirect,
    useTranslate,
} from 'react-admin';
import FluxItemLevelField from './fields/level';
import FluxReferenceField from '../flux/fields/reference';
import ActivityTypeField from '../activity/fields/type';
import MomentField from '../crossCutting/fields/momentField';
import title from './title';
import CreateActivityIcon from '@material-ui/icons/AddCircle';
import ViewActivityIcon from '@material-ui/icons/Visibility';

const FluxItemTitle = title;

export const FluxItemShow = props => {
    const redirect = useRedirect();
    const translate = useTranslate();
    return (
        <ShowController {...props}>
            {controllerProps =>
                <ShowView title={<FluxItemTitle />} {...props} {...controllerProps} translate=''>
                    <TabbedShowLayout>
                        <Tab label="hgw.summary">
                            <FluxItemLevelField source="origin" />
                            <ReferenceField source="fluxId" reference="flux" link="show">
                                <FluxReferenceField />
                            </ReferenceField>
                            <TextField label="hgw.code" source="label" />
                            <TextField label="hgw.label" source="name" />
                            <RichTextField label="hgw.description" source="description" />
                        </Tab>
                        <Tab label="hgw.wum.label">
                            <ReferenceField label="hgw.wum.unit" source="wum.unit" reference="wumunit">
                                <ChipField source="name" />
                            </ReferenceField>
                            <ReferenceField label="hgw.wum.type" source="wum.type" reference="wumtype">
                                <ChipField source="name" />
                            </ReferenceField>
                            <ReferenceField label="hgw.wum.function" source="wum.function" reference="wumfunction">
                                <ChipField source="name" />
                            </ReferenceField>
                            <ReferenceField label="hgw.wum.material" source="wum.material" reference="wummaterial">
                                <ChipField source="name" />
                            </ReferenceField>
                        </Tab>
                        <Tab label="hgw.activity.many">
                            {controllerProps.record && controllerProps.record.origin !== 'acquiring' ?
                                <ActivityTypeField label="hgw.activity.in" source="activity.type" />

                                : null}
                            {controllerProps.record && controllerProps.record.origin !== 'acquiring' && !controllerProps.record.activity ?
                                <Button
                                    onClick={() => {
                                        redirect('/activity/create', null, null, null, { record: { targetFluxItemId: controllerProps.record.id } });
                                    }}
                                    label="hgw.activity.create" >
                                    <CreateActivityIcon />
                                </Button> : null
                            }

                            {controllerProps.record && controllerProps.record.origin !== 'acquiring' && controllerProps.record.activity ?
                                <Button
                                    onClick={() => {
                                        redirect(`/activity/${controllerProps.record.activity.id}/show/1`);
                                    }}
                                    label="hgw.activity.view" >
                                    <ViewActivityIcon />
                                </Button> : null
                            }
                            <ReferenceManyField label="hgw.activity.out" reference="activity" target="sourceFluxItemId" >
                                <Datagrid rowClick="show" empty={translate('hgw.activity.undefined')}>
                                    <ActivityTypeField label="hgw.activity.label" source="type" />
                                    <TextField label="" source="name" />
                                </Datagrid >
                            </ReferenceManyField>
                        </Tab>
                        <Tab label="hgw.flux.point.many">
                            <MomentField label="hgw.flux.point.firstdate" source="firstPointDate" />
                            <MomentField label="hgw.flux.point.lastdate" source="lastPointDate" />
                            <ArrayField label="hgw.flux.point.lastmany" source="points">
                                <Datagrid>
                                    <DateField label="hgw.flux.point.lastdate" source="date" showTime={true} />
                                    <NumberField label="hgw.flux.point.lastvalue" source="value" />
                                </Datagrid>
                            </ArrayField>
                        </Tab>
                    </TabbedShowLayout>

                </ShowView>
            }
        </ShowController >
    )
};

export default FluxItemShow;
