import * as React from "react";
import Chip from '@material-ui/core/Chip';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';

const ReferenceField = ({ record }) => {
    return (
        <Chip icon={<PermIdentityIcon />} label={record.name} key={record} />
    )
}

ReferenceField.defaultProps = { addLabel: true }

export default ReferenceField;