import * as React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Check } from '@material-ui/icons';
import { BulkUpdateButton } from 'react-admin';

const VALIDATE = { validate: true };

const useStyles = makeStyles({
    button: {
        backgroundColor: 'orange',
        color: 'black',
        marginLeft: 8,
    }
});

const BulkValidateButton = props => {
    const classes = useStyles();
    return <BulkUpdateButton
        {...props}
        label="hgw.validate"
        className={classes.button}
        data={VALIDATE}
        icon={<Check />} />
};

export default BulkValidateButton;