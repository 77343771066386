import BusinessAccountList from './list';
import BusinessAccountShow from './show';
import BusinessAccountCreate from './create';
import BusinessAccountEdit from './edit';
import BusinessAccountIcon from './icon';

export default {
    list: BusinessAccountList,
    show: BusinessAccountShow,
    create: BusinessAccountCreate,
    edit: BusinessAccountEdit,
    icon: BusinessAccountIcon
};