import * as React from "react";
import {
    ArrayInput,
    BooleanInput,
    Edit,
    FormTab,
    SelectField,
    SimpleFormIterator,
    TabbedForm,
    TextInput
} from 'react-admin';
import title from './title';
import ValueTypeEnums from './valueType';
import ResourceTypeField from './fields/resourceTypeField';

const ExtendedPropertyTitle = title;

const ExtendedPropertyEdit = props => (
    <Edit title={<ExtendedPropertyTitle />} {...props}>
        <TabbedForm>
            <FormTab label="hgw.summary">
                <TextInput disabled source="id" />
                <SelectField label="hgw.extendedproperty.valuetype.label" source="valueType" choices={ValueTypeEnums} />
                <ResourceTypeField label="hgw.extendedproperty.resourcetype" source="resourceType" />
                <TextInput disabled label="hgw.label" source="label" />
                <TextInput label="hgw.description" multiline source="description" />
                <BooleanInput label="hgw.extendedproperty.nullable" source="nullable" />
            </FormTab>
            <FormTab label="hgw.extendedproperty.list.label">
                <ArrayInput label=""
                    source="list" >
                    <SimpleFormIterator>
                        <TextInput label="hgw.extendedproperty.list.key" source="key" />
                        <TextInput label="hgw.extendedproperty.list.value" source="value" />
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
        </TabbedForm>
    </Edit>
);

export default ExtendedPropertyEdit;