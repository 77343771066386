import * as React from "react";
import {
    BooleanField,
    ChipField,
    ReferenceArrayField,
    SelectField,
    Show,
    SingleFieldList,
    Tab,
    TabbedShowLayout,
    TextField,
    useTranslate
} from 'react-admin';
import title from './title';
import TagArrayField from '../tag/fields/arrayField';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import AlarmBadge from '../reflect/fields/alarmBadge';
import { CriticityList } from '../reflect/enums/criticity';

const ScopeTitle = title;

export const ScopeShow = props => {
    const translate = useTranslate();
    return (
        <Show title={<ScopeTitle />} {...props} component="div">
            <TabbedShowLayout>
                <Tab label="hgw.summary">
                    <TextField label="hgw.label" source="label" />
                    <SelectField label="hgw.scope.criticitydiffusion" source="criticityDiffusion" choices={CriticityList} optionText={<AlarmBadge />} allowEmpty emptyText={translate("hgw.scope.criticitydiffusionempty")} />
                </Tab>
                <Tab label="hgw.scope.query">
                    <TextField label="hgw.scope.query" source="description" />
                    <TagArrayField label="hgw.tag.many" source="query.tags" />
                    <ReferenceArrayField label="hgw.infra.many" source="query.infraIds" reference="infra">
                        <SingleFieldList>
                            <ChipField source="name" />
                        </SingleFieldList>
                    </ReferenceArrayField>
                    <BooleanField label="hgw.infra.includechildren" source="query.includeInfraChildren" valueLabelTrue="hgw.infra.includechildren" valueLabelFalse="hgw.infra.notincludechildren" TrueIcon={AccountTreeIcon} FalseIcon={NotInterestedIcon} />
                </Tab>
            </TabbedShowLayout>
        </Show>
    )
};

export default ScopeShow;