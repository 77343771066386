import * as React from "react";
import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/es';
import recordSourceResolver from './recordSourceResolver';

const momentField = ({ record, source, calendar }) => {
    var locale = window.navigator.userLanguage || window.navigator.language;
    moment.locale(locale);
    //moment.locale('fr')
    const datesource = recordSourceResolver({ record, source });
    if (typeof datesource === 'undefined' || datesource === null) {
        return <div />
    } else {
        return (
            <>
                <span>{!calendar ? moment(datesource).fromNow() : moment(datesource).calendar()}</span>
            </>
        )
    }
}
momentField.defaultProps = { addLabel: true }

export default momentField;