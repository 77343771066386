const ValueTypeEnums = [
    {
        id: 'bool',
        name: 'hgw.extendedproperty.valuetype.bool',
        format: (value) => {
            return value == 'true';
        }
    },
    {
        id: 'int',
        name: 'hgw.extendedproperty.valuetype.int',
        format: (value) => {
            return parseInt(value, 10);
        }
    },
    {
        id: 'string',
        name: 'hgw.extendedproperty.valuetype.string',
        format: (value) => {
            return value;
        }
    },
    {
        id: 'decimal',
        name: 'hgw.extendedproperty.valuetype.decimal',
        format: (value) => {
            return parseFloat(value);
        }
    },
    {
        id: 'date',
        name: 'hgw.extendedproperty.valuetype.date',
        format: (value) => {
            return new Date(value).toISOString();
        }
    }
]

export default ValueTypeEnums;