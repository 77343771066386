const TypeEnums = {
    enterprise: { name: 'hgw.organization.type.enterprise' },
    association: { name: 'hgw.organization.type.association' },
    collectivity: { name: 'hgw.organization.type.collectivity' },
    other: { name: 'hgw.other' }
}

export const TypeList = Object.entries(TypeEnums).map(([id, obj]) => {
    obj.id = id;
    return obj
})

export default TypeEnums;