import * as React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Check, ErrorOutline } from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import {
    useNotify,
    useRefresh,
    useTranslate,
    useUpdate,
} from 'react-admin';

const useStyles = makeStyles({
    button: {
        backgroundColor: 'orange',
        color: 'black',
        marginLeft: 8,
    }
});

const ValidateButton = ({ record, source, resource, toValidateValue, validatedValue }) => {
    const classes = useStyles();
    const tranlate = useTranslate();
    const refresh = useRefresh();
    const notify = useNotify();

    const isValidated = !record ? false : record[source] === validatedValue;
    const isToValidate = !record ? false : record[source] === toValidateValue;

    const [update] = useUpdate(
        resource,
        record?.id,
        { id: record.id, validated: !isValidated },
        { id: record.id, validated: isValidated },
        {
            onSuccess: () => {
                refresh();
                notify(isValidated ? `hgw.${resource}.invalidated` : `hgw.${resource}.validated`, { type: 'success' });
            },
            onFailure: error => notify(`hgw.${resource}.validateerror`, { type: 'warning' }),
        });

    return !isValidated && !isToValidate ? null :
        <Button
            startIcon={isValidated ? <ErrorOutline /> : <Check />}
            className={classes.button}
            onClick={update}>
            {tranlate(isValidated ? "hgw.invalidate" : "hgw.validate")}
        </Button>
};

ValidateButton.defaultProps = { source: 'status', toValidateValue: 'toValidate', validatedValue: 'validated' }

export default ValidateButton;