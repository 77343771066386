import * as React from "react";
import { useMediaQuery } from '@material-ui/core';
import {
    List,
    Datagrid,
    TextField,
    BooleanField,
    SimpleList
} from 'react-admin';
import LabelField from './fields/label';
import RoleIcon from './icon';

const RoleList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List {...props}>
            {isSmall ?
                (<SimpleList
                    linkType="show"
                    leftIcon={() => <RoleIcon />}
                    primaryText={record => record.name}
                    secondaryText={record => record.description}
                />) :
                (<Datagrid rowClick="show">
                    <LabelField source="name" />
                    <TextField source="description" />
                    <BooleanField label="Administrateur" source="isAdmin" />
                </Datagrid>)}
        </List>
    )
};

export default RoleList;