import * as React from "react";
import { Avatar, makeStyles } from '@material-ui/core';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';

const useStyles = makeStyles({
    avatar: {
        height: 32,
        width: 32,
    }
});

const Rolecon = props => {
    const classes = useStyles();
    return (
        <Avatar className={classes.avatar} >
            <PermIdentityIcon />
        </Avatar>
    )
}

export default Rolecon;