import * as React from "react";
import { useMediaQuery } from '@material-ui/core';
import {
    List,
    Filter,
    Datagrid,
    BooleanField,
    TextInput,
    SimpleList,
    BooleanInput,
    useTranslate
} from 'react-admin';
import LabelField from './fields/label';
import TagIcon from './icon';

const TagFilter = (props) => (
    <Filter {...props}>
        <TextInput label="hgw.search" source="search" alwaysOn />
        <BooleanInput label="Global" source="isGlobal" defaultValue="true" />
    </Filter>
);

const TagList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const translate = useTranslate();
    return (
        <List filters={<TagFilter />} {...props}>
            {isSmall ?
                (<SimpleList
                    linkType="edit"
                    leftIcon={record => <TagIcon />}
                    primaryText={record => record.label}
                    secondaryText={record => record.isGlobal ? translate("hgw.tag.isglobal"):null}
                />) :
                (<Datagrid rowClick="edit">
                    <LabelField label="hgw.label" source="label" />
                    <BooleanField label="hgw.tag.isglobaldetail" source="isGlobal" />
                </Datagrid>)}
        </List>
    )
};

export default TagList;