import * as React from "react";
import {
    Create,
    CreateButton,
    SaveButton,
    SimpleForm,
    Toolbar,
    TopToolbar,
    useRedirect,
    useTranslate,
    useNotify
} from 'react-admin';
import { Box, Typography } from '@material-ui/core';
import IconJoin from '@material-ui/icons/SystemUpdateAlt';
import SelectOrganization from "./inputs/select";
import contextStore from "../../provider/services/contextStore";

const OrganizationTitle = () => {
    const translate = useTranslate();
    return <span>{translate('hgw.organization.define')}</span>;
};

const OrganizationDefineToolbar = props => (
    <Toolbar {...props}>
        <SaveButton icon={<IconJoin />} label="hgw.join" />
    </Toolbar>
);

const OrganizationDefineActions = ({ props, basePath, data, resource }) => {
    return (
        <TopToolbar>
            <CreateButton basePath='/organization' />
        </TopToolbar>
    )
};

const Aside = () => {
    const translate = useTranslate();
    return (
        <div style={{ width: 200, margin: '1em' }}>
            <Typography variant="h5">{translate('hgw.help')}</Typography>
            <Typography variant="overline" display="block" >
                {translate('hgw.organization.select')}
            </Typography>
            <Box textAlign="center" fontWeight="fontWeightBold">
                {translate('hgw.or')}
            </Box>
            <Typography variant="overline" display="block" >
                {translate('hgw.organization.setcode')}
            </Typography>
            <Box textAlign="center" fontWeight="fontWeightBold">
                {translate('hgw.or')}
            </Box>
            <Typography variant="overline" display="block" >
                {translate('hgw.organization.create')}
            </Typography>
        </div>
    )
};

const OrganizationDefine = props => {
    const redirect = useRedirect();
    const notify = useNotify();
    const onSuccess = ({ data }) => {
        notify('hgw.organization.definesuccessfully');
        contextStore.setOrganization(data);
        redirect('/organization/show');
    };

    return (
        <Create title={<OrganizationTitle />} onSuccess={onSuccess} aside={<Aside />} actions={<OrganizationDefineActions />} {...props} >
            <SimpleForm toolbar={<OrganizationDefineToolbar />}>
                <SelectOrganization label="hgw.organization.your" source="code" />
            </SimpleForm>
        </Create>
    )
};

export default OrganizationDefine;
