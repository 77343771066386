import * as React from "react";
import { makeStyles } from '@material-ui/core/styles';
import TagIcon from '../icon';

const useStyles = makeStyles({
    div: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap'
    }
});

const LabelField = ({ record, source }) => {
    const classes = useStyles();
    return (
        <>
            <div className={classes.div}>
                <TagIcon />
                <span>&nbsp;{record[source]}</span>
            </div>
        </>
    )
}

LabelField.defaultProps = { addLabel: true }

export default LabelField;