import httpClient from './services/httpClient';
import requestUtils from './factory/requestUtils';
import contextStore from './services/contextStore';

const root = () => 'core/organization';

const getMy = () => {
    return httpClient(`${root()}/my`).then(({ json }) => json);
}

const getFriendly = () => {
    return httpClient(`${root()}/friendly`).then(({ json }) => json)
}

export default {

    map: (json) => { return json },

    getList: (params) => {
        return getFriendly().then(organizations => {
            return {
                data: organizations,
                count: organizations.length
            }
        })
    },

    getMany: (params) => {
        var areCodes = !params.ids.every(id => Number.isInteger(id));
        return getFriendly().then(
            organizations => {
                return areCodes ?
                    organizations.filter(t => params.ids.indexOf(t.code) !== -1) :
                    organizations.filter(t => params.ids.indexOf(t.id) !== -1);
            })
    },

    getOne: (params) => {
        return httpClient(`${root()}/${params.id}`)
            .then(({ json }) => json)
            .catch((reason) => {
                // membership is not validated just show short
                if (contextStore.getOrganization().id !== params.id) {
                    return Promise.reject(reason);
                }

                return getMy().then(organization => {
                    organization.isMemberOnGoing = true;
                    return organization;
                });
            });
    },

    create: (params) => {
        if (params.data.hasOwnProperty("id") || params.data.hasOwnProperty("code")) {
            // join organization by id (friendly) or by code
            return (params.data.hasOwnProperty("code") ?
                Promise.resolve(params.data.code) :
                getFriendly().then(organizations => {
                    var organization = organizations.find(t => t.id === params.data.id);
                    return !organization ?
                        Promise.reject(`id ${params.data.id} is not in your friendly organizations`) : // should not occur ...
                        organization.code;
                })
            ).then(code => {
                return httpClient(`${root()}/join`, {
                    method: 'POST',
                    body: JSON.stringify({ code: code })
                }).then(() => {
                    return getMy();
                })
            })
        } else {
            // new organization
            var dataMain = requestUtils.getDatas(params, ['name', 'url', 'description', 'siret', 'vatin', 'otherIn', 'businessSector', 'legalName', 'type', 'address']);
            return httpClient(root(), {
                method: 'POST',
                body: JSON.stringify(dataMain)
            }).then(({ json }) => {
                if (!params.data.pictureraw) return json;

                // create before uploading picture: need organization code
                let formData = new FormData();
                formData.append('code', json.code);
                formData.append('picture', params.data.pictureraw.rawFile);
                return httpClient(`${root()}/picture`, {
                    method: 'POST',
                    body: formData
                }).then(({ json }) => json)
            })
        }
    },

    update: (params) => {
        const puts = [];
        var paramsAddress = { data: params.data.address, previousData: params.previousData.address };
        var dataAddress = requestUtils.getDatas(paramsAddress, ['phone', 'fax', 'street', 'postalCode', 'city', 'countryId']);
        var dataMain = requestUtils.getDatas(params, ['code', 'name', 'url', 'description', 'siret', 'vatin', 'otherIn', 'businessSector', 'legalName', 'type']);
        if (dataMain || dataAddress) {
            if (!dataMain) dataMain = { code: params.previousData.code };
            dataMain.address = dataAddress;
            puts.push({
                url: root(),
                method: 'PUT',
                data: JSON.stringify(dataMain)
            });
        }

        if (!!params.data.pictureraw) {
            let formData = new FormData();
            formData.append('code', params.previousData.code);
            formData.append('picture', params.data.pictureraw.rawFile);
            puts.push({
                url: `${root()}/picture`,
                method: 'POST',
                data: formData
            });
        }

        return requestUtils.sendSynchrone(puts);
    },

    delete: (params) => {
        return httpClient(`${root()}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => json)
    },
};
