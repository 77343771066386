import * as React from "react";
import {
    ArrayInput,
    BooleanInput,
    Create,
    FormTab,
    RadioButtonGroupInput,
    SimpleFormIterator,
    TabbedForm,
    TextInput
} from 'react-admin';
import ValueTypeEnums from './valueType';
import { ResourceTypeList } from '../crossCutting/enums/resourceType';

const ExtendedPropertyCreate = props => {
    return (
        <Create {...props}>
            <TabbedForm>
                <FormTab label="hgw.summary">
                    <TextInput label="hgw.label" source="label" />
                    <TextInput label="hgw.description" multiline source="description" />
                    <BooleanInput label="hgw.extendedproperty.nullable" source="nullable" />
                    <RadioButtonGroupInput label="hgw.extendedproperty.valuetype.label"
                        source="valueType"
                        choices={ValueTypeEnums} />
                    <RadioButtonGroupInput label="hgw.extendedproperty.resourcetype"
                        source="resourceType"
                        choices={ResourceTypeList} />
                </FormTab>
                <FormTab label="hgw.extendedproperty.list.label">
                    <ArrayInput label=""
                        source="list" >
                        <SimpleFormIterator>
                            <TextInput label="hgw.extendedproperty.list.key" source="key" />
                            <TextInput label="hgw.extendedproperty.list.value" source="value" />
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
            </TabbedForm>
        </Create>
    )
};

export default ExtendedPropertyCreate;