import httpClient from './services/httpClient';
import requestUtils from './factory/requestUtils';
import contextStore from '../provider/services/contextStore';

const root = () => `wac/${contextStore.getWac()}/scope`;

export default {

    map: (json) => { return json; },

    getList: (params) => {
        const query = requestUtils.buildQuery(params);
        return httpClient(`${root()}?${query}`).then(
            ({ json }) => json)
    },

    getOne: (params) => {
        return httpClient(`${root()}/${params.id}`).then(({ json }) => json)
    },

    getMany: (params) => {
        const query = params.ids.map((id) => `ids=${id}`).join("&");
        return httpClient(`${root()}/many?${query}`).then(({ json }) => json)
    },

    getManyReference: (params) => {
        //const { page, perPage } = params.pagination;
        //const { field, order } = params.sort;
        //const query = {
        //    sort: JSON.stringify([field, order]),
        //    range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        //    filter: JSON.stringify({
        //        ...params.filter,
        //        [params.target]: params.id,
        //    }),
        //};
        //const url = `tag?${stringify(query)}`;

        //return httpClient(url).then(({ headers, json }) => ({
        //    data: json,
        //    total: parseInt(headers.get('content-range').split('/').pop(), 10),
        //}));
    },

    update: (params) => {
        return httpClient(root(), {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => json)
    },

    updateMany: (params) => {
        //const query = {
        //    filter: JSON.stringify({ id: params.ids }),
        //};
        //return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
        //    method: 'PUT',
        //    body: JSON.stringify(params.data),
        //}).then(({ json }) => ({ data: json }));
    },

    create: (params) => {
        return httpClient(root(), {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => json)
    },

    delete: (params) => {
        return httpClient(`${root()}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => json)
    },

    deleteMany: (params) => {
        //const query = {
        //    filter: JSON.stringify({ id: params.ids }),
        //};
        //return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
        //    method: 'DELETE',
        //    body: JSON.stringify(params.data),
        //}).then(({ json }) => ({ data: json }));
    },
};
