const NetworkMediaEnums = {
    lan: { name: 'hgw.smartlocalunit.networkmedia.lan' },
    gprs: { name: 'hgw.smartlocalunit.networkmedia.gprs' },
    rtc: { name: 'hgw.smartlocalunit.networkmedia.rtc' },
    g3: { name: 'hgw.smartlocalunit.networkmedia.g3' },
    g4: { name: 'hgw.smartlocalunit.networkmedia.g4' },
    unknown: { name: 'hgw.unknown' }
}

export const NetworkMediaList = Object.entries(NetworkMediaEnums).map(([id, obj]) => {
    obj.id = id;
    return obj
})

export default NetworkMediaEnums;