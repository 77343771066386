import React from 'react';
import BusinessAccountJoin from '../resources/businessaccount/join';
import Organization from '../resources/organization';
import User from '../resources/user';
import { Route } from "react-router-dom";
import { Login } from '../oidc/Login'
import { Logout } from '../oidc/Logout'
import About from '../About';
import { ApplicationPaths, LoginActions, LogoutActions } from '../oidc/ApiAuthorizationConstants';

export default [
    <Route exact path="/businessaccount/join" render={(routeProps) => <BusinessAccountJoin hasCreate resource="businessaccount" basePath={routeProps.match.url} {...routeProps} />} />,
    <Route path="/organization/show" render={(routeProps) => <Organization.show hasCreate resource="organization" basePath={routeProps.match.url} {...routeProps} />} />,
    <Route path="/organization/edit" render={(routeProps) => <Organization.edit hasEdit resource="organization" basePath={routeProps.match.url} {...routeProps} />} />,
    <Route path="/organization/define" render={(routeProps) => <Organization.define hasCreate resource="organization" basePath={routeProps.match.url} {...routeProps} />} />,
    <Route path="/organization/create" render={(routeProps) => <Organization.create hasCreate resource="organization" basePath={routeProps.match.url} {...routeProps} />} />,
    <Route path="/profile/show" render={(routeProps) => <User.show hasEdit resource="user" basePath={routeProps.match.url} {...routeProps} />} />,
    <Route path="/profile/edit" render={(routeProps) => <User.edit hasEdit resource="user" basePath={routeProps.match.url} {...routeProps} />} />,
    <Route exact path="/about" component={About} />,
    <Route path={ApplicationPaths.Login} render={() => loginAction(LoginActions.Login)}  />,
    <Route path={ApplicationPaths.LoginFailed} render={() => loginAction(LoginActions.LoginFailed)}  />,
    <Route path={ApplicationPaths.LoginCallback} render={() => loginAction(LoginActions.LoginCallback)}  />,
    <Route path={ApplicationPaths.Profile} render={() => loginAction(LoginActions.Profile)}  />,
    <Route path={ApplicationPaths.Register} render={() => loginAction(LoginActions.Register)}  />,
    <Route path={ApplicationPaths.LogOut} render={() => logoutAction(LogoutActions.Logout)}  />,
    <Route path={ApplicationPaths.LogOutCallback} render={() => logoutAction(LogoutActions.LogoutCallback)}  />,
    <Route path={ApplicationPaths.LoggedOut} render={() => logoutAction(LogoutActions.LoggedOut)}  />
];

function loginAction(name) {
    return (<Login action={name}></Login>);
}

function logoutAction(name) {
    return (<Logout action={name}></Logout>);
}