import httpClient from './services/httpClient';
import contextStore from '../provider/services/contextStore';
import requestUtils from './factory/requestUtils';
import DeployEnums from '../resources/badge/deployEnums';

const root = () => `wac/${contextStore.getWac()}/badge`;

export default {

    map: (json) => {
        json.summary = Object.keys(json.summary ?? []).map(key => {
            return {
                deploy: DeployEnums.find(x => x.key === key) ?? DeployEnums[0],
                count: json.summary[key]
            };
        });

        return json;
    },

    getList: (params) => {
        const query = requestUtils.buildQuery(params);
        return httpClient(`${root()}?${query}`).then(({ json }) => json)
    },

    getOne: (params) => {
        return httpClient(`${root()}/${params.id}`).then(({ json }) => json)
    },

    getMany: (params) => {
        const query = params.ids.map((id) => `ids=${id}`).join("&");
        return httpClient(`${root()}/many?${query}`).then(({ json }) => json)
    },

    update: (params) => {
        return httpClient(root(), {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => json)
    },

    create: (params) => {
        return httpClient(root(), {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => json)
    },

    delete: (params) => {
        return httpClient(`${root()}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => json)
    }
};