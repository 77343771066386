import * as React from "react";
import ResourceTypeEnums  from '../../crossCutting/enums/resourceType';
import { useTranslate } from 'react-admin';

const ResourceTypeField = ({ record, source }) => {
    const translate = useTranslate();
    const type = ResourceTypeEnums;
    const id = record[source];
    var model = type[id];
    //var model = type.find(t => t.id === record[source]);
    return !model ? <span>{record[source]}</span> : !model.logo ? <span>{translate(model.name)}</span>: (<img src={model.logo} alt={model.name} />)
}

ResourceTypeField.defaultProps = { addLabel: true }

export default ResourceTypeField;