import * as React from "react";
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'react-admin';
import recordSourceResolver from './recordSourceResolver';
import ResourceStatusEnums from '../enums/status';

const useStyles = makeStyles({
    disabled: { backgroundColor: "#D9534F" },
    toValidate: { backgroundColor: "#F0AD4E" },
    validated: { backgroundColor: "#5CB85C" },
    locked: { backgroundColor: "#000000" },
    offline: { backgroundColor: "#777777" },
    customToKill: { backgroundColor: "#AA50AA" },
    adminToKill: { backgroundColor: "#AA50AA" },
    killed: { backgroundColor: "#AA50AA" }
});

const ResourceStatusField = ({ record, source }) => {
    const translate = useTranslate();
    const classes = useStyles();
    const id = recordSourceResolver({ record, source });
    const status = ResourceStatusEnums[id];
    if (!status) return <div />;
    const className = classes[id ?? 'disabled'];
    return (
        <Chip icon={<status.icon />} className={className} color="primary" label={translate(status.name)} key={status} size="small" />
    )
}

ResourceStatusField.defaultProps = { addLabel: true }

export default ResourceStatusField;