import * as React from "react";
import { makeStyles } from '@material-ui/core/styles';
import NotificationsActive from '@material-ui/icons/NotificationsActive';
import { Badge, Tooltip } from '@material-ui/core';
import { useTranslate } from 'react-admin';

const useStyles = makeStyles({
    veryHigh: { color: "Red", marginRight: "15px" },
    high: { color: "Orange", marginRight: "15px" },
    normal: { color: "Yellow", marginRight: "15px" },
    low: { color: "White", marginRight: "15px" },
    veryLow: { color: "LightGray", marginRight: "15px" },
    none: { color: "DarkGray", marginRight: "15px" }
});

const AlarmBadge = ({ record, addLabel }) => {
    const translate = useTranslate();
    const classes = useStyles();
    const criticity = record?.criticity ?? record;
    const count = record?.count ?? 0;
    const className = classes[criticity.id];
    return addLabel ?
        <Badge color="primary" badgeContent={count} className={className} >
            <NotificationsActive />
            <span>&nbsp;{translate(criticity.name)}</span>
        </Badge> :
        <Tooltip title={translate(criticity.name)} arrow>
            <Badge color="primary" badgeContent={count} className={className} >
                <NotificationsActive />
            </Badge>
        </Tooltip>
}

AlarmBadge.defaultProps = { addLabel: true }

export default AlarmBadge;