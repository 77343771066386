import * as React from "react";
import { cloneElement } from 'react';
import { Box, Typography, useMediaQuery } from '@material-ui/core';
import {
    List,
    Datagrid,
    TextField,
    SelectField,
    TopToolbar,
    useListContext,
    CreateButton,
    ExportButton,
    Button,
    SimpleList,
    sanitizeListRestProps,
    useRedirect,
    useTranslate
} from 'react-admin';
import AvatarPicture from '../crossCutting/fields/avatarPicture';
import BusinessAccountLogo from './pictures/businessAccount64.png';
import IconJoin from '@material-ui/icons/SystemUpdateAlt';
import { StatusList } from './enums/status';

const ListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        basePath,
        showFilter,
        total,
    } = useListContext();
    const redirect = useRedirect();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <CreateButton basePath={basePath} />
            <Button
                onClick={() => { redirect('businessaccount/join', props.basePath); }}
                label="hgw.join" >
                <IconJoin />
            </Button>
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filterValues={filterValues}
                maxResults={maxResults}
            />
        </TopToolbar>
    );
};

const Empty = (props) => {
    const { basePath } = useListContext();
    const redirect = useRedirect();
    const translate = useTranslate();
    return (
        <Box textAlign="center" m={1}>
            <Typography variant="h4" paragraph>
                {translate("hgw.businessaccount.empty")}
            </Typography>
            <Box textAlign="center" fontWeight="fontWeightBold">
                {translate('hgw.youcan')}:
            </Box>
            <Typography variant="overline" display="block" >
                {translate('hgw.businessaccount.join')}
            </Typography>
            <Button
                onClick={() => { redirect('businessaccount/join', props.basePath); }}
                label="hgw.join" >
                <IconJoin />
            </Button>
            <Box textAlign="center" fontWeight="fontWeightBold">
                {translate('hgw.or')}
            </Box>
            <Typography variant="overline" display="block" >
                {translate('hgw.businessaccount.create')}
            </Typography>
            <CreateButton basePath={basePath} />
        </Box>
    );
};

const BusinessAccountList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const rowClick = (id, basePath, record) => {
        //contextStore.setBusinessAccount(record);
        return 'show';
    };
    return (
        <List title="Vos affaires" empty={<Empty />} actions={<ListActions />} {...props}>
            {isSmall ?
                (<SimpleList
                    linkType="show"
                    leftIcon={record => <AvatarPicture record={record} source="picture" fallbackSrc={BusinessAccountLogo} />}
                    primaryText={record => record.name}
                    secondaryText={record => record.wac}
                    tertiaryText={record => <SelectField record={record} source="status" choices={StatusList} />}
                />) :
                (<Datagrid rowClick={rowClick}>
                    <AvatarPicture label="hgw.logo" source="picture" fallbackSrc={BusinessAccountLogo} sortable={false} />
                    <TextField label="hgw.label" source="name" />
                    <TextField label="hgw.description" source="description" />
                    <TextField label="hgw.businessaccount.wac" source="wac" />
                    <SelectField label="hgw.status.label" source="status" choices={StatusList} />
                </Datagrid>)}
        </List>

    )
}

export default BusinessAccountList;
