import httpClient from './services/httpClient';

const root = (id) => !id ? 'wac/infra/usage' : `wac/infra/usage/${id}`;

function getCachedInfraUsages() {
    const dicoString = sessionStorage.getItem('infraUsages');
    return (!dicoString ?
        httpClient(root()).then(({ json }) => {
            const dico = {};
            json.forEach(i => dico[i.id] = i);
            sessionStorage.setItem('infraUsages', JSON.stringify(dico));
            return dico;
        }) :
        Promise.resolve(JSON.parse(dicoString)));
}

export default {

    map: (json) => {
        return json
    },

    getOne: (params) => {
        return getCachedInfraUsages().then(dico => {
            if (dico.hasOwnProperty(params.id))
                return dico[params.id];

            sessionStorage.removeItem('infraUsages');
            return getCachedInfraUsages().then(dico => dico[params.id] ?? null);
        })
    },

    getList: (params) => {
        return getCachedInfraUsages().then(dico => {
            var usages = Object.values(dico);
            if (params.hasOwnProperty("search")) {
                usages = usages.filter(i => i.name.indexOf(params.search) > -1);
            }

            var paged = (params.hasOwnProperty("pageSize") && params.hasOwnProperty("pageIndex")) ?
                usages.slice((params.pageIndex - 1) * params.pageSize, params.pageIndex * params.pageSize) : usages;

            return {
                data: paged,
                count: usages.length
            }
        })
    },

    getMany: (params) => {
        return getCachedInfraUsages().then(dico => {
            if (!Object.keys(dico).some(i => !params.ids.includes(i)))
                return params.ids.map(i => dico[i]);

            sessionStorage.removeItem('infraUsages');
            return getCachedInfraUsages()
                .then(dico => params.ids.filter(i => dico.hasOwnProperty(i)).map(i => dico[i]));
        })
    },

    create: (params) => {
        return httpClient(root(), {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => {
            sessionStorage.removeItem('infraUsages');
            return json;
        })
    },

    update: (params) => {
        return httpClient(root(params.data.id), {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => {
            sessionStorage.removeItem('infraUsages');
            return json;
        })
    },

    delete: (params) => {
        return httpClient(root(params.id), {
            method: 'DELETE',
        }).then(({ json }) => {
            sessionStorage.removeItem('infraUsages');
            return json;
        })
    }
};