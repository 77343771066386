import * as React from "react";
import { translate } from 'react-admin';

const ValueTypeField = ({ record, source, translate }) => {

    return (
        <>
            {translate(`hgw.flux.item.type.${record.type}`)}
        </>
    )
}
ValueTypeField.defaultProps = { addLabel: true }

export default translate(ValueTypeField);
