import * as React from "react";
import {
    Button,
    Create,
    FormTab,
    ImageField,
    ImageInput,
    RadioButtonGroupInput,
    ReferenceInput,
    SelectInput,
    TabbedForm,
    TextInput,
    TopToolbar,
    useRedirect,
    useTranslate,
    useNotify
} from 'react-admin';
import { TypeList } from './enums/type';
import contextStore from "../../provider/services/contextStore";
import IconJoin from '@material-ui/icons/SystemUpdateAlt';

const OrganizationCreateTitle = () => {
    const translate = useTranslate();
    return <span>{translate('hgw.organization.create')}</span>;
};

const OrganizationCreateActions = () => {
    const redirect = useRedirect();
    return (
        <TopToolbar>
            <Button
                onClick={() => {
                    redirect('/organization/define');
                }}
                label="hgw.define" >
                <IconJoin />
            </Button>
        </TopToolbar>
    )
};

export const OrganizationCreate = props => {
    const redirect = useRedirect();
    const notify = useNotify();
    const onSuccess = ({ data }) => {
        notify('hgw.organization.createsuccessfully');
        contextStore.setOrganization(data);
        redirect('/organization/show');
    };

    return (
        <Create {...props} title={<OrganizationCreateTitle />} onSuccess={onSuccess} actions={<OrganizationCreateActions />}  >
            <TabbedForm>
                <FormTab label="hgw.summary">
                    <TextInput disabled source="id" />
                    <TextInput label="hgw.label" source="name" required={true} />
                    <TextInput label="hgw.description" source="description" multiline />
                    <TextInput label="hgw.url" source="url" />
                    <TextInput label="hgw.organization.businesssector" source="businessSector" />
                    <RadioButtonGroupInput label="hgw.type" source="type" choices={TypeList} />
                </FormTab>
                <FormTab label="hgw.logo">
                    <ImageInput label="hgw.logo" source="pictureraw" accept="image/*">
                        <ImageField source="src" title="title" />
                    </ImageInput>
                </FormTab>
                <FormTab label="hgw.organization.legalcode">
                    <TextInput label="hgw.organization.siret" source="siret" />
                    <TextInput label="hgw.organization.vatin" source="vatin" />
                    <TextInput label="hgw.organization.otherin" source="otherIn" />
                    <TextInput label="hgw.organization.legalname" source="legalName" />
                </FormTab>
                <FormTab label="hgw.address.label">
                    <TextInput label="hgw.address.phone" source="address.phone" />
                    <TextInput label="hgw.address.fax" source="address.fax" />
                    <TextInput label="hgw.address.street" source="address.street" multiline />
                    <TextInput label="hgw.address.postalcode" source="address.postalCode" />
                    <TextInput label="hgw.address.city" source="address.city" />
                    <ReferenceInput label="hgw.country.label" source="address.countryId" reference="country">
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                </FormTab>
            </TabbedForm>
        </Create>
    )
};

export default OrganizationCreate;