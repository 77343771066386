import * as React from "react";
import {
    Create,
    SimpleForm,
    TextInput,
    BooleanInput
} from 'react-admin';

const TagCreate = props => (
    <Create {...props} re>
        <SimpleForm redirect="list">
            <TextInput label="hgw.label" source="label" />
            <BooleanInput label="hgw.tag.isglobaldetail" source="isGlobal" />
            <TextInput label="hgw.description" multiline source="description" />
        </SimpleForm>
    </Create>
);

export default TagCreate;