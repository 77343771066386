import TagList from './list';
import TagCreate from './create';
import TagEdit from './edit';
import TagIcon from './icon';

export default {
    list: TagList,
    create: TagCreate,
    edit: TagEdit,
    icon: TagIcon
};