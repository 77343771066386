import * as React from "react";
import {
    ArrayField,
    BooleanField,
    ChipField,
    Datagrid,
    NumberField,
    ReferenceField,
    RichTextField,
    Show,
    Tab,
    TabbedShowLayout,
    TextField,
} from 'react-admin';
import FluxItemReferenceField from '../fluxitem/fields/reference';
import MomentField from '../crossCutting/fields/momentField';
import ActivityTypeField from './fields/type';
import MetricTypeField from './fields/metric';
import PeriodField from '../crossCutting/fields/periodField';
import title from './title';

const ActivityTitle = title;

export const ActivityShow = props => {
    return (
        <Show title={<ActivityTitle />}  {...props} translate="">
            <TabbedShowLayout>
                <Tab label="hgw.summary">
                    <TextField label="hgw.label" source="name" />
                    <RichTextField label="hgw.description" source="description" />
                    <ActivityTypeField label="hgw.activity.type.label" source="type" />
                    <NumberField label="hgw.activity.executioncount" source="executionCount" />
                    <MomentField label="hgw.activity.executiondate" source="executionDate" />
                    <MomentField label="hgw.creationdate" source="creationDate" />
                    <MomentField label="hgw.updatedate" source="updateDate" />
                    <BooleanField label="hgw.enabled" source="enabled" />
                </Tab>
                <Tab label="hgw.config">
                    <ReferenceField label="hgw.activity.formula.targetfluxitem" source="targetFluxItemId" reference="fluxitem" link="show">
                        <FluxItemReferenceField />
                    </ReferenceField>
                    <PeriodField label="hgw.period.label" source="period" />
                    <ChipField label="hgw.activity.formula.label" source="formula" />
                    <ArrayField label="hgw.activity.formula.sources" source="sources">
                        <Datagrid>
                            <TextField label="hgw.activity.formula.key" source="key" />
                            <ReferenceField label="hgw.flux.item.label" source="fluxItemId" reference="fluxitem" link="show">
                                <FluxItemReferenceField />
                            </ReferenceField>
                            <MetricTypeField label="hgw.activity.metric.label" source="metric" />
                        </Datagrid>
                    </ArrayField>
                </Tab>
            </TabbedShowLayout>
        </Show>
    )
};

export default ActivityShow;
