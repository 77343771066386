import * as React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { useState, useEffect } from 'react';
import {
    useDataProvider,
    useTranslate,
    Loading
} from 'react-admin';
import AvatarPicture from '../../crossCutting/fields/avatarPicture';
import ApartmentIcon from '@material-ui/icons/Apartment';

const useStyles = makeStyles({
    div: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap'
    }
});

const ReferenceOrganizationField = ({ record, source, label, ...props }) => {
    const classes = useStyles();
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const [organization, setOrganization] = useState({});
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const id = record[source];
        if (!id) {
            Promise.resolve().then(() => setLoading(false))
        }
        // use get many to get friendly instead of get one (to review on API side ...)
        dataProvider.getMany('organization', { ids: [id] })
            .then(({ data }) => {
                if (data.length === 1) {
                    setOrganization(data[0]);
                }

                setLoading(false);
            })
            .catch(error => setLoading(false))
    }, []);
    if (loading) return <Loading />;
    return (
        <div className={classes.div}>
            <AvatarPicture record={organization} source="picture"><ApartmentIcon /></AvatarPicture>
            <span>&nbsp; {organization.name?? translate("hgw.undefined")}</span>
        </div>
    )
};

ReferenceOrganizationField.defaultProps = { addLabel: true }

export default ReferenceOrganizationField;