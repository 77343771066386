import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import LockIcon from '@material-ui/icons/Lock';

const BadgeStatusEnums = [
    { id: 0, name: 'hgw.status.disabled', icon: HighlightOffIcon },
    { id: 1, name: 'hgw.status.tovalidate', icon: ErrorOutlineIcon },
    { id: 2, name: 'hgw.status.validated', icon: CheckCircleOutlineIcon },
    { id: 9, name: 'hgw.status.killed', icon: LockIcon }
]

export default BadgeStatusEnums;