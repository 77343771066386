import * as React from "react";
import { useState, useEffect } from 'react';
import {
    AutocompleteArrayInput,
    AutocompleteInput,
    DeleteButton,
    Edit,
    FormTab,
    ListButton,
    NumberInput,
    ReferenceArrayInput,
    ReferenceInput,
    SaveButton,
    ShowButton,
    TabbedForm,
    TextInput,
    TopToolbar,
    Toolbar,
    useDataProvider
} from 'react-admin';
import { InputFieldFx } from '../extendedproperty/fields/fieldFx';
import contextStore from '../../provider/services/contextStore';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import ResourceStatusField from '../crossCutting/fields/resourceStatusField';
import ValidateButton from '../crossCutting/buttons/ValidateButton';
import title from './title';

const DeviceLoraTitle = title;

const DeviceLoraActions = ({ data, hasExtendedProperties, ...props }) => {
    return (
        <TopToolbar>
            <ShowButton record={data} {...props} />
            <ListButton
                icon={<NoteAddIcon />}
                label={hasExtendedProperties ? "hgw.extendedproperty.edit" : "hgw.extendedproperty.create"}
                basePath={hasExtendedProperties ? "/extendedproperty" : "/extendedproperty/create"}>
            </ListButton>
        </TopToolbar>
    )
};

const DeviceLoraToolbar = (props) => {
    return (
        <Toolbar {...props}>
            <SaveButton />
            <ValidateButton />
            <DeleteButton />
        </Toolbar>
    )
};

const DeviceLoraEdit = props => {
    const businessAccount = contextStore.getBusinessAccount();
    const [extendedProperties, setExtendedProperties] = useState();
    const [loading, setLoading] = useState(true);
    const dataProvider = useDataProvider();
    useEffect(() => {
        dataProvider.getList('extendedproperty', { filter: { resourceType: 'smartLocalUnit' } })
            .then(({ data }) => {
                setExtendedProperties(data);
                setLoading(false);
            })
            .catch(error => {
                setExtendedProperties(null);
                setLoading(false);
            })
    }, []);
    if (loading) return null;
    return (
        <Edit title={<DeviceLoraTitle />} actions={<DeviceLoraActions hasExtendedProperties={extendedProperties?.length ?? 0 > 0} />} {...props}>
            <TabbedForm toolbar={<DeviceLoraToolbar />}>
                <FormTab label="hgw.summary">
                    <TextInput disabled source="id" />
                    <TextInput label="hgw.label" source="name" />
                    <TextInput label="hgw.description" multiline source="description" />
                    <ResourceStatusField label="hgw.status.label" source="status" />
                </FormTab>
                <FormTab label="hgw.tagsinfras">
                    <ReferenceInput label="hgw.infra.label" source="infraId" reference="infra"
                        filterToQuery={searchText => ({ search: searchText })}
                        perPage={25}
                        emptyText="hgw.undefined"
                        allowEmpty>
                        <AutocompleteInput optionText="name" optionValue="id" />
                    </ReferenceInput>
                    <ReferenceArrayInput label="hgw.tag.many" source="tags" reference="tag" filterToQuery={searchText => ({ search: searchText })} perPage={25}>
                        <AutocompleteArrayInput optionText="label" optionValue="label" />
                    </ReferenceArrayInput>
                    <NumberInput label="hgw.location.latitude" source="location.latitude" />
                    <NumberInput label="hgw.location.longitude" source="location.longitude" />
                </FormTab>
                {!!extendedProperties && extendedProperties.length > 0 && !!businessAccount &&
                    <FormTab label={businessAccount.name}>
                        {extendedProperties.map(ep => InputFieldFx({ extendedProperty: ep }))}
                    </FormTab>
                }
            </TabbedForm>
        </Edit>
    )
};

export default DeviceLoraEdit;