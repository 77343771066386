import * as React from "react";
import MetricEnums from '../enums/metric';
import { useTranslate } from 'react-admin';

const Metric = MetricEnums;

const ActivityMetricField = ({ record, source, addLabel }) => {
    const translate = useTranslate();
    const value = record[source ?? 'id'];
    const metric = Metric[value];
    const name = translate(metric.name);
    return <span>{name}</span>

}

ActivityMetricField.defaultProps = { addLabel: true }

export default ActivityMetricField;